import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Button, Confirm, crudUpdateMany } from 'react-admin'

class BulkStatusButton extends Component {
  state = {
    isOpen: false
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false })
  }

  handleConfirm = () => {
    const {
      basePath,
      crudUpdateMany,
      resource,
      selectedIds,
      action
    } = this.props
    crudUpdateMany(resource, selectedIds, { status: action }, basePath)
  }

  render() {
    const { text, direct } = this.props
    return (
      <Fragment>
        <Button label={text} onClick={() => this.setState({ isOpen: true })} />
        <Confirm
          title={`Update ${direct}`}
          content="Are you sure you want to update status for these items?"
          isOpen={this.state.isOpen}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    )
  }
}

export default connect(
  undefined,
  { crudUpdateMany }
)(BulkStatusButton)
