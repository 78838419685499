import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Space from './custom/view/AffiliateDashboard/Space'
import Icon from '@material-ui/core/Icon'

import ConfirmWithdraw from './ConfirmWithdraw'

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    height: '251px',
    width: '352px',
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    padding: '40px 39px 0',
    position: 'relative'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    width: 244
  },
  button: {
    width: '261px',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  cross: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  }
}

class WithdrawModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      amount: undefined,
      submitAmount: undefined,
      error: '',
      openConfirm: false
    }
  }
  handleChange = event => {
    this.setState({ amount: event.target.value })
  }

  handleSubmit = event => {
    const { handleClose } = this.props
    const { amount } = this.state
    event.preventDefault()

    if (this.validate()) {
      this.setState({ submitAmount: amount, amount: '' })
      this.handleOpenConfirm()
      handleClose()
    }
  }

  validate = () => {
    let amount = this.state.amount
    let errors = ''
    let formIsValid = true

    if (!amount) {
      formIsValid = false
      errors = '*Please enter your withdraw'
    }

    if (typeof amount !== 'undefined' && amount !== '') {
      if (!amount.match(/^[0-9]+$/)) {
        formIsValid = false
        errors = '*Please enter Number only!'
      }
    }

    this.setState({
      error: errors
    })
    return formIsValid
  }

  handleOpenConfirm = () => {
    this.setState({ openConfirm: true })
  }

  handleCloseConfirm = () => {
    this.setState({ openConfirm: false })
  }

  doConfirm = value => {
    // const { promomCode } = this.state
    this.props.update(value)
    this.setState({ openConfirm: false })
  }

  render() {
    // const classes = useStyles()
    const { open, handleClose, classes, email } = this.props
    const { amount, error, openConfirm, submitAmount } = this.state
    const emailText = `Withdraw to ${email}`
    // console.log('error >>> ', error.length)
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.title}>
                Withdraw to your Paypal Account
              </div>
              <div className={classes.desc}>Please enter the amount</div>
              <Icon className={classes.cross} onClick={handleClose}>
                close
              </Icon>
              <Space size="15" />
              <div className={classes.container}>
                <form onSubmit={this.handleSubmit}>
                  <Input
                    className={classes.input}
                    value={amount}
                    inputProps={{
                      'aria-label': 'description'
                    }}
                    onChange={this.handleChange}
                  />
                  {error.length > 0 && (
                    <div style={{ color: '#cc0000', marginBottom: '12px' }}>
                      {error}
                    </div>
                  )}
                  <Space size="25" />
                  <Button type="submit" className={classes.button}>
                    <p className={classes.buttonText}>{emailText}</p>
                  </Button>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
        <ConfirmWithdraw
          open={openConfirm}
          confirm={() => this.props.confirmWithdraw()}
          handleCloseConfirm={this.handleCloseConfirm}
          amount={submitAmount}
          update={this.doConfirm}
        />
      </>
    )
  }
}

export default withStyles(styles)(WithdrawModal)
