import React, { Fragment, Component } from 'react'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { notification, Icon } from 'antd'
import SpinnerView from './SpinnerView'

import WithdrawModal from './WithdrawModal'
import WithdrawPaypalEdit from './WithdrawPaypalEdit'

import Space from './custom/view/AffiliateDashboard/Space'

const numberWithCommas = amount =>
  amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const styles = {
  padding: { padding: '41px' },
  title: {
    fontFamily: 'Ubuntu',
    color: '#4a4a4a',
    fontSize: '20px',
    fontWeight: 500,
    margin: 0
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-15px',
    marginLeft: '0px'
  },
  card: {
    textAlign: 'end',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    borderRadius: '10px',
    width: '23.5%',
    padding: '15px',
    marginBottom: '33px',
    height: '110px'
  },
  span: {
    display: 'inline-block',
    width: '2%'
  },
  typeText: {
    fontSize: '16px',
    fontFamily: 'Roboto',
    color: '#828282',
    fontWeight: 400,
    margin: '0 0 5px'
  },
  amountText: {
    fontSize: '30px',
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontWeight: 700,
    marginBlockStart: '0.3em',
    marginBlockEnd: '0em'
  },
  paypalCard: {
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    borderRadius: '10px',
    width: '96%',
    padding: '15px',
    height: '110px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emailText: {
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontSize: '18px',
    fontWeight: 500
  },
  editText: {
    width: '96%',
    display: 'flex',
    fontFamily: 'Roboto',
    color: '#3e4eb8',
    fontSize: '18px',
    fontWeight: 500,
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 0,
    cursor: 'pointer'
  },
  button: {
    width: '258px',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  }
}

class Withdraw extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      paypalOpen: false,
      total: 0,
      pending: 0,
      withdrawable: 0,
      pending_withdrawable: 0,
      email: '(Please add your Paypal email only!)',
      onload: true
    }
  }

  componentDidMount() {
    const pid = localStorage.getItem('pid')
    axios
      .get(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/wallet/${pid}`)
      .then(res => {
        const data = res.data
        const {
          total,
          pending,
          withdrawable,
          pending_withdrawable,
          email
        } = data

        // console.log('Profile data >>> ', data)

        if (data) {
          this.setState({
            total,
            pending,
            withdrawable,
            pending_withdrawable,
            email,
            onload: false
          })
        }
      })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handlePaypalOpen = () => {
    this.setState({ paypalOpen: true })
  }

  handlePaypalClose = () => {
    this.setState({ paypalOpen: false })
  }
  updateError = error => {
    this.setState({ paypalOpen: false, onload: false })
    notification.open({
      message: '[DUPLICATE EMAIL] Transaction email declined!',
      icon: <Icon type="close-circle" style={{ color: 'red' }} />
    })
  }

  updatePaypal = email => {
    this.setState({ email, onload: false })
    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Editted Paypal account Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  loadState = () => {
    this.setState({ paypalOpen: false, onload: true })
  }

  confirmWithdraw = () => {
    this.setState({ open: false, onload: true })
  }
  handleSuccess = value => {
    this.setState({ onload: false })
    notification.config({
      placement: 'bottomRight'
    })

    if (value === 'error') {
      notification.open({
        message: 'Transfer declined!',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />
      })
    } else {
      notification.open({
        message: 'Withdraw Succeeded!',
        icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      open,
      paypalOpen,
      total,
      pending,
      withdrawable,
      pending_withdrawable,
      email,
      onload
    } = this.state

    const data = {
      balancesText: 'Balances',
      dashboardData: [
        {
          type: 'Total Balance (THB)',
          amount: total
        },
        {
          type: 'Pending Withdraw (THB)',
          amount: pending_withdrawable
        },
        {
          type: 'Pending Balance (THB)',
          amount: pending
        },
        {
          type: 'Withdrawable (THB)',
          amount: withdrawable
        }
      ],
      paypaiText: 'Your Paypal Account (To receive the withdraw)',
      email,
      editText: 'Edit your Paypal Account',
      withdrawText: 'Your Paypal Account (To receive the withdraw)',
      buttonText: 'Set up a withdraw'
    }

    return (
      <Card id="card">
        <SpinnerView show={onload} />
        <div className={classes.padding}>
          <p className={classes.title}>{data.balancesText}</p>
          <Space size="33" />
          <div className={classes.row} style={{ maxWidth: '96%' }}>
            {data.dashboardData.map(({ type, amount }, index) => (
              <Fragment key={index}>
                <div className={classes.card}>
                  <p className={classes.typeText}>{type}</p>
                  <p className={classes.amountText}>
                    {numberWithCommas(amount)}
                  </p>
                </div>
                <div className={classes.span} />
              </Fragment>
            ))}
          </div>
          <Space size="7" />
          <div className={classes.title}>{data.paypaiText}</div>
          <Space size="40" />
          <div className={classes.paypalCard}>
            <div className={classes.emailText}>{data.email}</div>
          </div>
          <Space size="16" />
          <span className={classes.editText} onClick={this.handlePaypalOpen}>
            {data.editText}
          </span>
          <Space size="23" />
          <p className={classes.title}>{data.withdrawText}</p>
          <Space size="28" />
          <Button className={classes.button} onClick={this.handleOpen}>
            <p className={classes.buttonText}>{data.buttonText}</p>
          </Button>
          <Space size="418" />
          <WithdrawModal
            open={open}
            handleClose={this.handleClose}
            email={data.email}
            update={this.handleSuccess}
            confirmWithdraw={this.confirmWithdraw}
          />
          <WithdrawPaypalEdit
            open={paypalOpen}
            handleClose={this.handlePaypalClose}
            update={this.updatePaypal}
            loadState={this.loadState}
            updateError={this.updateError}
          />
        </div>
      </Card>
    )
  }
}

export default withStyles(styles)(Withdraw)
