import React from 'react'
import {
  List,
  Edit,
  email,
  Toolbar,
  SaveButton,
  Datagrid,
  TextField,
  TabbedForm,
  FormTab,
  EmailField,
  EditButton,
  TextInput,
  SelectInput,
  DisabledInput,
  AutocompleteInput,
  minLength,
  maxLength,
  DeleteButton
} from 'react-admin'
import { SortThaiBank } from '../Assets/src/Bank'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Group'
import Pagination from './custom/view/Pagination'

const PartnerContentField = ({ record = {} }) => (
  <div style={{ lineHeight: '20px', margin: '16px 0' }}>
    <span>{record.option.toUpperCase()}</span> <br /> {record.role}
  </div>
)

const PartnerInfoField = ({ record = {} }) => (
  <div style={{ lineHeight: '20px', margin: '16px 0' }}>
    {record.organize} <br />
    <a target="blank" href={`http://${record.website}`}>
      {record.website}
    </a>
  </div>
)

// Exporter
export const PartnerIcon = () => <DefaultIcon />
export const PartnerList = props => (
  <List
    {...props}
    title="Show user lists"
    perPage={30}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstname" label="Name" />
      <PartnerContentField label="Type" />
      <PartnerInfoField label="Information" />
      <EmailField source="email" />
      <TextField source="partner_code" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const PartnerToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/partners" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/partners" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const PartnerEdit = props => {
  return (
    <Edit title="Partner Edit" {...props}>
      <TabbedForm toolbar={<PartnerToolbar />}>
        <FormTab label="Profile">
          <DisabledInput label="Partner name" source="firstname" />
          <TextInput label="Organize" source="organize" />
          <TextInput
            label="Partner Affiliate code (Generated)"
            source="partner_code"
          />
          <DisabledInput source="user_id" />
          <SelectInput
            label="Partner option"
            source="option"
            choices={[
              { id: 'online', name: 'Online partner' },
              { id: 'offline', name: 'Offline partner' },
              { id: 'operator', name: 'Operator' }
            ]}
          />
          <SelectInput
            label="Partner role"
            source="role"
            choices={[
              { id: 'Hotel', name: 'Hotel' },
              { id: 'Hostel', name: 'Hostel' },
              { id: 'Restaurant', name: 'Restaurant' },
              { id: 'Bar', name: 'Bar' },
              { id: 'Blogger', name: 'Blogger' },
              { id: 'Trip Planner', name: 'Trip Planner' },
              { id: 'Travel Influencer', name: 'Travel Influencer' },
              { id: 'Facebook Page', name: 'Bar' },
              { id: 'Instagram', name: 'Instagram' },
              { id: 'Tour Agent', name: 'Tour Agent' },
              { id: 'Ferry', name: 'Ferry' },
              { id: 'Bus', name: 'Bus' },
              { id: 'Train', name: 'Train' },
              { id: 'Flights', name: 'Flights' },
              { id: 'Activities', name: 'Activities' },
              { id: 'Event', name: 'Event' },
              { id: 'Accomodation', name: 'Accomodation' },
              { id: 'Others', name: 'Others' }
            ]}
          />
          <DisabledInput label="Email" source="email" />
          <TextInput label="Website" source="website" defaultValue="" />
          <TextInput
            label="Password"
            source="contract_password"
            type="password"
          />
        </FormTab>
        <FormTab label="Bank Account">
          <TextInput
            source="book_holder"
            label="Account Name"
            validate={[minLength(3), maxLength(100)]}
          />
          <AutocompleteInput
            source="book_bank"
            label="Bank"
            choices={SortThaiBank}
          />
          <TextInput
            source="book_number"
            label="Account Number "
            validate={[minLength(3), maxLength(100)]}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
