import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { addField, FieldTitle } from 'ra-core'

import sanitizeRestProps from './sanitizeRestProps'
import styled from 'styled-components'
import { DateRangePicker } from 'material-date-range-picker'

const Styles = styled.div`
  padding: 7px;
  width: ${props => (props.width ? `${props.width}px` : '260px')};
`

const dateFormatter = v => {
  if (!(v instanceof Date) || isNaN(v)) return
  const pad = '00'
  const yyyy = v.getFullYear().toString()
  const MM = (v.getMonth() + 1).toString()
  const dd = v.getDate().toString()
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`
}

const dateRegex = /^\d{4}-\d{2}-\d{2}$/

// const sanitizeValue = value => {
//   // null, undefined and empty string values should not go through dateFormatter
//   // otherwise, it returns undefined and will make the input an uncontrolled one.
//   if (value == null || value === '') {
//     return ''
//   }
//   // valid dates should not be converted
//   if (dateRegex.test(value)) {
//     return value
//   }

//   const finalValue = typeof value instanceof Date ? value : new Date(value)
//   return dateFormatter(finalValue)
// }

export class DatePickerRange extends Component {
  state = {
    fromDate: null,
    toDate: null
  }

  _handleDateRangeChange = update => {
    if (update.fromDate !== undefined && this.state.toDate !== null) {
      let from = JSON.stringify(update.fromDate)
      let to = JSON.stringify(this.state.toDate)
      this.props.input.onChange(`${from}${to}`)
    }
    if (update.toDate !== undefined && this.state.fromDate !== null) {
      let from = JSON.stringify(this.state.fromDate)
      let to = JSON.stringify(update.toDate)
      this.props.input.onChange(`${from}${to}`)
    }

    this.setState(update)
  }

  render() {
    const {
      className,
      meta,
      input,
      isRequired,
      label,
      options,
      source,
      resource,
      width,
      ...rest
    } = this.props
    if (typeof meta === 'undefined') {
      throw new Error(
        "The DateInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    const { touched, error } = meta

    // const value = sanitizeValue(input.value)

    return (
      <Styles width={width}>
        <DateRangePicker
          {...input}
          className={className}
          type="date"
          value={this.fromDate}
          dateFormat="D MMM YYYY"
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          onChange={this._handleDateRangeChange}
          closeDialogOnSelection={false}
          label={label}
        />
      </Styles>
    )
  }
}

DatePickerRange.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string
}

DatePickerRange.defaultProps = {
  options: {}
}

export default DatePickerRange
