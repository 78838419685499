import React, { Fragment } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { getName } from 'country-list'
import * as moment from 'moment'

export const DateKOHField = ({ record, timeKey, showTime }) => {
  return (
    <div style={{ minWidth: '90px', lineHeight: '20px' }}>
      <span style={{ fontWeight: '500' }}>
        {moment(record[timeKey])
          //.add(7, 'hours') //+7 hours for Thailand Local Time
          .format('D MMM YYYY')}
      </span>
      {showTime && (
        <Fragment>
          <br />
          <span>
            {moment(record[timeKey])
              //.add(7, 'hours')
              .format('HH:mm')}
          </span>
        </Fragment>
      )}
    </div>
  )
}

export const TimeKOHField = ({ record, timeKey }) => {
  return (
    <div style={{ minWidth: '30px', lineHeight: '20px' }}>
      <Fragment>
        <span>
          {moment(record.time[timeKey])
            .add(7, 'hour')
            .format('HH:mm')}
        </span>
      </Fragment>
    </div>
  )
}

export const NationField = ({ nationKey, showNationText }) => {
  const parseNation =
    typeof nationKey == 'undefined'
      ? 'TH'
      : nationKey == null
      ? 'TH'
      : nationKey
  const styles = showNationText
    ? { marginTop: '6px', display: 'flex', width: '90px' }
    : { marginTop: '0px', display: 'inline-block', width: '90px' }
  return (
    <span style={styles}>
      <ReactCountryFlag
        code={parseNation}
        styleProps={{
          width: '20px',
          height: '20px'
        }}
        svg
      />
      {showNationText && (
        <span style={{ color: 'grey', marginLeft: '6px' }}>
          {getName(parseNation)}
        </span>
      )}
    </span>
  )
}
