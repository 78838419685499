import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { notification, Icon } from 'antd'

import Space from './custom/view/AffiliateDashboard/Space'
import PromoCodeModal from './PromoCodeModal'

const styles = {
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: '92%',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    padding: '33px 3.7% 13px 3.7%',
    position: 'relative'
  },
  promoCode: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  copyText: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    position: 'absolute',
    bottom: '24px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  createCode: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    cursor: 'pointer'
  },
  warningText: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: '0 auto',
    position: 'absolute',
    bottom: '33px',
    left: 0,
    right: 0
  }
}

class PromoCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      promoCode: undefined,
      copied: false,
      open: false
    }
  }
  componentDidMount() {
    const pid = localStorage.getItem('pid')

    axios
      .get(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/promocodes/${pid}`)
      .then(res => {
        const data = res.data.message

        // console.log('data promo-code >>> ', code)
        if (data.length > 0) {
          this.setState({ promoCode: data[0].code })
        } else {
          this.setState({ promoCode: undefined })
        }
      })
  }

  success = () => {
    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Copy Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleCreate = code => {
    this.setState({ open: false, promoCode: code })

    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Create Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  render() {
    const { classes } = this.props
    const { promoCode, open } = this.state

    const data = {
      promoDesc: `You can generate your 5% off unique promo-code with this tool. Once you have created your code, this code cannot be changed in the future.`,
      promoscript: `<script src="https://s3-ap-southeast-1.amazonaws.com/koh-widget/koh/js/widget.min.js"></script> <div id="koh_widget" width="340" height="500" theme="forrest" from="bangkok" to="phuket" affiliate_id="GOUCNRDY" ></div>`
    }

    console.log('promo >>> ', localStorage.getItem('promo'))
    return (
      <Fragment>
        <Space size="66" />
        <p className={classes.title}>Promo-code Generator</p>
        <Space size="24" />
        <p className={classes.desc}>{data.promoDesc}</p>
        <Space size="29" />
        <p className={classes.title}>Your 5% off Promo-code</p>
        <Space size="20" />
        {promoCode ? (
          <div className={classes.card}>
            <div className={classes.promoCode}>{promoCode}</div>
            <CopyToClipboard
              text={promoCode}
              onCopy={() => this.setState({ copied: true })}
            >
              <span className={classes.copyText} onClick={this.success}>
                Copy code
              </span>
            </CopyToClipboard>
          </div>
        ) : (
          <div className={classes.card}>
            <div className={classes.createCode} onClick={this.handleOpen}>
              Create your own code
            </div>
            <div className={classes.warningText}>
              (This cannot be changed after)
            </div>
          </div>
        )}
        <PromoCodeModal
          open={open}
          handleClose={this.handleClose}
          update={this.handleCreate}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(PromoCode)
