import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Space from '../view/AffiliateDashboard/Space'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required.')
})

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    height: '327px',
    width: '480px',
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    padding: '43px 62px 0'
  },
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: '0 0 23px'
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#4a4a4a',
    fontWeight: 300,
    margin: '0 0 23px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    width: 244
  },
  button: {
    width: '100%',
    height: '60px',
    borderRadius: '10px',
    backgroundColor: '#fc5b63',
    textTransform: 'none',
    paddingBottom: '10px',
    '&:hover': {
      backgroundColor: '#fc5b63'
    }
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '18px',
    color: '#ffffff',
    margin: 0
  },
  inputFeild: {
    width: '100%',
    height: '50px',
    borderRadius: '2px',
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
    padding: '5px'
  }
}

class ResetModal extends Component {
  doReset = value => {
    const pid = localStorage.getItem('pid')

    axios
      .post(
        `https://4ybz6h0uy2.execute-api.ap-southeast-1.amazonaws.com/prod/api/1.0.0/users/reset-password`,
        {
          email: value
        }
      )
      .then(res => {
        const data = res.data
        const { status } = data
        console.log(data)

        if (status) {
          this.props.update(status)
        } else {
          this.props.update(status)
        }
      })
  }

  render() {
    const { open, handleClose, classes, email } = this.props

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.title}>Reset Password</div>
              <div className={classes.desc}>
                Enter the email address associated with your account, and we’ll
                email you a link to reset your password.
              </div>

              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  // same shape as initial values
                  this.doReset(values.email)
                  console.log(values.email)

                  handleClose()
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field
                      name="email"
                      type="email"
                      className={classes.inputFeild}
                    />
                    {errors.email && touched.email ? (
                      <div
                        style={{
                          fontSize: '13px',
                          color: '#d0021b',
                          fontWeight: 300,
                          fontFamily: 'Roboto'
                        }}
                      >
                        {errors.email}
                      </div>
                    ) : null}
                    <Space size="15" />
                    <Button className={classes.button} type="submit">
                      <p className={classes.buttonText}>Send reset link</p>
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

export default withStyles(styles)(ResetModal)
