// Service to package and upload any IMAGES to S3 //
//
// AWS sdk
const AWS = require('aws-sdk')
AWS.config.update({
  accessKeyId: 'AKIAIDEWLR3ZDWO265DQ',
  secretAccessKey: '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',
  region: 'ap-southeast-1'
})

///// SUPPORT METHOD ////
// Client naming
const getPrefixType = type => {
  switch (type) {
    case 'vehicles':
      return `vehicle`
    case 'companies':
      return `company`
    case 'locations':
      return `location`
    default:
      return `assets`
  }
}

// Prefix naming by Client type
const getPrefixby = (type, data) => {
  console.log('GDT >>', data)
  switch (type) {
    case 'vehicles':
      const operator_of =
        data.seed_key !== undefined
          ? data.seed_key.split('_')[0]
          : data.company_id
      const vehicleType = data.type.split('::')[1].toLowerCase()
      const process_key =
        data.seed_key !== undefined
          ? data.seed_key.split('_').join('-')
          : data.name
              .split(' ')
              .join('-')
              .toLowerCase()
      // console.log(
      //   'GFG >>>',
      //   `kohlife-${vehicleType}-thailand-${operator_of}-${process_key}`
      // )
      return `kohlife-${vehicleType}-thailand-${operator_of}-${process_key}`
    case 'companies':
      const op_name = data.op_name
        .split(' ')
        .join('-')
        .toLowerCase()
      return `kohlife-company-thailand-${op_name}`
    case 'locations':
      const process_name =
        data.seed_key !== undefined
          ? data.seed_key.split('_').join('-')
          : data.location_name
              .toLowerCase()
              .split(' ')
              .join('_')
      return `kohlife-station-thailand-${process_name}`
    default:
      return `${data}`
  }
}

// Index organization
const NIndex = (locations, findex) => {
  let nullBlock = []
  let result
  if (locations.length == 0) {
    result = findex + 1
  } else {
    const sort = locations.sort()
    const last = sort[sort.length - 1] //sort.splice(-1)[0];
    for (var index = 1; index <= last; index++) {
      if (index !== sort[index - 1] && index != last) {
        nullBlock.push(index)
      }
    }
    result =
      typeof nullBlock[findex] != 'undefined'
        ? nullBlock[findex]
        : locations.length + findex + 1
  }
  return result
}

// Add O Digit number
const pad = input => {
  var BASE = '000'
  return input ? BASE.substr(0, 3 - Math.ceil(input / 10)) + input : BASE
}

//// PROMISE METHOD ////
// convert file to BASE64
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)
    reader.onload = () => {
      return resolve(reader.result)
    }
    reader.onerror = reject
  })

// File compresser
const compressBackgroundBase64 = (file, data, resource) => {
  return Promise.resolve(
    file.map(picture64 => {
      const BUFFER = new Buffer(
        picture64.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      )
      const {
        background_image_picture: { title: pic_title },
        name,
        seed_key,
        location_name
      } = data
      const prefix =
        resource === 'companies'
          ? name
          : resource === 'locations'
          ? seed_key !== undefined
            ? seed_key
            : location_name
                .toLowerCase()
                .split(' ')
                .join('_')
          : ''
      const suffixGet = pic_title.split('.').slice(-1)
      // console.log('TRACKK >>', data)
      // console.log('RESOURCE >>', resource)
      return {
        Bucket: 'koh-assets',
        Key: `image/background/${resource}/${prefix}.${suffixGet}`,
        Filename: `${resource}_${prefix}.${suffixGet}`,
        ACL: 'public-read',
        Body: BUFFER
      }
    })
  )
}

const compressFileBase64 = (file, newPics, data, resource) => {
  if (resource === 'companies') {
    return Promise.resolve(
      file.map((picture64, fileIndex) => {
        const FILE_PERMISSION = 'public-read'
        const BUFFER = new Buffer(
          picture64.replace(/^data:image\/\w+;base64,/, ''),
          'base64'
        )
        const pic_name = data.pictures.title
        const prefixType = getPrefixType(resource)
        const prefixGet = getPrefixby(resource, data)
        const suffixGet = pic_name.split('.').slice(-1)
        const PARSE_URL = `${prefixGet}.${suffixGet}`
        const BUCKET_NAME = 'koh-assets'
        return {
          Bucket: BUCKET_NAME,
          Key: `${prefixType}/${PARSE_URL}`,
          Filename: PARSE_URL,
          ACL: FILE_PERMISSION,
          Body: BUFFER
        }
      })
    )
  } else {
    return Promise.resolve(
      file.map((picture64, fileIndex) => {
        const FILE_PERMISSION = 'public-read'
        const BUFFER = new Buffer(
          picture64.replace(/^data:image\/\w+;base64,/, ''),
          'base64'
        )
        const pic_name = newPics[fileIndex].title
        const exist_location =
          data.images === undefined
            ? []
            : data.images.map(({ title }) =>
                parseInt(
                  title
                    .split('-')
                    .splice(-1)[0]
                    .split('.')[0]
                )
              )
        const new_index = NIndex(exist_location, fileIndex)
        const indexGet = pad(new_index)
        const prefixType = getPrefixType(resource)
        const prefixGet = getPrefixby(resource, data)
        const suffixGet = pic_name.split('.').slice(-1)
        const PARSE_URL = `${prefixGet}-${indexGet}.${suffixGet}`
        const BUCKET_NAME = 'koh-assets'
        return {
          Bucket: BUCKET_NAME,
          Key: `${prefixType}/${PARSE_URL}`,
          Filename: PARSE_URL,
          ACL: FILE_PERMISSION,
          Body: BUFFER
        }
      })
    )
  }
}

// File uploader
const imageUploader = (files, AWS) =>
  new Promise(async (resolve, reject) => {
    resolve(
      await Promise.all(
        files.map(
          file =>
            new Promise(async (resolver, rejector) => {
              const s3 = new AWS.S3()
              s3.upload(file, function(err, data) {
                if (err) {
                  rejector(err)
                } else {
                  resolver({
                    title: file.Filename,
                    url: data.Location
                  })
                }
              })
            })
        )
      )
    )
  })

// Upload feature
const addUploadFeature = requestHandler => (type, resource, params) => {
  if (
    (type === 'UPDATE' || type === 'CREATE') &&
    (resource === 'vehicles' ||
      resource === 'companies' ||
      resource === 'locations')
  ) {
    if (params.data.pictures) {
      // Array Images
      if (params.data.pictures.length) {
        const newPics = params.data.pictures.filter(
          p => p.rawFile instanceof File
        )
        return Promise.all(newPics.map(convertFileToBase64))
          .then(files => {
            return compressFileBase64(files, newPics, params.data, resource)
          })
          .then(files => {
            return imageUploader(files, AWS)
          })
          .then(transformPics => {
            const previousImages =
              params.data.images !== undefined ? params.data.images : []
            return requestHandler(type, resource, {
              ...params,
              data: {
                ...params.data,
                images: [...transformPics, ...previousImages]
              }
            })
          })
      } else {
        // Single pictures changed
        return Promise.all([convertFileToBase64(params.data.pictures)])
          .then(files => compressFileBase64(files, '', params.data, resource))
          .then(files => imageUploader(files, AWS))
          .then(transformPics => {
            console.log('extract pic >>>', transformPics)
            return requestHandler(type, resource, {
              ...params,
              data: {
                ...params.data,
                image_logo: transformPics[0].url
              }
            })
          })
      }
    }
    if (params.data.background_image_picture) {
      return Promise.all([
        convertFileToBase64(params.data.background_image_picture)
      ])
        .then(files => compressBackgroundBase64(files, params.data, resource))
        .then(files => imageUploader(files, AWS))
        .then(transformPics => {
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              background_image: transformPics[0].url
            }
          })
        })
    }
  }
  return requestHandler(type, resource, params)
}

// Exported
export default addUploadFeature
