import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { routeActive, routeDeActive } from '../action/RouteAction'
import { tripActive, tripDeActive } from '../action/TripAction'
import { startUndoable as startUndoableAction } from 'ra-core'

class StatusButton extends Component {
  handleClick = actions => {
    const { startUndoable, record, resourceType } = this.props
    if (resourceType === 'route') {
      if (!actions) {
        startUndoable(routeActive(record.id, record))
      } else {
        startUndoable(routeDeActive(record.id, record))
      }
    } else if (resourceType === 'trip') {
      if (!actions) {
        startUndoable(tripActive(record.id, record))
      } else {
        startUndoable(tripDeActive(record.id, record))
      }
    }
  }
  render() {
    const { record } = this.props
    const status = record.status
    return (
      <Switch
        checked={record.status}
        onClick={() => this.handleClick(status)}
      />
    )
  }
}

StatusButton.propTypes = {
  record: PropTypes.object,
  startUndoable: PropTypes.func
}

export default connect(
  null,
  {
    startUndoable: startUndoableAction
  }
)(StatusButton)
