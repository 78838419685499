import React from 'react'
import { ArcSeries } from 'react-vis'

import {
  withKnobs,
  boolean,
  color,
  number,
  object,
  select,
  text
} from '@storybook/addon-knobs/react'

import { generateRadialData } from './Data'
import {
  SimpleChartWrapperNoAxes,
  SimpleRadialChartWrapper,
  jsxOptions
} from './Utils'

const PIChart = () => {
  const nbSlices = number(
    'nbSlice',
    5,
    { max: 8, min: 1, range: true, step: 1 },
    'Pie Chart'
  )
  return (
    <SimpleRadialChartWrapper
      data={generateRadialData({ key: 'radial-1' }).slice(0, nbSlices)}
    />
  )
}

export default PIChart
