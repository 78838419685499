import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'

class ConfirmWithdraw extends React.Component {
  doWithdraw = value => {
    const pid = localStorage.getItem('pid')
    this.props.confirm()
    axios
      .post(
        `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/wallet/withdraw/${pid}`,
        {
          withdraw: value
        }
      )
      .then(res => {
        const data = res.data.message

        console.log('doWithdraw >>> ', data)
        if (data) {
          console.log('after withdraw data >>> ', value)
          this.props.update(value)
        }
      })
      .catch(error => {
        this.props.update('error')
      })
  }

  render() {
    const { open, handleCloseConfirm, amount } = this.props

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Confirm your withdrawing ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Withdraw amount: ${amount} THB`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.doWithdraw(amount)} color="primary">
              Confirm
            </Button>
            <Button onClick={handleCloseConfirm} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default ConfirmWithdraw
