import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  ReferenceInput,
  LongTextInput,
  NumberInput,
  SelectInput,
  TextField,
  Toolbar,
  BulkDeleteButton,
  SaveButton,
  Create,
  SimpleForm,
  DeleteButton
} from 'react-admin'
import PromoCodeActionBar from './custom/view/PromoCodeActionbar'
import StatusButton from './custom/view/PromocodeStatusButton'
import BulkStatusButton from './custom/view/BulkStatusButton'
import DefaultIcon from '@material-ui/icons/Group'
import Pagination from './custom/view/Pagination'
import Button from '@material-ui/core/Button'

const PromocodeFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      source="status"
      defaultValue="active"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' }
      ]}
    />
    <SelectInput
      source="discount_type"
      defaultValue="percent"
      choices={[
        { id: 'percent', name: 'Percent' },
        { id: 'decimal', name: 'Decimal' }
      ]}
    />
    <SelectInput
      label="Code type"
      source="used_type"
      defaultValue="free_code"
      choices={[
        { id: 'normal', name: 'Normal Code' },
        { id: 'free_code', name: 'Free Code' },
        { id: 'affiliate', name: 'Affiliate Code' }
      ]}
    />
  </Filter>
)

const BulkActionButtons = props => (
  <Fragment>
    <BulkStatusButton
      text="Deactive All"
      direct="promocodes"
      action={null}
      {...props}
    />
    <BulkStatusButton
      text="Active All"
      direct="promocodes"
      action="active"
      {...props}
    />
    <BulkDeleteButton {...props} />
  </Fragment>
)

// Exporter
export const PromocodeIcon = () => <DefaultIcon />
export const PromocodeList = props => (
  <List
    {...props}
    filters={<PromocodeFilter />}
    title="Show user lists"
    bulkActionButtons={<BulkActionButtons />}
    actions={<PromoCodeActionBar />}
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="discount_type" />
      <TextField source="discount_amount" />
      <TextField source="used_type" label="Code Type" />
      <StatusButton label="Status" />
      <DeleteButton />
    </Datagrid>
  </List>
)

const PromotionToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/promocodes" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/promocodes" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const PromoCodeCreate = props => {
  return (
    <Create title="Promotion Create" {...props}>
      <SimpleForm toolbar={<PromotionToolbar />}>
        <TextInput source="code" label="Promotion code" />
        <NumberInput source="discount_amount" label="Discount amount" />
        <ReferenceInput
          label="Creator"
          source="creator"
          reference="users"
          perPage={500}
          sort={{ field: 'first_name', order: 'ASC' }}
          filter={{ user_type: 'partner' }}
        >
          <SelectInput optionText="first_name" />
        </ReferenceInput>
        <SelectInput
          source="used_type"
          label="Ticket Categories"
          choices={[
            { id: 'affiliate', name: 'Affiliate code' },
            { id: 'free_code', name: 'Free code' }
          ]}
        />
        <SelectInput
          source="discount_type"
          label="Discount type"
          choices={[{ id: 'percent', name: 'Percent %' }]}
        />
        <LongTextInput source="desc" label="Description" />
      </SimpleForm>
    </Create>
  )
}
