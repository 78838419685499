import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Space from './custom/view/AffiliateDashboard/Space'
import { allToolsData } from './custom/view/AffiliateDashboard/allToolsData'

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '57%'
  },
  card: {
    width: '342px',
    height: '161px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    padding: '17px 37px',
    marginRight: '57px',
    marginBottom: '30px',
    cursor: 'pointer'
  },
  titleCard: {
    display: 'flex',
    justifyContent: 'center',
    color: '#4a4a4a',
    fontSize: '20px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    margin: 0
  },
  image: {
    display: 'flex',
    width: '70px',
    height: '70px',
    margin: '0 auto'
  },
  titleContent: {
    color: '#4a4a4a',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Roboto',
    margin: 0
  },
  content: {
    color: '#4a4a4a',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    margin: 0
  }
}

class AllTools extends Component {
  handleChangeTap = index => {
    this.props.swipe(index)
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <Space size="60" />
        {allToolsData.map(({ title, img, titleContent, content }, index) => (
          <Fragment key={index}>
            <div className={classes.row}>
              <div
                className={classes.card}
                onClick={() => this.handleChangeTap(index + 1)}
              >
                <p className={classes.titleCard}>{title}</p>
                <Space size="27" />
                <img className={classes.image} src={img} alt={img} />
              </div>
              <div className={classes.column}>
                <div className={classes.titleContent}>{titleContent}</div>
                <Space size="24" />
                <p className={classes.content}>{content}</p>
              </div>
            </div>
          </Fragment>
        ))}
        <Space size="67" />
      </Fragment>
    )
  }
}

export default withStyles(styles)(AllTools)
