import DeepLink from '../../../assets/icr_deeplink.png'
import SearchBox from '../../../assets/icr_searchbox.png'
import Promo from '../../../assets/icr_promo.png'
import QR from '../../../assets/icr_qr.png'

export const allToolsData = [
  {
    title: 'Deeplink Generator',
    img: DeepLink,
    titleContent: 'Deeplink Generator',
    content: `This tool will help you create your unique link which contains your affiliate ID. You can choose the link to point to our specific available routes. When you got the link, you can include it within text on your website as a hyperlink or you can post on your social media. When a visitor clicks on this link, it has a unique affiliate ID attached to it so we know they have come from you.`
  },
  {
    title: 'Search Box',
    img: SearchBox,
    titleContent: 'Search Box',
    content: `Let visitors search routes and trips within your site. Easy to implement tool, it’s just a line of html script to implement on your site. You can customise the default route to display.`
  },
  {
    title: 'Promo-code Generator',
    img: Promo,
    titleContent: 'Promo-code Generator (works with physical store partner)',
    content: `Generate your unique promo-code for your visitor or customer. You can choose to give your customer a discount in percentage or in currency.`
  },
  {
    title: 'QR-code Generator',
    img: QR,
    titleContent: 'QR-code Generator (works with physical store partner)',
    content: `You can create your unique QR-Code which will link to our site with your affiliate ID. You can customise your QR-code to point to specific route.`
  }
]
