import React from 'react'
import { Spin } from 'antd'

const SpinnerView = ({ show }) => {
  return show ? (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2,
        background: '#80808073',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <span></span>
  )
}

export default SpinnerView
