import React from 'react'
import { LineSeries } from 'react-vis'

import { generateLinearData, nonUniformX } from './Data'
import { SimpleChartWrapper, jsxOptions } from './Utils'

const LineSerie = () => {
  return (
    <SimpleChartWrapper>
      <LineSeries data={generateLinearData({ key: 'line1' })} />
    </SimpleChartWrapper>
  )
}

export default LineSerie
