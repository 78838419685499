import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    // Button,
    SaveButton,
    SimpleForm,
    NumberInput,
    LongTextInput,
    CREATE,
    UPDATE,
    REDUX_FORM_NAME,
    TextField,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';

import dataProvider from '../../../dataProvider'

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
});

class ExtraPaymentButton extends Component {
    state = {
        error: false,
        showDialog: false
    };
    

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('send-email');
    };

    handleSubmit = (values, ...props) => {
        // console.log(values)
        const { change, fetchStart, fetchEnd, showNotification, record } = this.props;
        const { id } = record
        record['extraAmount'] = values.extraAmount
        record['reason'] = values.reason
        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        dataProvider(UPDATE, 'email/invoice/extrapayment', {id: id ,data: record} )
            .then(({ data }) => {
                // console.log(data)
                // Update the main react-admin form (in this case, the comments creation form)
                change(REDUX_FORM_NAME, 'put_id', data.id);
                
                this.setState({ showDialog: false });
            })
            .catch(error => {
                showNotification(error.message, 'error');
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                // alert('Extra Payment Done') 
                fetchEnd();
            });
    };

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record, sendTo, classes } = this.props;
        // alert(JSON.stringify(record))
        // console.log(this.props)
        let sendE = 'someOne@mail.com';
        let nameE = 'someOne';
        if (sendTo == 'user_email') {
            sendE = 'Customer'
            nameE = record.user_name
        } else if (sendTo == 'contact_email') {
            sendE = 'Operator'
            nameE = record.companyName
        };
        
        const confirm = (value, allValues) => {
            if (!value) {
                return '"confirm" is required';
            }
            if (value != 'confirm') {
                return 'just "confirm"';
            }
            return [];
        }

        return (
            <Fragment>
                <Button onClick={this.handleClick} color="secondary" variant="contained" size="large" className={classes.button} >
                    <span style={{ fontSize: '0.825rem' }} >{`Extra Payment`}</span>
                    <Icon style={{ marginLeft:'8px'}} >send </Icon>
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create post"
                >
                    <DialogTitle>{`Refund INV${('000000' + record.id).slice(-6)}`}</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="send-email"
                            resource="tickets"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <tr>
                                <span>
                                {'Extra Amount (THB): '}
                            <NumberInput
                                label='THB'
                                source="extraAmount"
                                validate={required()}
                                style={{ marginTop: '8px' }}
                                {...record}
                            />
                                </span>
                            </tr>
                            <tr>
                                <span>
                                    {'Reason for Extra Payment :'}
                                    <LongTextInput
                                        label='Why?'
                                        source="reason"
                                        validate={required()}
                                        style={{ marginTop: '5px' }}
                                        {...record}
                                    />
                                </span>
                            </tr>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                            color="secondary"
                            variant="contained"
                            size="large"
                        >
                            {`SEND EXTRA PAYMENT E-MAIL`}
                        </Button>
                        <Button
                            label="ra.action.cancel"
                            variant="contained"
                            size="large"
                            onClick={this.handleCloseClick}
                        >
                            {'BACK'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, PropTypes) => ({
    isSubmitting: isSubmitting('send-email')(state),
});


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

ExtraPaymentButton.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(
    ExtraPaymentButton
));