import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Space from './custom/view/AffiliateDashboard/Space'
import {
  theme,
  layout,
  departure,
  arrival
} from './custom/view/AffiliateDashboard/searchboxData'

const styles = theme => ({
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  card: {
    width: '100%',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    padding: '21px 3.7% 13px 4.7%',
    position: 'relative'
  },
  script: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: 0
  },
  copyText: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    position: 'absolute',
    bottom: '13px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  cardLeft: {
    width: '34.6%',
    height: '523px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleLeft: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '24px',
    top: '18px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  inputDesc: {
    position: 'absolute',
    left: '24px',
    top: '68px',
    width: '83%'
  },
  selectTheme: {
    position: 'absolute',
    left: '24px',
    top: '140px',
    width: '83%'
  },
  selectLayout: {
    position: 'absolute',
    left: '24px',
    top: '194px',
    width: '83%'
  },
  selectDeparture: {
    position: 'absolute',
    left: '24px',
    top: '302px',
    width: '83%'
  },
  selectArrival: {
    position: 'absolute',
    left: '24px',
    top: '360px',
    width: '83%'
  },
  span: {
    display: 'inline-block',
    width: '3.1%'
  },
  cardRight: {
    width: '63.2%',
    height: '523px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleRight: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '43px',
    top: '18px'
  },
  button: {
    width: '83%',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    position: 'absolute',
    left: '24px',
    top: '418px',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  }
})

class SearchBox extends Component {
  state = {
    theme: 'Theme',
    layout: 'Layout',
    departure: 'Bangkok',
    arrival: 'Rayong'
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  render() {
    const { classes } = this.props

    const data = {
      deeplinkDesc: `Let visitors search routes and trips within your site. Easy to implement tool, it’s just a line of html script to implement on your site. You can customise the default route to display.`,
      widgetscript: `<script src="https://s3-ap-southeast-1.amazonaws.com/koh-widget/koh/js/widget.min.js"></script> <div id="koh_widget" width="340" height="500" theme="forrest" from="bangkok" to="phuket" affiliate_id="GOUCNRDY" ></div>`
    }

    return (
      <Fragment>
        <Space size="66" />
        <p className={classes.title}>Deep link Generator</p>
        <Space size="24" />
        <p className={classes.desc}>{data.deeplinkDesc}</p>
        <Space size="45" />
        <p className={classes.title}>
          Search Widget Script (copy & paste this script to your site)
        </p>
        <Space size="20" />
        <div className={classes.card}>
          <div className={classes.script}>{data.widgetscript}</div>
          <span className={classes.copyText}>Copy Script</span>
        </div>
        <Space size="26" />
        <p className={classes.title}>Your unique link to specific route</p>
        <Space size="24" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.cardLeft}>
            <div className={classes.titleLeft}>Customise your search box</div>
            <div className={classes.container}>
              <Input
                placeholder="Description"
                className={classes.inputDesc}
                inputProps={{
                  'aria-label': 'Description'
                }}
              />
              <TextField
                id="select-theme"
                select
                value={this.state.theme}
                onChange={this.handleChange('theme')}
                className={classes.selectTheme}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
              >
                {theme.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="select-layout"
                select
                value={this.state.layout}
                onChange={this.handleChange('layout')}
                className={classes.selectLayout}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
              >
                {layout.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <div className={classes.titleLeft} style={{ top: '243px' }}>
                Select the route
              </div>
              <TextField
                id="select-departure"
                select
                value={this.state.departure}
                onChange={this.handleChange('depature')}
                className={classes.selectDeparture}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
              >
                {departure.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="select-arrival"
                select
                value={this.state.arrival}
                onChange={this.handleChange('arrival')}
                className={classes.selectArrival}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
              >
                {arrival.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Button className={classes.button} onClick={this.handleOpen}>
                <p className={classes.buttonText}>Generate Script</p>
              </Button>
            </div>
          </div>
          <div className={classes.span} />
          <div className={classes.cardRight}>
            <div className={classes.titleRight}>
              Search Box Preview (320x420)
            </div>
          </div>
        </div>
        <Space size="23" />
      </Fragment>
    )
  }
}

export default withStyles(styles)(SearchBox)
