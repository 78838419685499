import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { startUndoable as startUndoableAction } from 'ra-core'
import { CardActions, CreateButton } from 'react-admin'
import { promoCreateFreeCode } from '../action/PromocodeAction'
import CameraIcon from '@material-ui/icons/Camera'

class PromocodesActionsBar extends Component {
  handleCreateFreeTicket = () => {
    const { startUndoable } = this.props
    const amount = 5
    startUndoable(promoCreateFreeCode(amount))
  }
  render() {
    const {
      bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter
    } = this.props
    return (
      <CardActions>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems
          })}
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
          })}
        <CreateButton basePath={basePath} />
        <Button
          color="primary"
          size="small"
          style={{ padding: '5px 2px' }}
          onClick={this.handleCreateFreeTicket}
        >
          <CameraIcon style={{ marginRight: '5px' }} />
          Create Free Code (+5)
        </Button>
      </CardActions>
    )
  }
}

PromocodesActionsBar.propTypes = {
  record: PropTypes.object,
  startUndoable: PropTypes.func
}

export default connect(
  null,
  {
    startUndoable: startUndoableAction
  }
)(PromocodesActionsBar)
