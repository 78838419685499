// REPLACE with 'ra-data-json-server'
import provider from './DataProvider/DataJSON_Provider'
import addUploadFeature from './DataProvider/UploadFeature'
import { fetchUtils } from 'react-admin'
//
const httpClient = (url, options = {}) => {
  const pid = localStorage.getItem('pid')

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = localStorage.getItem('accessToken')
  options.headers.set('Authorization', `Bearer ${token}`)
  console.log('url >>> ', typeof url)
  if (url.match(/pending_order\?/g) !== null) {
    options.headers.set('pid', pid)
  }
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = provider(
  `${process.env.REACT_APP_URL_PARTNER}api/1.0.0`,
  httpClient
)

// Added Custom BASE64 encryption to imageInput
export default addUploadFeature(dataProvider)
