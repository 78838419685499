import { UPDATE } from 'react-admin'

export const ROUTE_ACTIVE = 'ROUTE_ACTIVE'
export const ROUTE_DEACTIVE = 'ROUTE_DEACTIVE'

export const tripActive = (id, data, basePath) => ({
  type: ROUTE_ACTIVE,
  payload: { id, data: { status: 'scheduled' } },
  meta: {
    fetch: UPDATE,
    resource: 'trips/status',
    onSuccess: {
      notification: {
        body: 'Trips - ACTIVE STATUS success',
        level: 'info'
      },
      redirectTo: '/trips',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Trips update failure',
        level: 'warning'
      }
    }
  }
})

export const tripDeActive = (id, data, basePath) => ({
  type: ROUTE_DEACTIVE,
  payload: { id, data: { status: 'canceled' } },
  meta: {
    fetch: UPDATE,
    resource: 'trips/status',
    onSuccess: {
      notification: {
        body: 'Trips - DE_ACTIVE STATUS  success',
        level: 'info'
      },
      redirectTo: '/trips',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Trips update failure',
        level: 'warning'
      }
    }
  }
})
