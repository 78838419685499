import React from 'react'
import { DateKOHField, NationField } from './custom/view/CustomField'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  EditButton,
  Edit,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
  TextInput,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  FormDataConsumer,
  Filter
  // downloadCSV,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Receipt'
import { BookingTable } from './custom/view/BookingTable'
import Pagination from './custom/view/Pagination'
import { DatePickerRange } from './custom/view/DatePickerRange'
import * as moment from 'moment'
import {
  CustomerEditInput,
  EmailEditInput,
  PhoneEditInput,
  NationEditInput,
  PickupEditInput
} from './custom/view/CustomInput'
import SendEmailButton from './custom/view/SendEmailButton'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import { getName } from 'country-list'

// BookingField
const BookingField = ({ record = {} }) => {
  const { booking_id, invoice_id } = record
  return (
    <div style={{ lineHeight: '15px', margin: '12px 0' }}>
      <span style={{ fontWeight: 'bold' }}>{booking_id}</span> <br />
      <a
        style={{ fontWeight: 'bold', fontSize: '12px', color: 'DarkGray' }}
        href={`#/invoices/${invoice_id}`}
      >
        {`INV${('000000' + invoice_id).slice(-6)}`}
      </a>
    </div>
  )
}

// FromField
const FromField = ({ record = {} }) => {
  const { location_name } = record
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      <span style={{ fontWeight: '500' }}>{location_name}</span> <br />
    </div>
  )
}
// ToField
const ToField = ({ record = {} }) => {
  const { location_name } = record
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      <span style={{ fontWeight: '500' }}>{location_name}</span> <br />
    </div>
  )
}

// ContractField
const ContractField = ({ record = {} }) => {
  const { user_email, user_nation, user_telephone } = record
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      {user_email}
      <NationField nationKey={user_nation} showNationText />
      <span style={{ fontWeight: '300' }}>{user_telephone}</span>
    </div>
  )
}
ContractField.defaultProps = { label: 'Contracts' }

// StatusField(new field)
const StatusField = ({ record = {} }) => {
  let styles = { color: 'green', marginTop: '-4px', fontSize: '0.8125rem' }
  switch (record.status) {
    case 'confirm':
      styles.color = 'green'
      break
    case 'pending':
      styles.color = 'orange'
      break
    case 'refund_customer':
      styles.color = 'red'
      break
    case 'staging_refund':
      styles.color = 'purple'
      break
    default:
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '-15px'
        }}
      >
        <p style={styles}> {record.status}</p>
      </div>
    </div>
  )
}
// TimeDepartureField
const TimeDepartureField = ({ record = {} }) => {
  const { departure } = record
  return (
    <div style={{ fontWeight: '500' }}>
      <span style={{}}>
        {moment(departure)
          .add(7, 'hours') //+7 hours for Thailand Local Time
          .format('HH:mm')}
      </span>
    </div>
  )
}

// ICON
export const TicketIcon = () => <DefaultIcon />

// InvoiceFilter
const BookingFilter = props => {
  const { filterValues } = props
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <DatePickerRange
        label="Booking Date Ranges"
        source="date_range"
        alwaysOn
      />
      <ReferenceInput
        label="Company"
        source="company"
        reference="companies"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput source="op_name" />
      </ReferenceInput>
      <ReferenceInput
        label="From Destination"
        source="from_area_id"
        reference="routes/filterStation"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ target: 'from_area_id' }}
        allowEmpty
      >
        <AutocompleteInput optionText="location_name" />
      </ReferenceInput>
      {filterValues.hasOwnProperty('from_area_id') && (
        <ReferenceInput
          label="From Stations"
          source="from_location_id"
          reference="routes/filterStation"
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{
            from_area_id: filterValues.from_area_id,
            target: 'from_location_id'
          }}
          allowEmpty
        >
          <AutocompleteInput optionText="location_name" />
        </ReferenceInput>
      )}
      {filterValues.hasOwnProperty('from_area_id') && (
        <ReferenceInput
          label="To Destination"
          source="to_area_id"
          reference="routes/filterStation"
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{
            // company_id: filterValues.company,
            from_area_id: filterValues.from_area_id,
            target: 'to_area_id'
          }}
          allowEmpty
        >
          <AutocompleteInput optionText="location_name" />
        </ReferenceInput>
      )}
      {filterValues.hasOwnProperty('from_location_id') &&
        filterValues.hasOwnProperty('to_area_id') && (
          <ReferenceInput
            label="To Stations"
            source="to_location_id"
            reference="routes/filterStation"
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{
              // company_id: filterValues.company,
              from_area_id: filterValues.from_area_id,
              from_location_id: filterValues.from_location_id,
              to_area_id: filterValues.to_area_id,
              target: 'to_location_id'
            }}
            allowEmpty
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        )}
    </Filter>
  )
}

const exporter = (records, fetchRelatedRecords) => {
  console.log(records)
  try {
    fetchRelatedRecords(records, 'company', 'companies').then(
      companyResponse => {
        fetchRelatedRecords(records, 'from', 'locations').then(fromResponse => {
          console.log('companyResponse', companyResponse)
          console.log('fromResponse', fromResponse)

          const data = records.map(record => {
            const {
              id,
              user_name,
              user_nation,
              user_telephone,
              // amount,
              // booking_id,
              company,
              created_at,
              departure,
              from,
              fromStation,
              // invoice_id,
              // itinerary,
              // no_refund,
              pickupBoolean,
              // price,
              // route_id,
              // schedule,
              status,
              to,
              toStation
              // tripName,
              // updated_at,
              // user_email,
              // user_id
            } = record
            console.log(company)
            console.log(typeof companyResponse[company])
            const result = {
              id: id,
              bookingDate: moment(created_at)
                .add(7, 'hours')
                .format('D MMM YYYY HH:mm'),
              customer: user_name,
              nation: getName(user_nation),
              telephone: user_telephone,
              company: JSON.stringify(companyResponse[company]),
              from: JSON.stringify(fromResponse[from]),
              to,
              fromStation,
              toStation,
              departureDate: moment(departure)
                .add(7, 'hours')
                .format('D MMM YYYY HH:mm'),
              pickup: pickupBoolean,
              status
            }
            return result
            // return ({
            //   ...result,
            //   company: companyResponse[parseInt(company)],
            // })
          })
          const csv = convertToCSV({
            data,
            fields: [
              'id',
              'bookingDate',
              'customer',
              'nation',
              'telephone',
              'company',
              'from',
              'to',
              'fromStation',
              'toStation',
              'departureDate',
              'pickup',
              'status'
            ]
          })
          // downloadCSV(csv, 'booking');
        })
      }
    )
  } catch (error) {
    console.log(error)
  }
}

// List
export const TicketList = props => (
  <List
    {...props}
    key={'id'}
    perPage={30}
    sort="created_at"
    filters={<BookingFilter />}
    pagination={<Pagination />}
    exporter={exporter}
    // bulkActions={false}
  >
    <Datagrid>
      <BookingField label="Booking ID" source="booking_id" />
      <DateKOHField
        label="Booking Date"
        timeKey="created_at"
        showTime
        {...props}
      />
      <TextField label="Customer" source="user_name" />
      <ContractField />
      <ReferenceField
        label="Company"
        source="company"
        reference="companies"
        sortable={false}
        allowEmpty
      >
        <TextField
          source="op_name"
          style={{ fontWeight: '500', fontSize: '0.8125rem' }}
        />
      </ReferenceField>
      <ReferenceField
        label="From Destination"
        source="from"
        reference="locations"
        sortable={false}
      >
        <FromField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="To Destination"
        source="to"
        reference="locations"
        sortable={false}
      >
        <ToField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="From Stations"
        source="fromStation"
        reference="locations"
        sortable={false}
      >
        <FromField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="To Station"
        source="toStation"
        reference="locations"
        sortable={false}
      >
        <ToField source="location_name" />
      </ReferenceField>
      <TimeDepartureField label="Time" source="departure" />
      <DateKOHField label="Departure Date" timeKey="departure" {...props} />
      <BooleanField label="pickup" source="pickupBoolean" />
      <StatusField label="Status" source="status" />
      <EditButton />
    </Datagrid>
  </List>
)

// Edit page componant
// BookingIdField
const BookingIdField = ({ record = {} }) => {
  const { booking_id } = record
  return (
    <div style={{ lineHeight: '30px', margin: '30px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Booking ID :</span>{' '}
      <span style={{ color: 'DarkGray' }}>{booking_id}</span>
    </div>
  )
}
BookingIdField.defaultProps = { label: 'Booking ID' }

// InvoiceIDField
const InvoiceIDField = ({ record = {} }) => {
  const { invoice_id } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Invoice ID :</span>{' '}
      <a href={`#/invoices/${invoice_id}`} style={{ color: 'DarkGray' }}>
        {`INV${('000000' + invoice_id).slice(-6)}`}
      </a>
    </div>
  )
}
InvoiceIDField.defaultProps = { label: 'Invoice_ID' }

// TripNameField
const TripNameField = ({ record = {} }) => {
  const { tripName } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Trip :</span>{' '}
      <span style={{ color: 'DarkGray' }}>{tripName}</span>
    </div>
  )
}
TripNameField.defaultProps = { label: 'TripName' }

// TravelDateField
const TravelDateField = ({ record = {} }) => {
  const { departure } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold' }}>{'Booking Date :'}</span>{' '}
      <span style={{ color: 'DarkGray' }}>
        {moment(departure)
          .add(7, 'hours') //+7 hours for Thailand Local Time
          .format('D MMM YYYY')}
        {', '}
        {moment(departure).format('HH:mm')}
      </span>
    </div>
  )
}
TravelDateField.defaultProps = { label: 'Booking Date' }

// TripIDField
const TripIDField = ({ record = {} }) => {
  const { route_id } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Trip :</span>{' '}
      <span style={{ color: 'DarkGray' }}>{route_id}</span>
    </div>
  )
}
TripIDField.defaultProps = { label: 'TripName' }

// BookingDateField
const BookingDateField = ({ record = {} }) => {
  const { created_at } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold' }}>{'Booking Date :'}</span>{' '}
      <span style={{ color: 'DarkGray' }}>
        {moment(created_at)
          .add(7, 'hours') //+7 hours for Thailand Local Time
          .format('D MMM YYYY')}
        {', '}
        {moment(created_at).format('HH:mm')}
      </span>
    </div>
  )
}
BookingDateField.defaultProps = { label: 'Booking Date' }

// PriceField
const PriceField = ({ record = {} }) => {
  const { price } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Paid :</span>{' '}
      <span style={{ color: 'DarkGray' }}>{`${price} Baht`}</span>
    </div>
  )
}
PriceField.defaultProps = { label: 'TripName' }

// AmountPassangerField
const AmountPassangerField = ({ record = {} }) => {
  const { amount } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>passenger:</span>{' '}
      <span style={{ color: 'DarkGray' }}>{`${amount} persons`}</span>
    </div>
  )
}
AmountPassangerField.defaultProps = { label: 'passenger' }

// Toolbar
const TicketToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="SAVE"
      redirect={(source, id, record) => {
        // console.log(source, id, record)
        return `/tickets/${id}`
      }}
    />
    <span style={{ margin: '0 6px' }} />
    <SendEmailButton sendTo="user_email" {...props} />
    <span style={{ margin: '0 6px' }} />
    <SendEmailButton sendTo="operator_email" {...props} />
    <span style={{ margin: '0 6px' }} />
    <a href="/#/tickets" style={{ textDecoration: 'none' }}>
      <Button variant="contained" size="large">
        BACK
      </Button>
    </a>
    <span style={{ margin: '0 6px' }} />
  </Toolbar>
)

const TicketAside = ({ record }) => {
  let itinerary = []
  if (typeof record != 'undefined') {
    itinerary = [...record.itinerary]
  }
  return (
    <div style={{ width: 280, margin: '2em' }}>
      {/* {typeof record != 'undefined' && record.itinerary.length > 0 && } */}
      {itinerary.map(({ terminal, vehicleType, travel_time }, index) => {
        const indexColor = index === 0 ? '#6d6d6d' : '#9a9a9a'
        return (
          <div
            style={{
              borderLeft: `5px solid ${indexColor}`,
              padding: '0 20px 54px'
            }}
          >
            {terminal} <br />
            <div
              style={{
                lineHeight: '22px',
                padding: '4px 0px 0px 10px',
                color: '#8c8c8c'
              }}
            >
              {index === 0 && (
                <div>
                  [From] <br />
                  {moment(record.departure)
                    .add(7, 'hours')
                    .format('dddd, MMMM D YYYY, HH:mm')}
                </div>
              )}
              {vehicleType} <br /> {travel_time}
            </div>
          </div>
        )
      })}
      {typeof record != 'undefined' && (
        <div style={{ borderLeft: '5px solid #6d6d6d', padding: '0 20px' }}>
          {record.toTerminal}
          <div
            style={{
              lineHeight: '22px',
              padding: '4px 0px 0px 10px',
              color: '#8c8c8c'
            }}
          >
            [Arrive] <br />
            {moment(record.arrival)
              .add(7, 'hours')
              .format('dddd, MMMM D YYYY, HH:mm')}
          </div>
        </div>
      )}
    </div>
  )
}

export const TicketEdit = props => (
  <Edit title="Ticket Edit" aside={<TicketAside />} {...props}>
    <TabbedForm toolbar={<TicketToolbar />}>
      <FormTab label="Detail">
        <BookingIdField source="booking_id" label="Ticket ID" />
        <InvoiceIDField source="invoice_id" label="Invoice ID" />
        <TripNameField source="tripName" label="Trip" />
        <TravelDateField label="Travel Date" source="departure" />
        <TripIDField source="route_id" label="Trip ID" />
        <BookingDateField label="Booking Date" source="created_at" />
        <PriceField source="price" label="Paid" />
        <CustomerEditInput source="user_name" />
        <EmailEditInput source="user_email" {...props} />
        <NationEditInput source="user_nation" />
        <PhoneEditInput source="user_telephone" />
        <AmountPassangerField source="amount" label="Passenger(person)" />
        <ArrayInput
          source="passengerRid"
          label="passenger name(old database)"
          style={{
            width: '470px',
            margin: '15px 0px 15px 36px',
            fontSize: 14
          }}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData, dispatch, getSource, ...rest }) => (
                <div>
                  <td>
                    <span style={{ marginLeft: '20pt' }}>
                      <SelectInput
                        label="Title"
                        source={getSource('title')}
                        choices={[
                          { id: 'mr', name: 'Mr.' },
                          { id: 'miss', name: 'Miss' },
                          { id: 'mrs', name: 'Mrs.' },
                          { id: 'ms', name: 'Ms.' }
                        ]}
                        style={{
                          minWidth: '50px',
                          marginRight: '10px',
                          height: '47.9px'
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    <span style={{ height: '20px' }}>
                      <TextInput
                        label="Firstname"
                        source={getSource('firstname')}
                        style={{ marginRight: '10px' }}
                      />
                    </span>
                  </td>
                  <td>
                    <span>
                      <TextInput
                        label="Lastname"
                        source={getSource('lastname')}
                      />
                    </span>
                  </td>
                </div>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="travellersRef"
          label="travellers name(new database)"
          style={{
            width: '470px',
            margin: '15px 0px 15px 36px',
            fontSize: 14
          }}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData, dispatch, getSource, ...rest }) => (
                <div>
                  <td>
                    <span style={{ marginLeft: '20pt' }}>
                      <SelectInput
                        label="Title"
                        source={getSource('travellers_title')}
                        choices={[
                          { id: 'mr', name: 'Mr.' },
                          { id: 'miss', name: 'Miss' },
                          { id: 'mrs', name: 'Mrs.' },
                          { id: 'ms', name: 'Ms.' }
                        ]}
                        style={{
                          minWidth: '50px',
                          marginRight: '10px',
                          height: '47.9px'
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    <span style={{ height: '20px' }}>
                      <TextInput
                        label="Firstname"
                        source={getSource('travellers_firstname')}
                        style={{ marginRight: '10px' }}
                      />
                    </span>
                  </td>
                  <td>
                    <span>
                      <TextInput
                        label="Lastname"
                        source={getSource('travellers_lastname')}
                      />
                    </span>
                  </td>
                </div>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <PickupEditInput source="pickup" {...props} />
      </FormTab>
      <FormTab label="Activities">
        <BookingTable {...props} />
      </FormTab>
    </TabbedForm>
  </Edit>
)
