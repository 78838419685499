import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as moment from 'moment'


export class BookingTable extends Component {
  state = {
    fromDate: 'null',
    toDate: null
  }
  
  render() {
    const { className,
      basePath,
      record,
      resource
    } = this.props

    console.log(record)

    const {
      id,
      price,
      booking_id,
      created_at,
      updated_at,
      no_refund,
      agent_gain,
      margin,
      net_price,
      saving,
      promo_code_store,
    } = record

    function dateKohFormatter(cell, row) {
      if (cell == '') {
        return ''
      }

      return (
        <div style={{ minWidth: '90px', lineHeight: '20px', margin: '15px 15px 15px 0px' }}>
          <span style={{ fontWeight: '500' }}>
            {moment(cell)
              // .add(7, 'hours') //+7 hours for Thailand Local Time
              .format('D MMM YYYY')}
          </span>
          <br />
          <span>{moment(cell).format('HH:mm')}</span>
            
        </div>
      );
    }

    const detail = [{
        id: 0,
        name: 'Paid: ',
        amount: price,
        date: updated_at,
        note: '-',
    }, {
        id: 1,
        name: 'net_price',
        amount: net_price,
        date: updated_at,
        note: '-',
    }, {
        id: 2,
        name: `agent_gain`,
        amount: agent_gain,
        date: updated_at,
        note: promo_code_store,
    }, {
        id: 3,
        name: `saving`,
        amount: saving,
        date: updated_at,
        note: '-',
    }
    ];
    const columns = [{
        dataField: 'name',
        text: '',
        align: 'left',
      headerAlign: 'left',
      headerStyle: {
        width: '100px',
      },
      
    }, {
        dataField: 'amount',
        text: 'Amount',
        align: 'left',
        headerAlign: 'left',
        headerStyle:{width: '100px'},
    }, {
        dataField: 'date',
        text: 'Date',
        align: 'left',
        headerAlign: 'left',
        formatter: dateKohFormatter,
        headerStyle: { width: '100px' },
    }, {
        dataField: 'note',
        text: 'Remarks',
        align: 'center',
        headerAlign: 'left',
        headerStyle:{width: '100px'},
      }];
      const cellEditProps = {
        mode: 'click'
      };
    return (
      <div style={{ margin: '40px 36px' }} >
        <BootstrapTable
          cellEdit={ cellEditProps }
          keyField={id}
          data={detail}
          columns={columns}
          bordered={false}
          />
      </div>
    )
  }
}

BookingTable.propTypes = {
  classes: PropTypes.object,
}
export default BookingTable;