import { UPDATE, CREATE } from 'react-admin'

export const PROMOCODE_ACTIVE = 'PROMOCODE_ACTIVE'
export const PROMOCODE_DEACTIVE = 'PROMOCODE_DEACTIVE'
export const PROMOCODE_CREATE_FREE_CODE = 'PROMOCODE_CREATE_FREE_CODE'

export const promoCreateFreeCode = (amount, basePath) => ({
  type: PROMOCODE_CREATE_FREE_CODE,
  payload: { data: { post_trigger: 'free_code_created', amount: amount } },
  meta: {
    fetch: CREATE,
    resource: 'promocodes',
    onSuccess: {
      notification: {
        body: 'Promocode - created free-code success',
        level: 'info'
      },
      redirectTo: '/promocodes',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Promocode create failure',
        level: 'warning'
      }
    }
  }
})

export const promocodeActive = (id, data, basePath) => ({
  type: PROMOCODE_ACTIVE,
  payload: { id, data: { status: 'active' } },
  meta: {
    fetch: UPDATE,
    resource: 'promocodes',
    onSuccess: {
      notification: {
        body: 'Promocode - ACTIVE STATUS success',
        level: 'info'
      },
      redirectTo: '/promocodes',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Promocode update failure',
        level: 'warning'
      }
    }
  }
})

export const promocodeDeActive = (id, data, basePath) => ({
  type: PROMOCODE_DEACTIVE,
  // payload: { id, data: { ...data, status: null } },
  payload: { id, data: { status: null } },
  meta: {
    fetch: UPDATE,
    resource: 'promocodes',
    onSuccess: {
      notification: {
        body: 'Promocode - DE_ACTIVE STATUS  success',
        level: 'info'
      },
      redirectTo: '/promocodes',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Promocode update failure',
        level: 'warning'
      }
    }
  }
})
