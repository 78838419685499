import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Space from './custom/view/AffiliateDashboard/Space'
import Icon from '@material-ui/core/Icon'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required.')
})

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    height: '251px',
    width: '352px',
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    padding: '40px 39px 0',
    position: 'relative'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    width: 244
  },
  button: {
    width: '261px',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    paddingBottom: '10px',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  inputFeild: {
    width: '261px',
    height: '50px',
    borderRadius: '2px',
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
    padding: '5px'
  },
  cross: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  }
}

class WithdrawPaypal extends Component {
  doEdit = value => {
    const pid = localStorage.getItem('pid')
    this.props.loadState()
    axios
      .put(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/wallet/${pid}`, {
        transaction_email: value
      })
      .then(res => {
        const data = res.data.message
        const { id } = data
        if (data) {
          this.props.update(id)
        }
      })
      .catch(error => {
        this.props.updateError(error)
      })
  }

  render() {
    const { open, handleClose, classes, email } = this.props

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.title}>Edit your Paypal Account</div>
              <div className={classes.desc} style={{ color: 'red' }}>
                !Please enter the paypal email only
              </div>
              <Icon className={classes.cross} onClick={handleClose}>
                close
              </Icon>
              <Space size="15" />

              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  // same shape as initial values
                  console.log(values.email)
                  this.doEdit(values.email)
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field
                      name="email"
                      type="email"
                      className={classes.inputFeild}
                    />
                    {errors.email && touched.email ? (
                      <div
                        style={{
                          fontSize: '13px',
                          color: '#d0021b',
                          fontWeight: 300,
                          fontFamily: 'Roboto'
                        }}
                      >
                        {errors.email}
                      </div>
                    ) : null}
                    <Space size="15" />
                    <Button className={classes.button} type="submit">
                      <p className={classes.buttonText}>Edit Paypal Account</p>
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

export default withStyles(styles)(WithdrawPaypal)
