import React from 'react'
import {
  List,
  Edit,
  Create,
  Datagrid,
  FunctionField,
  BooleanField,
  TextInput,
  TextField,
  SelectInput,
  EmailField,
  EditButton,
  DeleteButton,
  SaveButton,
  SimpleForm,
  email,
  Toolbar,
  BooleanInput
} from 'react-admin'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Group'
import { DateKOHField } from './custom/view/CustomField'
import Pagination from './custom/view/Pagination'

// Exporter
export const AdminIcon = () => <DefaultIcon />
export const AdminList = props => {
  return (
    <List {...props} title="Show user lists" pagination={<Pagination />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <FunctionField
          label="Username"
          render={record => `${record.first_name} ${record.last_name}`}
        />
        <EmailField source="email" />
        <TextField label="Tel. number" source="main_contact_number" />
        <BooleanField source="is_super_admin" label="Super Admin" />
        <TextField label="User role" source="user_type" />
        <DateKOHField label="Created" timeKey="created_at" {...props} />
        <EditButton />
        {/* <DeleteButton /> */}
      </Datagrid>
    </List>
  )
}

const AdminToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/admins" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/admins" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const AdminEdit = props => {
  return (
    <Edit title="Admin Edit" {...props}>
      <SimpleForm toolbar={<AdminToolbar />}>
        <TextInput label="First Name" source="first_name" />
        <TextInput label="Last Name" source="last_name" />
        <TextInput
          label="Email"
          source="email"
          type="email"
          validate={email()}
        />
        <TextInput label="Telephone number" source="main_contact_number" />
        <TextInput label="Password" source="password" type="password" />
        <SelectInput
          label="Staff role"
          source="user_type"
          defaultValue="admin"
          choices={[
            { id: 'admin', name: 'Admin' },
            { id: 'admin_seo_manager', name: 'SEO Manager' }
          ]}
        />
        <BooleanInput source="is_super_admin" />
      </SimpleForm>
    </Edit>
  )
}

export const AdminCreate = props => {
  return (
    <Create title="Admin Create" {...props}>
      <SimpleForm toolbar={<AdminToolbar />}>
        <TextInput label="First Name" source="first_name" />
        <TextInput label="Last Name" source="last_name" />
        <TextInput
          label="Email"
          source="email"
          type="email"
          validate={email()}
        />
        <TextInput label="Telephone number" source="main_contact_number" />
        <TextInput label="Password" source="password" type="password" />
        <SelectInput
          label="Staff role"
          source="user_type"
          defaultValue="admin"
          choices={[
            { id: 'admin', name: 'Admin' },
            { id: 'admin_seo_manager', name: 'SEO Manager' }
          ]}
        />
        <BooleanInput source="is_super_admin" defaultValue="true" />
      </SimpleForm>
    </Create>
  )
}
