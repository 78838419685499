import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { notification, Icon } from 'antd'

import axios from 'axios'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Space from './custom/view/AffiliateDashboard/Space'

const departureData = location => {
  let loc = location.map(({ id, name, url_key }, index) => {
    return {
      ['id']: id,
      ['url_key']: url_key,
      ['value']: name,
      ['label']: name
    }
  })

  return loc
}

const filterRouteBlank = (option, routematch) => {
  let filter = []

  Object.keys(routematch).forEach(key => {
    // console.log('filterRouteBlank key >>> ', key)

    option.map(item => {
      if (item.id === Number(key)) {
        filter.push(item)
      }
    })
  })

  return filter
}

const findUrlKey = (option, value) => {
  let url = option.filter(item => {
    return item.value === value
  })

  console.log('url >>> ', url, 'url_key', url[0].url_key)
  return url[0].url_key
}

const styles = theme => ({
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  card: {
    width: '100%',
    height: '110px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    padding: '31px 3.7% 13px 4.7%',
    position: 'relative'
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: 0
  },
  copyText: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    position: 'absolute',
    bottom: '30px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  cardLeft: {
    width: '34.6%',
    height: '263px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleLeft: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '24px',
    top: '18px'
  },
  selectDeparture: {
    position: 'absolute',
    left: '24px',
    top: '84px',
    width: '83%'
  },
  selectArrival: {
    position: 'absolute',
    left: '24px',
    top: '134px',
    width: '83%'
  },
  span: {
    display: 'inline-block',
    width: '3.1%'
  },
  cardRight: {
    width: '63.2%',
    height: '263px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleRight: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '43px',
    top: '18px'
  },
  routeLink: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: '0 auto',
    left: 0,
    right: 0,
    bottom: '50%'
  },
  copyLink: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    position: 'absolute',
    bottom: '37%',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  button: {
    width: '83%',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    position: 'absolute',
    left: '24px',
    top: '195px',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  }
})

class DeepLink extends Component {
  state = {
    departure: 'Bangkok',
    departureOption: [],
    departureKey: 'bangkok',
    arrival: 'Chiangmai',
    arrivalOption: [],
    arrivalKey: 'chiangmai',
    pid: '',
    routematch: {},
    deeplink: '-',
    routeDeeplink: '-',
    routeDesc: '',
    value: '',
    copied: false
  }

  componentDidMount() {
    const { departureKey, arrivalKey, departure, arrival } = this.state
    const pid = localStorage.getItem('pid')

    axios
      .get(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/deeplink/${pid}/`)
      .then(res => {
        const data = res.data
        const pid = data.message.deeplink.split('=').pop()
        const routeDeeplink = `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}?pid=${pid}`
        // console.log('Deeplink data >>> ', data.message)

        if (data.message.deeplink) {
          this.setState({
            deeplink: data.message.deeplink,
            pid,
            routeDeeplink,
            routeDesc: `Your affiliate link for ${departure} to ${arrival}`
          })
        }
      })

    axios
      .get(
        `https://82ywqgk2ik.execute-api.ap-southeast-1.amazonaws.com/dev/locations/searchbox`
      )
      .then(res => {
        const data = res.data
        // console.log('locations data >>> ', data.message)

        if (data.status) {
          this.setState({
            departureOption: departureData(data.message),
            arrivalOption: departureData(data.message)
          })
        }
      })

    axios
      .get(
        `https://7eliseuvsc.execute-api.ap-southeast-1.amazonaws.com/dev/transportations/routesMatch`
      )
      .then(res => {
        const data = res.data
        // console.log('routematch data >>> ', data.message)

        if (data.status) {
          this.setState({ routematch: data.message })
        }
      })
  }

  handleArrival = (option, routematch, name) => {
    let data = option.filter(item => {
      return item.value === name
    })

    let Route = []
    Object.entries(routematch).forEach(route => {
      // console.log(route[0])
      if (Number(route[0]) === data[0].id) {
        Route.push(route[1])
      }
    })

    // console.log('handleArrival >>> ', Route, data[0].id)

    let Arrival = []

    if (Route.length > 0) {
      Object.values(Route[0]).forEach(value => {
        option.map(item => {
          if (item.id === value) {
            Arrival.push(item)
          }
        })
      })
    }

    this.setState({ arrivalOption: Arrival })

    // console.log('arrival value >>> ', Arrival)
  }

  handleChange = name => event => {
    const { departureOption, routematch } = this.state

    if (name === 'departure') {
      this.handleArrival(departureOption, routematch, event.target.value)
      findUrlKey(departureOption, event.target.value)

      this.setState({
        [name]: event.target.value,
        [`${name}Key`]: findUrlKey(departureOption, event.target.value)
      })
    } else {
      this.setState({
        [name]: event.target.value,
        [`${name}Key`]: findUrlKey(departureOption, event.target.value)
      })
    }
  }

  success = () => {
    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Copy Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  generateLink = () => {
    const { departureKey, arrivalKey, pid, departure, arrival } = this.state
    const routeDeeplink = `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}?pid=${pid}`

    this.setState({
      routeDeeplink,
      routeDesc: `Your affiliate link for ${departure} to ${arrival}`
    })
  }

  render() {
    const { classes } = this.props
    const {
      departure,
      arrival,
      deeplink,
      routeDeeplink,
      departureOption,
      arrivalOption,
      routematch,
      routeDesc
    } = this.state
    const departureList = filterRouteBlank(departureOption, routematch)
    const arrivalList = filterRouteBlank(arrivalOption, routematch)

    const data = {
      deeplinkDesc: `This tool will help you create your unique link which contains your affiliate ID. You can choose the link to point to our specific available routes. When you got the link, you can include it within text on your website as a hyperlink or you can post on your social media. When a visitor clicks on this link, it has a unique affiliate ID attached to it so we know they have come from you.`,
      routeDeeplinkText: routeDesc
    }
    return (
      <Fragment>
        <Space size="66" />
        <p className={classes.title}>Deep link Generator</p>
        <Space size="24" />
        <p className={classes.desc}>{data.deeplinkDesc}</p>
        <Space size="26" />
        <p className={classes.title}>Your unique link to Kohlife’s homepage</p>
        <Space size="20" />
        <div className={classes.card}>
          <div className={classes.link}>{deeplink}</div>
          <CopyToClipboard
            text={deeplink}
            onCopy={() => this.setState({ copied: true })}
          >
            <span className={classes.copyText} onClick={this.success}>
              Copy link
            </span>
          </CopyToClipboard>
        </div>
        <Space size="26" />
        <p className={classes.title}>Your unique link to specific route</p>
        <Space size="24" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.cardLeft}>
            <p className={classes.titleLeft}>Select the route</p>
            <TextField
              id="select-departure"
              select
              value={this.state.departure}
              onChange={this.handleChange('departure')}
              className={classes.selectDeparture}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {departureList.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="select-arrival"
              select
              value={this.state.arrival}
              onChange={this.handleChange('arrival')}
              className={classes.selectArrival}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {arrivalList.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Button className={classes.button} onClick={this.generateLink}>
              <p className={classes.buttonText}>Generate link</p>
            </Button>
          </div>
          <div className={classes.span} />
          <div className={classes.cardRight}>
            <p className={classes.titleRight}>{data.routeDeeplinkText}</p>
            <p className={classes.routeLink}>{routeDeeplink}</p>
            <CopyToClipboard
              text={routeDeeplink}
              onCopy={() => this.setState({ copied: true })}
            >
              <span className={classes.copyLink} onClick={this.success}>
                Copy link
              </span>
            </CopyToClipboard>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(DeepLink)
