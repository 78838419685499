import { UPDATE } from 'react-admin'

export const ROUTE_ACTIVE = 'ROUTE_ACTIVE'
export const ROUTE_DEACTIVE = 'ROUTE_DEACTIVE'

export const routeActive = (id, data, basePath) => ({
  type: ROUTE_ACTIVE,
  payload: { id, data: { status: 'available' } },
  meta: {
    fetch: UPDATE,
    resource: 'routes/status',
    onSuccess: {
      notification: {
        body: 'Routes - ACTIVE STATUS success',
        level: 'info'
      },
      redirectTo: '/routes',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Routes update failure',
        level: 'warning'
      }
    }
  }
})

export const routeDeActive = (id, data, basePath) => ({
  type: ROUTE_DEACTIVE,
  payload: { id, data: { status: 'close' } },
  meta: {
    fetch: UPDATE,
    resource: 'routes/status',
    onSuccess: {
      notification: {
        body: 'Routes - DE_ACTIVE STATUS  success',
        level: 'info'
      },
      redirectTo: '/routes',
      basePath
    },
    onFailure: {
      notification: {
        body: 'Routes update failure',
        level: 'warning'
      }
    }
  }
})
