// Import Group
import React, { Fragment } from 'react'
// import Dashboard from './Component/Dashboard'
import authProvider from './Component/authProvider'
import dataProvider from './dataProvider'
import './App.css'
import { Admin, Resource } from 'react-admin'
import { UserList, UserEdit } from './Component/Users'
import { AdminList, AdminEdit, AdminCreate } from './Component/Admins'
import { VehicleList, VehicleEdit, VehicleCreate } from './Component/Vehicles'
import { CompanyList, CompanyEdit, CompanyCreate } from './Component/Companies'
import { ContentList, ContentEdit, ContentCreate } from './Component/Contents'
import { InvoiceList, InvoiceEdit, InvoiceCreate } from './Component/Invoices'
import { ReviewList, ReviewEdit, ReviewCreate } from './Component/Reviews'
import { RoutesList, RoutesCreate, RoutesEdit } from './Component/Routes'
import { TempInvoicesList } from './Component/TempInvoices'
import { TripsList, TripsEdit, TripsCreate } from './Component/Trips'
import { PromocodeList, PromoCodeCreate } from './Component/Promocodes'
import { PartnerList, PartnerEdit } from './Component/Partners'
import { TicketList, TicketEdit } from './Component/Tickets'
import {
  SEORoutesList,
  SEORoutesCreate,
  SEORoutesEdit
} from './Component/SEORoutes'
import {
  SEODestinationsList,
  SEODestinationsCreate,
  SEODestinationsEdit
} from './Component/SEODestinations'
import {
  SEOCountriesList,
  SEOCountriesCreate,
  SEOCountriesEdit
} from './Component/SEOCountries'
import {
  SEOCountryVehicleList,
  SEOCountryVehicleCreate,
  SEOCountryVehicleEdit
} from './Component/SEOCountryVehicle'
import {
  LocationList,
  LocationCreate,
  LocationEdit
} from './Component/Locations'
import { Graph } from './Component/Graph'
import AffiliateDashboard from './Component/AffiliateDashboard'
import BookingReport from './Component/BookingReport'
import Withdraw from './Component/Withdraw'
import Profile from './Component/Profile'
import Tools from './Component/Tools'
// Icon group
import RoutesIcon from '@material-ui/icons/EventNote'
import TripsIcon from '@material-ui/icons/EventAvailable'
import TicketIcon from '@material-ui/icons/AirplanemodeActive'
import InvoiceIcon from '@material-ui/icons/ShoppingBasket'
import TempInvoiceIcon from '@material-ui/icons/ReportProblem'
import VehIcon from '@material-ui/icons/Train'
import ReviewIcon from '@material-ui/icons/RateReview'
import LocationIcon from '@material-ui/icons/Place'
import ContentIcon from '@material-ui/icons/PermMedia'
import PromoIcon from '@material-ui/icons/Grade'
import CompanyIcon from '@material-ui/icons/Business'
import UserIcon from '@material-ui/icons/AccountBox'
import AdminIcon from '@material-ui/icons/Android'
import PartnerIcon from '@material-ui/icons/AssignmentInd'
import GraphIcom from '@material-ui/icons/TrendingUp'
import AffiliateIcon from '@material-ui/icons/Group'
// Theming
import { createMuiTheme } from '@material-ui/core/styles'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'
// Authentication
import LogoutButton from './Component/custom/auth/Logout'
import LoginPage from './Component/custom/auth/LoginPage'
import LoginPagePartner from './Component/custom/auth/LoginPagePartner'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import '../node_modules/react-vis/dist/style.css'
import 'antd/dist/antd.css'

const AdminTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: indigo,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#2D2D2D'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          color: '#283593 !important'
        }
      }
    }
  }
})

const gateway = permissions => {
  // check user :: permission
  // console.log('permissions >>> ', permissions)
  if (permissions === 'admin') {
    return [
      //Booking and Invoices
      <Resource
        name="invoices"
        list={InvoiceList}
        edit={InvoiceEdit}
        icon={InvoiceIcon}
        options={{ label: 'Invoice' }}
      />,
      <Resource
        name="temp_invoices"
        icon={TempInvoiceIcon}
        list={TempInvoicesList}
        options={{ label: 'Temp-Invoice' }}
      />,
      <Resource
        name="tickets"
        list={TicketList}
        edit={TicketEdit}
        icon={TicketIcon}
        options={{ label: 'Booking' }}
      />,
      <Resource
        name="routes"
        list={RoutesList}
        icon={RoutesIcon}
        create={RoutesCreate}
        edit={RoutesEdit}
        options={{ label: 'Routes' }}
      />,
      <Resource
        name="trips"
        list={TripsList}
        icon={TripsIcon}
        edit={TripsEdit}
        options={{ label: 'Trips' }}
      />,
      // Reviews
      <Resource
        name="reviews"
        list={ReviewList}
        edit={ReviewEdit}
        create={ReviewCreate}
        icon={ReviewIcon}
        options={{ label: 'Review' }}
      />,
      <Resource
        name="contents"
        list={ContentList}
        icon={ContentIcon}
        edit={ContentEdit}
        create={ContentCreate}
        options={{ label: 'Content' }}
      />,
      <Resource
        name="promocodes"
        list={PromocodeList}
        create={PromoCodeCreate}
        icon={PromoIcon}
        options={{ label: 'Promotion' }}
      />,
      // Organization
      <Resource
        name="companies"
        list={CompanyList}
        edit={CompanyEdit}
        create={CompanyCreate}
        icon={CompanyIcon}
        options={{ label: 'Company' }}
      />,
      <Resource
        name="admins"
        list={AdminList}
        edit={AdminEdit}
        create={AdminCreate}
        icon={AdminIcon}
        options={{ label: 'Admin' }}
      />,
      <Resource
        name="partners"
        list={PartnerList}
        edit={PartnerEdit}
        icon={PartnerIcon}
        options={{ label: 'Partners' }}
      />,
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        icon={UserIcon}
        options={{ label: 'User' }}
      />,
      // Vehicles and locations
      <Resource
        name="vehicles"
        list={VehicleList}
        edit={VehicleEdit}
        create={VehicleCreate}
        icon={VehIcon}
        options={{ label: 'Vehicles' }}
      />,
      <Resource
        name="locations"
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
        icon={LocationIcon}
        options={{ label: 'Locations' }}
      />,
      <Resource name="graph" icon={GraphIcom} list={Graph} />,

      <Resource
        name="seo/routes"
        list={SEORoutesList}
        edit={SEORoutesEdit}
        create={SEORoutesCreate}
        options={{ label: 'SEO Travel/from/to' }}
      />,
      <Resource
        name="seo/destinations"
        list={SEODestinationsList}
        edit={SEODestinationsEdit}
        create={SEODestinationsCreate}
        options={{ label: 'SEO Travel/destination' }}
      />,
      <Resource
        name="seo/countries"
        list={SEOCountriesList}
        edit={SEOCountriesEdit}
        create={SEOCountriesCreate}
        options={{ label: 'SEO Travel/countries' }}
      />,
      <Resource
        name="seo/country/vehicles"
        list={SEOCountryVehicleList}
        edit={SEOCountryVehicleEdit}
        create={SEOCountryVehicleCreate}
        options={{ label: 'SEO Travel/country/vehicles' }}
      />,

      /* Custom route path */
      <Resource name="trips/toggle" />,
      <Resource name="trips/status" />,
      <Resource name="routes/status" />,
      <Resource name="routes/filterStation" />,
      <Resource name="routes/fromDestination" />,
      <Resource name="routes/fromStation" />,
      <Resource name="routes/toDestination" />,
      <Resource name="routes/toStation" />
    ]
  } else if (permissions === 'partner') {
    return [
      // <Resource
      //   name="affiliate_dashboard"
      //   icon={AffiliateIcon}
      //   list={AffiliateDashboard}
      //   options={{ label: 'Affiliate Dashboard' }}
      // />,
      <Resource
        name="pending_order"
        icon={AffiliateIcon}
        list={BookingReport}
        options={{ label: 'Booking Report' }}
      />,
      <Resource
        name="withdraw"
        icon={AffiliateIcon}
        list={Withdraw}
        options={{ label: 'Withdraw' }}
      />,
      <Resource
        name="profile"
        icon={AffiliateIcon}
        list={Profile}
        options={{ label: 'Profile' }}
      />,
      <Resource
        name="tools"
        icon={AffiliateIcon}
        list={Tools}
        options={{ label: 'Tools' }}
      />,
      <Resource name="pending_order/filterStation" />
    ]
  } else {
    return [
      <Resource
        name="contents"
        list={ContentList}
        icon={ContentIcon}
        edit={ContentEdit}
        create={ContentCreate}
        options={{ label: 'Content' }}
      />,
      <Resource
        name="seo/routes"
        list={SEORoutesList}
        edit={SEORoutesEdit}
        create={SEORoutesCreate}
        options={{ label: 'SEO Travel/from/to' }}
      />,
      <Resource
        name="seo/destinations"
        list={SEODestinationsList}
        edit={SEODestinationsEdit}
        create={SEODestinationsCreate}
        options={{ label: 'SEO Travel/destination' }}
      />,
      <Resource
        name="seo/countries"
        list={SEOCountriesList}
        edit={SEOCountriesEdit}
        create={SEOCountriesCreate}
        options={{ label: 'SEO Travel/countries' }}
      />,
      <Resource
        name="seo/country/vehicles"
        list={SEOCountryVehicleList}
        edit={SEOCountryVehicleEdit}
        create={SEOCountryVehicleCreate}
        options={{ label: 'SEO Travel/country/vehicles' }}
      />
    ]
  }
}

const App = () => (
  <Admin
    theme={AdminTheme}
    dashboard={AffiliateDashboard}
    loginPage={LoginPagePartner}
    logoutButton={LogoutButton}
    authProvider={authProvider}
    dataProvider={dataProvider}
    locale="en"
  >
    {permissions => {
      return gateway(permissions)
    }}
  </Admin>
)

export default App
//
