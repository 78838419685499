import React from 'react'
import {
  email,
  minValue,
  minLength,
  maxLength,
  number,
  List,
  Filter,
  Datagrid,
  Edit,
  Create,
  TabbedForm,
  FormTab,
  TextField,
  DeleteButton,
  EditButton,
  ImageField,
  SelectInput,
  NumberInput,
  TextInput,
  ImageInput,
  BooleanInput,
  LongTextInput,
  AutocompleteInput,
  DisabledInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton
} from 'react-admin'
import Pagination from './custom/view/Pagination'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Group'
import { SortThaiBank } from '../Assets/src/Bank'

//
const CompanyLogoField = ({ record, logo_height }) => {
  const { image_logo } = record
  return (
    <img
      src={image_logo}
      alt=""
      style={{ width: `${logo_height || 96}px`, margin: '12px 0' }}
    />
  )
}

const BackgroundField = ({ record, width_size }) => {
  const { background_image } = record
  return (
    <img
      src={background_image}
      alt=""
      style={{ width: `${width_size || 50}%`, margin: '12px 0' }}
    />
  )
}

// Validation props
const required = (message = 'Required') => value =>
  value ? undefined : message
// const number = (message = 'Must be a number') => value =>
//   value && isNaN(Number(value)) ? message : undefined
//
const CompanyTitleField = ({ record }) => {
  const { op_name, company_type } = record
  return (
    <div style={{ lineHeight: '20px' }}>
      <span style={{ fontWeight: '500' }}>{op_name}</span> <br />
      <span style={{ fontWeight: '300' }}>{company_type}</span>
    </div>
  )
}
const ComponentLeadTimeField = ({ record }) => {
  const { reserved_hour } = record
  return <p>{reserved_hour !== null ? reserved_hour : '-'}</p>
}
const ComponentDuePaymentField = ({ record }) => {
  const { due_payment } = record
  return <p>{`${due_payment} THB`}</p>
}

const CompanyFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      source="status"
      defaultValue="active"
      choices={[
        { id: 'active', name: 'active' },
        { id: 'inactive', name: 'inactive' }
      ]}
    />
  </Filter>
)

const DescriptionPanel = ({ record }) => {
  const { book_bank, book_name, book_number } = record
  return (
    <div style={{ lineHeight: '20px', padding: '16px 36px' }}>
      <span>
        <span
          style={{ width: '100px', display: 'inline-block', fontWeight: '600' }}
        >
          Account Name
        </span>
        {book_name !== null ? book_name : '-'}
      </span>
      <br />
      <span>
        <span
          style={{ width: '100px', display: 'inline-block', fontWeight: '600' }}
        >
          Bank
        </span>
        {book_bank !== null ? book_bank : '-'}
      </span>
      <br />
      <span>
        <span
          style={{ width: '100px', display: 'inline-block', fontWeight: '600' }}
        >
          Book Number
        </span>
        {book_number !== null ? book_number : '-'}
      </span>
    </div>
  )
}

// Exporter
export const CompanyIcon = () => <DefaultIcon />
export const CompanyList = props => {
  return (
    <List
      {...props}
      filters={<CompanyFilter />}
      title="Show user lists"
      pagination={<Pagination />}
      perPage={30}
    >
      <Datagrid rowClick="edit" expand={<DescriptionPanel />}>
        <TextField source="id" />
        <CompanyLogoField label="Logo" />
        <CompanyTitleField label="Company Name" />
        <TextField label="Contact" source="contact_name" />
        <TextField label="Phone" source="main_phone_number" />
        <ComponentDuePaymentField label="Due payment" />
        <TextField label="Status" source="status" />
        <ComponentLeadTimeField label="Lead time" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

const CompanyToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/companies" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/companies" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const CompanyEdit = props => {
  return (
    <Edit title="Company Edit" {...props}>
      <TabbedForm toolbar={<CompanyToolbar />}>
        <FormTab label="Profile">
          <TextInput
            source="op_name"
            label="Company Name *(required)"
            validate={[required(), minLength(3), maxLength(100)]}
          />
          <TextInput
            source="official_name"
            label="Company Fullname"
            validate={[required(), minLength(3), maxLength(100)]}
          />
          <DisabledInput source="name" label="Company Seed Key *(required)" />
          {/* <TextInput
            source="name"
            label="Company Seed Key *(required)"
            validate={[required(), minLength(3), maxLength(100)]}
          /> */}
          <TextInput
            label="Contact Email"
            source="contact_email"
            validate={email()}
          />
          <TextInput
            label="Contact Telephone Number"
            source="main_phone_number"
          />
          <LongTextInput
            label="Address *(required)"
            source="address"
            validate={[required(), minLength(5), maxLength(999)]}
          />
          <TextInput
            label="Booking Email *(required)"
            source="main_email"
            validate={[required(), email()]}
          />
          <TextInput
            label="Support Email"
            source="support_email"
            validate={email()}
          />
          <TextInput
            source="support_phone_number"
            label="Support Phone Number "
          />
          <TextInput source="website" label="Website" />
          <SelectInput
            source="company_type"
            label="Select Company type *(required)"
            validate={required()}
            defaultValue="Company::Bus"
            choices={[
              { id: 'Company::Bus', name: 'Bus' },
              { id: 'Company::Ferry', name: 'Ferry' },
              { id: 'Company::Train', name: 'Train' },
              { id: 'Company::Airplain', name: 'Airplain' }
            ]}
          />
          <LongTextInput
            label="Company description (you can add many line)"
            source="description"
            validate={[minLength(5), maxLength(999)]}
          />
          <BooleanInput
            label="Booking Instant"
            source="instant_booking"
            defaultValue={true}
          />
          <ImageInput
            source="pictures"
            label="Update company logo"
            placeholder={
              <div>
                <p>
                  Drop Company profile image here <br /> *** Max size is 840KB
                  ***
                </p>
              </div>
            }
            accept="image/*"
            multiple={false}
            maxSize="840000"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <CompanyLogoField logo_height="320" label="Current company logo" />
          <ImageInput
            source="background_image_picture"
            label="Update company background"
            placeholder={
              <div>
                <p>
                  Drop Company background image here <br /> *** Max size is
                  960KB ***
                </p>
              </div>
            }
            accept="image/*"
            multiple={false}
            maxSize="960000"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <BackgroundField width_size="70" label="Company background image" />
        </FormTab>
        <FormTab label="Status">
          <SelectInput
            source="status"
            label="Active Listing"
            defaultValue="active"
            choices={[
              { id: 'active', name: 'Active' },
              { id: 'inactive', name: 'Inactive' }
            ]}
          />
          <NumberInput
            label="Default Lead Time"
            source="reserved_hour"
            validate={[number(), minValue(1)]}
          />
        </FormTab>
        <FormTab label="Bank Account">
          <TextInput
            source="book_name"
            label="Account Name"
            validate={[minLength(3), maxLength(100)]}
          />
          <AutocompleteInput
            source="book_bank"
            label="Bank"
            choices={SortThaiBank}
          />
          <TextInput
            source="book_number"
            label="Account Number "
            validate={[minLength(3), maxLength(100)]}
          />
        </FormTab>
        <FormTab label="Station">
          <h3>Station Edited</h3>
          <DisabledInput source="op_name" label="Company name" />
          <ArrayInput source="stations">
            <SimpleFormIterator>
              <ReferenceInput
                source="station"
                reference="locations"
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ type: 'Location::Terminal' }}
              >
                <AutocompleteInput optionText="location_name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Vehicle">
          <h3>Vehicle Edited</h3>
          <DisabledInput source="op_name" label="Company name" />
          <ArrayInput source="vehicles">
            <SimpleFormIterator>
              <ReferenceInput
                source="vehicle"
                reference="vehicles"
                perPage={100}
                sort={{ field: 'seed_key', order: 'ASC' }}
              >
                <AutocompleteInput optionText="desc" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const CompanyCreate = props => (
  <Create title="Company created" {...props}>
    <TabbedForm toolbar={<CompanyToolbar />}>
      <FormTab label="Profile">
        <TextInput
          source="op_name"
          label="Company Name *(required)"
          validate={[required(), minLength(3), maxLength(100)]}
        />
        <TextInput
          source="official_name"
          label="Company Fullname"
          validate={[required(), minLength(3), maxLength(100)]}
        />
        <TextInput
          label="Contact Email"
          source="contact_email"
          validate={email()}
        />
        <TextInput
          label="Contact Telephone Number"
          source="main_phone_number"
        />
        <LongTextInput
          label="Address *(required)"
          source="address"
          validate={[required(), minLength(5), maxLength(999)]}
        />
        <TextInput
          label="Booking Email *(required)"
          source="main_email"
          validate={[required(), email()]}
        />
        <TextInput
          label="Support Email"
          source="support_email"
          validate={email()}
        />
        <TextInput
          source="support_phone_number"
          label="Support Phone Number "
        />
        <TextInput source="website" label="Website" />
        <SelectInput
          source="company_type"
          label="Select Company type *(required)"
          validate={required()}
          defaultValue="Company::Bus"
          choices={[
            { id: 'Company::Bus', name: 'Bus' },
            { id: 'Company::Ferry', name: 'Ferry' },
            { id: 'Company::Train', name: 'Train' },
            { id: 'Company::Airplain', name: 'Airplain' }
          ]}
        />
        <BooleanInput
          label="Booking Instant"
          source="instant_booking"
          defaultValue={true}
        />
        {/*  */}
        <ImageInput
          source="pictures"
          label="Update company logo"
          placeholder={
            <div>
              <p>
                Drop Company profile image here <br /> *** Max size is 840KB ***
              </p>
            </div>
          }
          accept="image/*"
          multiple={false}
          maxSize="840000"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        {/*  */}
        {/* <ImageInput
          source="background_image_picture"
          label="Update company background"
          placeholder={
            <div>
              <p>
                Drop Company background image here <br /> *** Max size is 960KB
                ***
              </p>
            </div>
          }
          accept="image/*"
          multiple={false}
          maxSize="960000"
        >
          <ImageField source="src" title="title" />
        </ImageInput> */}
      </FormTab>
      <FormTab label="Status">
        <SelectInput
          source="status"
          label="Active Listing"
          defaultValue="active"
          choices={[
            { id: 'active', name: 'Active' },
            { id: 'inactive', name: 'Inactive' }
          ]}
        />
        <NumberInput
          label="Default Lead Time"
          source="reserved_hour"
          validate={[number(), minValue(1)]}
        />
      </FormTab>
      <FormTab label="Bank Account">
        <TextInput
          source="book_name"
          label="Account Name"
          validate={[minLength(3), maxLength(100)]}
        />
        <AutocompleteInput
          source="book_bank"
          label="Bank"
          choices={SortThaiBank}
        />
        <TextInput
          source="book_number"
          label="Account Number "
          validate={[minLength(3), maxLength(100)]}
        />
      </FormTab>
      <FormTab label="Station">
        <h3>Station Edited</h3>
        <DisabledInput source="op_name" label="Company name" />
        <ArrayInput source="stations">
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference="locations"
              perPage={100}
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ type: 'Location::Terminal' }}
            >
              <AutocompleteInput optionText="location_name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Vehicle">
        <h3>Vehicle Edited</h3>
        <DisabledInput source="op_name" label="Company name" />
        <ArrayInput source="vehicles">
          <SimpleFormIterator>
            <ReferenceInput
              source="id"
              reference="vehicles"
              perPage={100}
              sort={{ field: 'seed_key', order: 'ASC' }}
            >
              <AutocompleteInput optionText="desc" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
)
