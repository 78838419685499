import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
  AUTH_ERROR
} from 'react-admin'
import decodeJwt from 'jwt-decode'
//
export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { email, password } = params
    const request = new Request(
      `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/partner/login`,
      {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: new Headers({ 'Content-Type': 'application/json' })
      }
    )
    return fetch(request)
      .then(response => {
        const { status } = response
        if (status === 404) {
          throw new Error('Do not have Email in system.')
        }
        if (status === 403) {
          throw new Error('Email or Password invalid.')
        }
        if (status === 401) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(body => {
        localStorage.setItem('accessToken', body.accessToken)
        localStorage.setItem('role', decodeJwt(body.accessToken).user.role)
        localStorage.setItem('pid', decodeJwt(body.accessToken).user.pid)
      })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('role')
    return Promise.resolve()
  }
  if (type === AUTH_ERROR) {
    const { status } = params
    return status === 401 || status === 403
      ? Promise.reject()
      : Promise.resolve()
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('accessToken')
      ? Promise.resolve()
      : Promise.reject()
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role')
    return Promise.resolve(role)
  }
  return Promise.reject('Unknown method')
}
