import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  SelectInput,
  TextInput,
  Filter,
  Create,
  LongTextInput,
  NumberInput,
  FunctionField,
  TabbedForm,
  Toolbar,
  FormTab,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  BulkDeleteButton
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { DateKOHField, NationField } from './custom/view/CustomField'
import { withStyles } from '@material-ui/core/styles'
import { DatePickerRange } from './custom/view/DatePickerRange'
import * as moment from 'moment'
import {
  CustomerEditInput,
  EmailEditInput,
  PhoneEditInput,
  NationEditInput
} from './custom/view/CustomInput'
import Pagination from './custom/view/Pagination'
// table
import { InvoiceTable } from './custom/view/InvoiceTable'
import RefundButton from './custom/view/RefundButton'
import ExtraPayment from './custom/view/ExtraPaymentButton'
// PaymentTypeField
const PaymentTypeField = ({ record = {} }) => (
  <div style={{ lineHeight: '20px', margin: '12px 0' }}>
    {record.pay_id}
    {' | '}
    {record.pay_method} <br />
    <span style={{ fontWeight: '300' }}>{record.charge_id}</span>
  </div>
)

// ContractField
const ContractField = ({ record = {} }) => {
  if (record.ticketRefID[0] === undefined) {
    return <span>NOT MATCH!</span>
  } else {
    const { user_email, user_nation, user_telephone } = record.ticketRefID[0]
    return (
      <div style={{ lineHeight: '20px', margin: '12px 0' }}>
        {user_email}
        <NationField nationKey={user_nation} showNationText />
        <span style={{ fontWeight: '300' }}>{user_telephone}</span>
      </div>
    )
  }
}
ContractField.defaultProps = { label: 'Contracts' }

// NationField
const CustomerNationField = ({ record = {} }) => {
  const { user_nation } = record.ticketRefID[0]
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      <span style={{ marginBottom: '15pt', color: 'DarkGray' }}>Nation</span>{' '}
      <br />
      <NationField nationKey={user_nation} showNationText />
    </div>
  )
}
CustomerNationField.defaultProps = { label: 'Nation' }

// CustomerEmailField
const CustomerEmailField = ({ record = {} }) => {
  const { user_email } = record.ticketRefID[0]
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      <span href={record.user_email}>Email</span> <br />
      <span style={{ color: 'grey' }}>{user_email}</span>
    </div>
  )
}
CustomerEmailField.defaultProps = { label: 'Email' }

// BookingStatusField(new field)
const BookingStatusField = ({ record, classes }) => {
  const { ticketRefID: booking } = record
  return (
    <Fragment>
      <span>
        {booking.map(detail => {
          let styles = { color: 'Maroon' }
          switch (detail.status) {
            case 'confirm':
              styles.color = 'green'
              break
            case 'pending':
              styles.color = 'orange'
              break
            case 'refund_customer':
              styles.color = 'red'
              break
            case 'staging_refund':
              styles.color = 'purple'
              break
            default:
          }
          return (
            <tr>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '-1px'
                  }}
                >
                  <a style={styles} href={`#/tickets/${detail.id}`}>
                    {detail.ref_id}
                  </a>
                </div>
              </div>
            </tr>
          )
        })}
      </span>
    </Fragment>
  )
}
BookingStatusField.defaultProps = { label: 'Booking' }

// DiscountAndPartnerField
const DiscountAndPartnerField = ({ record = {} }) => {
  return (
    <div style={{ lineHeight: '20px', margin: '12px 0' }}>
      {record.agentGain} <br />
      <span style={{ fontWeight: '300' }}>{record.promo_code_store}</span>
    </div>
  )
}

// Edit page componant
// InvoiceIDField
const InvoiceIDField = ({ record = {} }) => {
  const { id } = record
  return (
    <div style={{ lineHeight: '30px', margin: '30px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>Invoice ID :</span>{' '}
      <span style={{ color: 'DarkGray' }}>
        {`INV${('000000' + id).slice(-6)}`}
      </span>
    </div>
  )
}
InvoiceIDField.defaultProps = { label: 'Invoice_ID' }

// BookingDateField
const BookingDateField = ({ record = {} }) => {
  const { created_at } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold' }}>{'Booking Date :'}</span>{' '}
      <span style={{ color: 'DarkGray' }}>
        {moment(created_at)
          .add(7, 'hours') //+7 hours for Thailand Local Time
          .format('D MMM YYYY')}
        {', '}
        {moment(created_at)
          .add(7, 'hours')
          .format('HH:mm')}
      </span>
    </div>
  )
}
BookingDateField.defaultProps = { label: 'Booking Date' }

// BookingdetailField(Edit page)
const BookingdetailField = ({ record, classes }) => {
  const { ticketRefID: booking } = record
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span
        style={{
          marginTop: '30px'
        }}
      >
        <Fragment>
          <span style={{ marginRight: '10pt', fontWeight: 'bold' }}>
            {'Booking: '}
          </span>
          {booking.map(detail => {
            let styles = {
              color: 'Maroon',
              marginTop: '-10px',
              fontWeight: 'bold'
            }
            switch (detail.status) {
              case 'confirm':
                styles.color = 'green'
                break
              case 'pending':
                styles.color = 'orange'
                break
              case 'refund_customer':
                styles.color = 'red'
                break
              case 'staging_refund':
                styles.color = 'purple'
                break
              default:
            }
            return (
              <td>
                <a style={styles} href={`#/tickets/${detail.id}`}>
                  {detail.ref_id}
                  {', '}
                </a>
              </td>
            )
          })}
        </Fragment>
      </span>
    </div>
  )
}
BookingdetailField.defaultProps = { label: 'Booking' }

// AmountPassangerField
const AmountPassangerField = ({ record = {} }) => {
  const { amount } = record.ticketRefID[0]
  return (
    <div style={{ lineHeight: '30px', margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', color: 'Black' }}>passenger:</span>{' '}
      <span style={{ color: 'DarkGray' }}>{`${amount} persons`}</span>
    </div>
  )
}
AmountPassangerField.defaultProps = { label: 'passenger' }

// InvoiceFilter
const InvoiceFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <DatePickerRange label="Booking Date Range" source="date_range" alwaysOn />
  </Filter>
)

// Styles
const scrollStyles = {
  table: {
    width: '1800px',
    overflowX: 'scroll'
  }
}

const BulkActionButtons = props => (
  <Fragment>{/* <BulkDeleteButton {...props} /> */}</Fragment>
)

// List
export const InvoiceList = withStyles(scrollStyles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<InvoiceFilter />}
    perPage={30}
    key={'id'}
    bulkActionButtons={<BulkActionButtons />}
    pagination={<Pagination />}
  >
    <Datagrid>
      <FunctionField
        label="Invoice ID"
        render={record => `INV${('000000' + record.id).slice(-6)}`}
        source="id"
      />
      <DateKOHField
        label="Booking Date"
        timeKey="created_at"
        showTime
        {...props}
      />
      <TextField label="Customer" source="name" />
      <ContractField />
      <BookingStatusField />
      <PaymentTypeField source="Payment" />
      <DiscountAndPartnerField label="Discount/Partner" />
      <TextField label="Paid" source="actual_price" />
      <TextField label="Cost" source="netPrice" />
      <TextField label="profit" source="profit" />
      <EditButton />
    </Datagrid>
  </List>
))

// Edit
const InvoiceToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="SAVE"
      redirect={(source, id, record) => {
        // console.log(source, id, record)
        return `/invoices/${id}`
      }}
    />
    <span style={{ margin: '0 6px' }} />
    <RefundButton refundTo="user_email" {...props} />
    {/* <span style={{ margin: '0 6px' }} />
    <ExtraPayment {...props} /> */}
    <span style={{ margin: '0 6px' }} />
    <a href="/#/invoices" style={{ textDecoration: 'none' }}>
      <Button variant="contained" size="large">
        {'BACK'}
      </Button>
    </a>
  </Toolbar>
)

export const InvoiceEdit = props => (
  <Edit title="Invoice Edit" {...props}>
    <TabbedForm toolbar={<InvoiceToolbar />}>
      <FormTab label="Detail">
        <InvoiceIDField source="id" label="Invoice ID" />
        <BookingDateField label="Booking Date" source="created_at" />
        <CustomerEditInput source="name" {...props} />
        <EmailEditInput source="ticketRefID[0].user_email" />
        <NationEditInput source="ticketRefID[0].user_nation" />
        <PhoneEditInput source="ticketRefID[0].user_telephone" />
        <BookingdetailField />
        <AmountPassangerField />
        <ArrayInput
          source="passengerRid"
          label="passenger name(old database)"
          style={{
            width: '470px',
            margin: '15px 0px 15px 36px',
            fontSize: 14
          }}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData, dispatch, getSource, ...rest }) => (
                <div>
                  <td>
                    <span style={{ marginLeft: '20pt' }}>
                      <SelectInput
                        label="Title"
                        source={getSource('title')}
                        choices={[
                          { id: 'mr', name: 'Mr.' },
                          { id: 'miss', name: 'Miss' },
                          { id: 'mrs', name: 'Mrs.' },
                          { id: 'ms', name: 'Ms.' }
                        ]}
                        style={{
                          minWidth: '50px',
                          marginRight: '10px',
                          height: '47.9px'
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    <span style={{ height: '20px' }}>
                      <TextInput
                        label="Firstname"
                        source={getSource('firstname')}
                        style={{ marginRight: '10px' }}
                      />
                    </span>
                  </td>
                  <td>
                    <span>
                      <TextInput
                        label="Lastname"
                        source={getSource('lastname')}
                      />
                    </span>
                  </td>
                </div>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="travellersRef"
          label="travellers name(new database)"
          style={{
            width: '470px',
            margin: '15px 0px 15px 36px',
            fontSize: 14
          }}
        >
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData, dispatch, getSource, ...rest }) => (
                <div>
                  <td>
                    <span style={{ marginLeft: '20pt' }}>
                      <SelectInput
                        label="Title"
                        source={getSource('travellers_title')}
                        choices={[
                          { id: 'mr', name: 'Mr.' },
                          { id: 'miss', name: 'Miss' },
                          { id: 'mrs', name: 'Mrs.' },
                          { id: 'ms', name: 'Ms.' }
                        ]}
                        style={{
                          minWidth: '50px',
                          marginRight: '10px',
                          height: '47.9px'
                        }}
                      />
                    </span>
                  </td>
                  <td>
                    <span style={{ height: '20px' }}>
                      <TextInput
                        label="Firstname"
                        source={getSource('travellers_firstname')}
                        style={{ marginRight: '10px' }}
                      />
                    </span>
                  </td>
                  <td>
                    <span>
                      <TextInput
                        label="Lastname"
                        source={getSource('travellers_lastname')}
                      />
                    </span>
                  </td>
                </div>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Activities">
        <InvoiceTable {...props} />
      </FormTab>
    </TabbedForm>
  </Edit>
)

// Create
export const InvoiceCreate = props => (
  <Create {...props} title={'name'} key={'id'}>
    <SimpleForm>
      <TextInput source="name" />
      <LongTextInput source="description" />
      <NumberInput source="actual_price" />
      <TextInput source="rid" />
      <TextInput source="pay_id" />
      <TextInput source="pay_method" />
      <NumberInput source="user_id" />
      <TextInput source="charge_id" />
      <NumberInput source="actual_gain" />
      <NumberInput source="transaction_fee" />
      <TextInput source="agent" />
    </SimpleForm>
  </Create>
)
