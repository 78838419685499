import React from 'react'
import {
  List,
  Edit,
  regex,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SimpleForm,
  TextInput,
  DisabledInput,
  SaveButton,
  Toolbar
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import DefaultIcon from '@material-ui/icons/Group'
import Button from '@material-ui/core/Button'
import Pagination from './custom/view/Pagination'

// Validation props
const validateURL = regex(
  /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
  'Must be a valid URL'
)

const Dashed = ({ width }) => (
  <div style={{ width: width, borderBottom: '1px solid #e6e6e6' }} />
)

const ContentToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/seo/routes" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/seo/routes" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

const ContentPanel = ({ id, record, resource }) => {
  return (
    <table style={{ padding: '16px 32px 8px' }}>
      <tr>
        <td
          style={{
            minWidth: '120px',
            borderBottom: '1px solid #cccccc',
            lineHeight: '32px'
          }}
        >
          page title
        </td>
        <td style={{ borderBottom: '1px solid #cccccc', lineHeight: '32px' }}>
          {record.page_title}
        </td>
      </tr>
      <tr>
        <td
          style={{
            minWidth: '120px',
            fontWeight: '600',
            borderBottom: '1px solid #cccccc',
            lineHeight: '32px'
          }}
        >
          meta description
        </td>
        <td
          style={{
            fontWeight: '600',
            borderBottom: '1px solid #cccccc',
            lineHeight: '32px'
          }}
        >
          {record.meta_desc}
        </td>
      </tr>
      <tr>
        <td style={{ minWidth: '120px' }}>content</td>
        <td>
          <span
            dangerouslySetInnerHTML={{ __html: record.content }}
            style={{ lineHeight: '18px' }}
          />
        </td>
      </tr>
    </table>
  )
}

export const SEORoutesCreate = props => {
  return (
    <Create title="SEO Route Create" {...props}>
      <SimpleForm toolbar={<ContentToolbar />}>
        <TextInput
          source="meta_url"
          label="URL ... /travel/${from_key}/${to_key} :"
          fullWidth
        />
        <TextInput source="page_title" label="Page title :" fullWidth />
        <TextInput source="meta_desc" label="Meta description :" fullWidth />
        <TextInput
          source="content_title"
          label="Content title (H1) :"
          fullWidth
        />
        <RichTextInput source="content" validation={{ required: false }} />
      </SimpleForm>
    </Create>
  )
}

export const SEORoutesEdit = props => {
  return (
    <Edit title="SEO Route Edit" {...props}>
      <SimpleForm toolbar={<ContentToolbar />}>
        <DisabledInput source="id" label="SEO Route ID" />
        <TextInput source="meta_url" label="URL :" fullWidth />
        <br />
        <br />
        <DisabledInput source="page_title" label="Page Title :" fullWidth />
        <TextInput
          source="new_page_title"
          label="Edit Page title :"
          fullWidth
        />
        <br />
        <br />
        <DisabledInput
          source="meta_desc"
          label="Meta description :"
          fullWidth
        />
        <TextInput
          source="new_meta_desc"
          label="Edit Meta description :"
          fullWidth
        />
        <br />
        <br />
        <DisabledInput
          source="content_title"
          label="Content title (H1) :"
          fullWidth
        />
        <TextInput
          source="new_content_title"
          label="Edit Content title :"
          fullWidth
        />
        <br />
        <br />
        <RichTextInput source="content" validation={{ required: false }} />
      </SimpleForm>
    </Edit>
  )
}

export const SEORoutesList = props => (
  <List
    {...props}
    title="Show SEO Routes lists"
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid rowClick="edit" expand={<ContentPanel />}>
      <TextField source="id" />
      <TextField source="meta_url" />
      <TextField source="page_title" label="Page Title" />
      <TextField source="content_title" label="Content Title" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)
