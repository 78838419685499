import React, { Fragment, Component } from 'react'
import * as moment from 'moment'
import lifecycle from 'react-pure-lifecycle'
import axios from 'axios'
import SpinnerView from './SpinnerView'

import {
  List,
  Filter,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  EmailField,
  DateField,
  TextInput,
  DateInput,
  SelectInput,
  BooleanInput,
  DatagridBody,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin'

import Pagination from './custom/view/Pagination'

import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import Space from './custom/view/AffiliateDashboard/Space'
import { DatePickerRange } from './custom/view/DatePickerRange'

const numberWithCommas = amount =>
  amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const sortData = location => {
  let loc = location.map(({ id, name, url_key }, index) => {
    return {
      ['id']: id,
      ['name']: name
    }
  })

  return loc
}

const filterRouteBlank = (option, routematch) => {
  let filter = []

  Object.keys(routematch).forEach(key => {
    // console.log('filterRouteBlank key >>> ', key)

    option.map(item => {
      if (item.id === Number(key)) {
        filter.push(item)
      }
    })
  })

  return filter
}

const styles = {
  prefix: {
    display: 'inline-block',
    width: '24px'
  },
  card: {
    textAlign: 'end',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    borderRadius: '10px',
    width: '27%',
    height: '110px',
    padding: '8px 15px',
    marginBottom: '33px'
  },
  span: {
    display: 'inline-block',
    width: '3%'
  },
  typeText: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    color: '#828282',
    fontWeight: 400,
    margin: 0
  },
  amountText: {
    fontSize: '30px',
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  headerTable: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4',
    fontWeight: 500,
    margin: '0 0 15px 0'
  }
}

const MyDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  basePath
}) => (
  <TableRow key={id}>
    {/* first column: selection checkbox */}
    <TableCell padding="none">
      {record.selectable && (
        <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
      )}
    </TableCell>
    {/* data columns based on children */}
    {React.Children.map(children, field => (
      <TableCell
        style={{ padding: '4px 56px 4px 14px' }}
        key={`${id}-${field.props.source}`}
      >
        {React.cloneElement(field, {
          record,
          basePath,
          resource
        })}
      </TableCell>
    ))}
  </TableRow>
)

const MyDatagridBody = props => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
)

const MyDatagrid = props => {
  const commision = localStorage.getItem('commision')
  const booking = localStorage.getItem('booking')
  // console.log('MyDatagrid Props >> ', props)
  return (
    <Fragment>
      <Space size="35" />
      <div style={{ display: 'flex', flexDirection: 'row', height: '110px' }}>
        <span style={styles.prefix} />
        <div style={styles.card}>
          <p style={styles.typeText}>Commision (THB)</p>
          <Space size="21" />
          {/* <p style={styles.amountText}>{numberWithCommas(commision)}</p> */}
          <p style={styles.amountText}>{commision}</p>
        </div>
        <span style={styles.span} />
        <div style={styles.card}>
          <p style={styles.typeText}>Booking</p>
          <Space size="21" />
          {/* <p style={styles.amountText}>{numberWithCommas(booking)}</p> */}
          <p style={styles.amountText}>{booking}</p>
        </div>
      </div>
      <Space size="29" />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={styles.prefix} />
        <p style={styles.headerTable}>Booking Breakdown</p>
      </div>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </Fragment>
  )
}

// export default withStyles(styles)(BookingReport)
const CommissionField = ({ source, record = {} }) => {
  return <span>{record[source].toFixed(2)}</span>
}

const CustomDateField = ({ source, record = {} }) => {
  const date = moment(record[source]).format('DD MMM YYYY')
  const styles = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#4a4a4a',
    fontWeight: 500
  }
  return <span style={styles}>{date}</span>
}

const PostFilter = props => {
  const { filterValues } = props
  const tools = [
    { id: 'deep_link', name: 'Deep Link' },
    { id: 'search_box', name: 'Search Box' },
    { id: 'promo_code', name: 'Promo Code' },
    { id: 'qr_code', name: 'QR Code' }
  ]

  return (
    <div>
      <Filter {...props}>
        {/* <DatePickerRange
          label="Date Range"
          source="date_range"
          width="300"
          alwaysOn
        /> */}

        <ReferenceInput
          label="From Destination"
          source="from_dest"
          reference="pending_order/filterStation"
          sort={{ field: 'location_name', order: 'ASC' }}
          filter={{ target: 'from_area_id' }}
          perPage={150}
          allowEmpty
          alwaysOn
        >
          <SelectInput optionText="location_name" />
          {/* <AutocompleteInput optionText="location_name" /> */}
        </ReferenceInput>

        <ReferenceInput
          label="To Destination"
          source="to_dest"
          reference="pending_order/filterStation"
          perPage={150}
          allowEmpty
          alwaysOn
          sort={{ field: 'location_name', order: 'ASC' }}
          filter={{
            // from_area_id: filterValues.from_dest,
            target: 'to_area_id'
          }}
          alwaysOn
        >
          <SelectInput optionText="location_name" />
          {/* <AutocompleteInput optionText="location_name" /> */}
        </ReferenceInput>

        <SelectInput source="tools" label="Tools" choices={tools} alwaysOn />
      </Filter>
    </div>
  )
}

class BookingReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onload: true
    }
  }
  componentDidMount() {
    const pid = localStorage.getItem('pid')

    axios
      .get(
        ` ${process.env.REACT_APP_URL_PARTNER}api/1.0.0/pending_order?_end=30&_order=DESC&_sort=id&_start=0`,
        { headers: { pid } }
      )
      .then(res => {
        const data = res.data.message
        const { commision, booking } = data
        // console.log('data >>> ', data)
        this.setState({ onload: false })
        if (data) {
          localStorage.setItem('commision', commision)
          localStorage.setItem('booking', booking)
        }
      })

    axios
      .get(
        `https://82ywqgk2ik.execute-api.ap-southeast-1.amazonaws.com/dev/locations/searchbox`
      )
      .then(res => {
        const data = res.data
        // console.log('locations data >>> ', data.message)

        if (data.status) {
          localStorage.setItem(
            'Booking From',
            JSON.stringify(sortData(data.message))
          )

          localStorage.setItem(
            'Booking To',
            JSON.stringify(sortData(data.message))
          )
        }
      })

    axios
      .get(
        `https://7eliseuvsc.execute-api.ap-southeast-1.amazonaws.com/dev/transportations/routesMatch`
      )
      .then(res => {
        const data = res.data

        if (data.status) {
          localStorage.setItem('RouteMatch', JSON.stringify(data.message))
        }
      })
  }
  render() {
    const { onload } = this.state
    return (
      <Fragment>
        <SpinnerView show={onload} />
        <List
          {...this.props}
          filters={<PostFilter {...this.props} />}
          pagination={<Pagination />}
          perPage={30}
          exporter={false}
        >
          <MyDatagrid>
            <TextField
              style={{
                color: '#3e4eb8',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 500
              }}
              source="customer"
              label="Customer"
            />
            <TextField source="route" label="Booked Route" />
            <TextField source="tools" label="Tools" />
            <CustomDateField source="bookingDate" label="Booking Date" />
            <CustomDateField source="travelDate" label="Travel Date" />
            <CommissionField source="commision" label="Commission (THB)" />
          </MyDatagrid>
        </List>
      </Fragment>
    )
  }
}

export default BookingReport //lifecycle(methods)(BookingReport)
