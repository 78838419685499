import React, { Fragment } from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  EmailField,
  TextInput,
  DateInput,
  SelectInput
} from 'react-admin'
import { DateKOHField, NationField } from './custom/view/CustomField'
import { DatePickerRange } from './custom/view/DatePickerRange'
import Pagination from './custom/view/Pagination'
const TempInvoicesFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="trip_detail" alwaysOn />
      <SelectInput
        source="status"
        label="Status"
        choices={[
          { id: 'paid', name: 'paid' },
          { id: 'invalid', name: 'invalid' }
        ]}
      />
      <DatePickerRange label="Date Range" source="date_range" />
      {/* <DateInput label="Start_Date" source="start_date" />
      <DateInput label="End_Date" source="end_date" /> */}
    </Filter>
  )
}

const CustomNationField = ({ record = {} }) => {
  const { nation } = record
  return <NationField nationKey={nation} showNationText />
}

const DescriptionPanel = ({ record }) => {
  const tripDetail = eval(record.trip_detail)
  return (
    <div>
      {tripDetail.map(
        ({
          date,
          route,
          quantity,
          from,
          to,
          from_time,
          from_terminal,
          to_terminal
        }) => (
          <div style={{ lineHeight: '20px', padding: '16px 36px' }}>
            <span style={{ width: '72px', display: 'inline-block' }}>
              Date:
            </span>{' '}
            {date} <br />
            <span style={{ width: '72px', display: 'inline-block' }}>
              Route:
            </span>{' '}
            {route} <br />
            <span style={{ width: '72px', display: 'inline-block' }}>
              Depart:
            </span>{' '}
            {from_time} <br />
            <span style={{ width: '72px', display: 'inline-block' }}>
              Quantity:
            </span>{' '}
            {quantity} <br />
            <span style={{ width: '72px', display: 'inline-block' }}>
              From:
            </span>{' '}
            {from} > {to} <br />
            <span style={{ width: '72px', display: 'inline-block' }}>
              Stations:
            </span>{' '}
            {from_terminal} - {to_terminal}
          </div>
        )
      )}
    </div>
  )
}

const BulkActionButtons = props => (
  <Fragment>{/* <BulkDeleteButton {...props} /> */}</Fragment>
)

export const TempInvoicesList = ({ ...props }) => (
  <List
    {...props}
    filters={<TempInvoicesFilter />}
    bulkActionButtons={<BulkActionButtons />}
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid expand={<DescriptionPanel />}>
      <TextField source="id" />
      <DateKOHField label="Created" timeKey="created_at" {...props} />
      <NumberField
        source="price"
        locales="en-EN"
        options={{ style: 'currency', currency: 'THB' }}
      />
      <TextField source="contact_name" />
      <EmailField source="email" />
      <CustomNationField label="Nation" />
      <TextField source="tel" />
      <BooleanField source="status" />
      {/* <RichTextField source="trip_detail" /> */}
      {/* <Record /> */}
    </Datagrid>
  </List>
)
