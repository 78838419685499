import { stringify } from 'query-string'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from 'react-admin'

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        // console.log(type)
        // console.log(resource)
        // console.log(params)
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`
        break
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          ...fetchUtils.flattenObject(params.filter),
          [params.target]: params.id,
          _sort: field,
          _order: order,
          _start: (page - 1) * perPage,
          _end: page * perPage
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = `${apiUrl}/${resource}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      case GET_MANY: {
        const query = {
          [`id_like`]: params.ids.join('|')
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response
    switch (type) {
      case GET_LIST:
        console.log('GET JSON >>>', json)

      case GET_MANY_REFERENCE:
        console.log(json.message.reportData)

        if (json.message.reportData) {
          return {
            data: json.message.reportData,
            total: json.totalCount
          }
        } else {
          return {
            data: json.message,
            total: json.totalCount
          }
        }
      case GET_MANY:
        // console.log(json)
        return { data: json.message }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      default:
        // console.log(json)
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */

  return (type, resource, params) => {
    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }))
    }
    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE'
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }))
    }
    const { url, options } = convertDataRequestToHTTP(type, resource, params)
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    )
  }
}
