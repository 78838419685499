export const theme = [
  {
    value: 'Theme',
    label: 'Theme'
  },
  {
    value: 'Theme02',
    label: 'Theme02'
  },
  {
    value: 'Theme03',
    label: 'Theme03'
  }
]

export const layout = [
  {
    value: 'Layout',
    label: 'Layout'
  },
  {
    value: 'Layout02',
    label: 'Layout02'
  },
  {
    value: 'Layout03',
    label: 'Layout03'
  }
]

export const departure = [
  {
    value: 'Bangkok',
    label: 'Bangkok'
  },
  {
    value: 'Nonthaburi',
    label: 'Nonthaburi'
  },
  {
    value: 'Rayong',
    label: 'Rayong'
  }
]

export const arrival = [
  {
    value: 'Bangkok',
    label: 'Bangkok'
  },
  {
    value: 'Nonthaburi',
    label: 'Nonthaburi'
  },
  {
    value: 'Rayong',
    label: 'Rayong'
  }
]
