import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'

class ConfirmPromo extends React.Component {
  doCreate = value => {
    const pid = localStorage.getItem('pid')

    axios
      .post(
        ` ${process.env.REACT_APP_URL_PARTNER}api/1.0.0/createPromocode/${pid}`,
        {
          code: value
        }
      )
      .then(res => {
        const data = res.data.message

        console.log('createPromocode >>> ', data)
        if (data) {
          console.log('after post data >>> ', value)
          this.props.update(value)
        }
      })
  }

  render() {
    const { open, handleCloseConfirm, code } = this.props

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Confirm your Promo Code ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {code}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.doCreate(code)} color="primary">
              Confirm
            </Button>
            <Button onClick={handleCloseConfirm} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default ConfirmPromo
