import React from 'react'
import { MarkSeries } from 'react-vis'

import { generateScatterplotData, intRandom, random } from './Data'
import { chooseColorScale, SimpleChartWrapper, jsxOptions } from './Utils'

const MarkSerie = () => {
  return (
    <SimpleChartWrapper>
      <MarkSeries data={generateScatterplotData({ key: 'scatter1' })} />
    </SimpleChartWrapper>
  )
}

export default MarkSerie
