import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Space from './custom/view/AffiliateDashboard/Space'
import ConfirmPromo from './ConfirmPromo'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const SignupSchema = Yup.object().shape({
  code: Yup.string()
    .max(10, 'Maximum 10 Characters!')
    .required('Required.')
    .matches(/^[0-9a-zA-Z]+$/, 'Letters and Numbers Only!')
})

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    height: '251px',
    width: '352px',
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    padding: '40px 39px 0'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    width: 244
  },
  button: {
    width: '261px',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    paddingBottom: '10px',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  inputFeild: {
    width: '261px',
    height: '50px',
    borderRadius: '2px',
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
    padding: '5px'
  }
}

class PromoCoadeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirm: false,
      promomCode: '',
      confirm: false
    }
  }

  handleOpenConfirm = () => {
    this.setState({ openConfirm: true })
  }

  handleCloseConfirm = () => {
    this.setState({ openConfirm: false })
  }

  doConfirm = value => {
    // const { promomCode } = this.state
    this.props.update(value)
    this.setState({ openConfirm: false })
  }

  render() {
    const { open, handleClose, classes } = this.props
    const { openConfirm, promomCode, confirm } = this.state

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.title}>Create your Promo Code</div>
              <div className={classes.desc}>Please create Promo Code</div>
              <Space size="15" />

              <Formik
                initialValues={{
                  code: ''
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  // same shape as initial values
                  this.setState({ promomCode: values.code.toUpperCase() })
                  this.handleOpenConfirm()
                  handleClose()
                }}
              >
                {({ errors, touched, values, handleSubmit }) => {
                  return (
                    <Form>
                      <Field
                        name="code"
                        value={values.code.toUpperCase()}
                        className={classes.inputFeild}
                        placeholder="(maximum 10 letters or numbers)"
                      />
                      {errors.code && touched.code ? (
                        <div
                          style={{
                            fontSize: '13px',
                            color: '#d0021b',
                            fontWeight: 300,
                            fontFamily: 'Roboto'
                          }}
                        >
                          {errors.code}
                        </div>
                      ) : null}
                      <Space size="15" />

                      <Button type="submit" className={classes.button}>
                        <p className={classes.buttonText}>Create Promo Code</p>
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Fade>
        </Modal>
        <ConfirmPromo
          open={openConfirm}
          handleCloseConfirm={this.handleCloseConfirm}
          code={promomCode}
          update={this.doConfirm}
        />
      </>
    )
  }
}

export default withStyles(styles)(PromoCoadeModal)
