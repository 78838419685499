import React, { Fragment } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  // TextInput,
  TabbedForm,
  FormTab,
  SelectInput,
  ReferenceField,
  ReferenceInput,
  BulkDeleteButton,
  NumberInput,
  FormDataConsumer,
  DisabledInput,
  // LongTextInput,
  Create,
  Edit,
  BooleanInput,
  Toolbar,
  SaveButton,
  // DeleteButton,
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator
} from 'react-admin'
import Filter from './custom/view/Filter/Filter'
import { TimeInput } from 'react-admin-date-inputs'
import DefaultIcon from '@material-ui/icons/EventNote'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import StatusButton from './custom/view/StatusButton'
import BulkStatusButton from './custom/view/BulkStatusButton'
import Button from '@material-ui/core/Button'
import { DateKOHField } from './custom/view/CustomField'
import { DateTimeInput, DateInput } from 'react-admin-date-inputs'
import { DatePickerRange } from './custom/view/DatePickerRange'
import Pagination from './custom/view/Pagination'

//// custome field

const required = (message = 'Required') => value =>
  value ? undefined : message

const BulkActionButtons = props => (
  <Fragment>
    <BulkStatusButton
      text="Deactive All"
      direct="Trips"
      action="cancelled"
      {...props}
    />
    <BulkStatusButton
      text="Active All"
      direct="Trips"
      action="scheduled"
      {...props}
    />
    <BulkDeleteButton quote="route" {...props} />
  </Fragment>
)

const Seat = ({ record }) => {
  let seatNum = record.seatNum
  let availability = record.availability
  let color
  if (seatNum == null) seatNum = 0
  if (seatNum < Math.ceil(availability) / 2) {
    color = 'green'
  } else if (seatNum < availability) {
    color = '#ceb029'
  } else if (seatNum == availability) color = 'red'

  return (
    <Fragment>
      <p style={{ color: color }}>
        {seatNum}/{availability}
      </p>
    </Fragment>
  )
}

const TripsToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/Trips" />
    <span style={{ margin: '0 6px' }} />
    <a href="/#/Trips" style={{ textDecoration: 'none' }}>
      <Button variant="contained" size="large">
        BACK
      </Button>
    </a>
  </Toolbar>
)

const TripsFilter = props => {
  const { filterValues } = props
  return (
    <Filter {...props}>
      {/* <TextInput label="Search" source="q" alwaysOn /> */}
      <DatePickerRange label="Trip Date Ranges" source="date_range" alwaysOn />
      <ReferenceInput
        label="Company"
        source="company_id"
        reference="companies"
        sort={{ field: 'op_name', order: 'ASC' }}
        perPage={150}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="op_name" />
      </ReferenceInput>

      {/* {filterValues.hasOwnProperty('company_id') && ( */}
      <ReferenceInput
        label="From Destination"
        source="from_area_id"
        reference="routes/filterStation"
        sort={{ field: 'location_name', order: 'ASC' }}
        perPage={150}
        filter={{
          company_id: filterValues.company_id,
          target: 'from_area_id'
        }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="location_name" />
      </ReferenceInput>
      {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') && (
          <ReferenceInput
            label="From Station"
            source="from_location_id"
            reference="routes/filterStation"
            sort={{ field: 'location_name', order: 'ASC' }}
            perPage={150}
            filter={{
              company_id: filterValues.company_id,
              from_area_id: filterValues.from_area_id,
              target: 'from_location_id'
            }}
            allowEmpty
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        )}
      {/* {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') && ( */}
      <ReferenceInput
        label="To Destination"
        source="to_area_id"
        reference="routes/filterStation"
        sort={{ field: 'location_name', order: 'ASC' }}
        perPage={150}
        filter={{
          company_id: filterValues.company_id,
          from_area_id: filterValues.from_area_id,
          target: 'to_area_id'
        }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="location_name" />
      </ReferenceInput>
      {/* )} */}
      {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') &&
        filterValues.hasOwnProperty('from_location_id') &&
        filterValues.hasOwnProperty('to_area_id') && (
          <ReferenceInput
            label="To Station"
            source="to_location_id"
            reference="routes/filterStation"
            sort={{ field: 'location_name', order: 'ASC' }}
            perPage={150}
            filter={{
              company_id: filterValues.company_id,
              from_area_id: filterValues.from_area_id,
              from_location_id: filterValues.from_location_id,
              to_area_id: filterValues.to_area_id,
              target: 'to_location_id'
            }}
            allowEmpty
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        )}
      <SelectInput
        source="status"
        choices={[
          { id: 'scheduled', name: 'Available' },
          { id: 'canceled', name: 'Close' }
        ]}
      />
    </Filter>
  )
}

export const TripsIcon = () => <DefaultIcon />
export const TripsList = props => (
  <List
    {...props}
    title="Trips"
    filters={<TripsFilter {...props} />}
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid>
      <TextField label="ID" source="id" />
      <TextField label="Route_ID" source="route" />
      <ReferenceField
        label="Company"
        source="company"
        reference="companies"
        sortable={false}
        allowEmpty
      >
        <TextField source="op_name" />
      </ReferenceField>
      <ReferenceField
        label="From"
        source="from"
        style={{ width: '100px' }}
        reference="locations"
        sortable={false}
      >
        <TextField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="To"
        source="to"
        style={{ width: '100px' }}
        reference="locations"
        sortable={false}
      >
        <TextField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="From Stations"
        source="fromStation"
        reference="locations"
        style={{ width: '100px' }}
        sortable={false}
      >
        <TextField source="location_name" />
      </ReferenceField>
      <ReferenceField
        label="To Station"
        source="toStation"
        style={{ width: '100px' }}
        reference="locations"
        sortable={false}
      >
        <TextField source="location_name" />
      </ReferenceField>
      <DateKOHField label="Dep." timeKey="departure" {...props} showTime />
      <DateKOHField label="Arr." timeKey="arrival" {...props} showTime />
      <Seat label="Seat" source="availability" {...props} />
      <TextField label="Contract ($)" source="contract" />
      <TextField label="Selling ($)" source="sales" />
      <StatusButton label="Status" resourceType="trip" />
      <EditButton />
    </Datagrid>
  </List>
)

export const TripsCreate = props => (
  <Create title="Trip created" {...props}>
    <TabbedForm toolbar={<TripsToolbar />}>
      <FormTab label="Trip">
        <NumberInput
          label="Transport Route ID"
          validate={[required()]}
          source="transport_route_id"
        />
        <tr>
          <td>
            <DateTimeInput
              source="departure_datetime"
              label="Departure"
              validate={[required()]}
              options={{ format: 'dd/MMM/YYYY, HH:mm:ss', ampm: false }}
            />
          </td>
          <td style={{ paddingLeft: 20, paddingRight: 20 }}>
            <TrendingFlatIcon />
          </td>
          <td>
            <DateTimeInput
              source="arrival_datetime"
              label="Arrival"
              validate={[required()]}
              options={{ format: 'dd/MMM/YYYY, HH:mm:ss', ampm: false }}
            />
          </td>
        </tr>
        <NumberInput
          label="Max Capacity"
          validate={[required()]}
          source="max_capacity"
          defaultValue={999}
        />
      </FormTab>
      <FormTab label="Price">
        <NumberInput
          label="Contract Rate (THB)"
          validate={[required()]}
          source="cost.netPrice"
        />
        <NumberInput
          label="Selling Price (THB)"
          validate={[required()]}
          source="cost.defaultPrice"
        />
        <NumberInput label="Suggested Price (THB)" source="cost.suggestPrice" />
      </FormTab>
    </TabbedForm>
  </Create>
)

export const TripsEdit = props => {
  return (
    <Edit title="Trip Edited" {...props}>
      <TabbedForm toolbar={<TripsToolbar />}>
        <FormTab label="Trip">
          <TextField
            label="Transport Route ID"
            reference="transport_route_id"
            source="route"
          />
          <DisabledInput label="Name" reference="name" source="name" />
          <DisabledInput source="company" optionText="op_name" />

          <tr>
            <td>
              <DateTimeInput
                reference="departure_datetime"
                label="Departure"
                source="departure"
                options={{ format: 'dd/MMM/YYYY, HH:mm:ss', ampm: false }}
              />
            </td>
            <td style={{ paddingLeft: 20, paddingRight: 20 }}>
              <TrendingFlatIcon />
            </td>
            <td>
              <DateTimeInput
                reference="arrival_datetime"
                label="Arrival"
                source="arrival"
                options={{ format: 'dd/MMM/YYYY, HH:mm:ss', ampm: false }}
              />
            </td>
          </tr>
          <NumberInput
            label="Max capacity (Seat)"
            reference="max_capacity"
            source="availability"
          />
          <TextField label="Occupied seats" source="seatNum" />
          <TextField label="Lead Time (Hours)" source="leadTime" />
          {/* <ArrayInput label="Start Station" source="itinerary">
            <SimpleFormIterator disableRemove disableAdd>
              <ReferenceInput
                source="terminal"
                label="Station"
                reference="locations"
                perPage={100}
                sort={{ field: 'name', order: 'ASC' }}
                filter={{ type: 'Location::Terminal' }}
              >
                <AutocompleteInput optionText="location_name" />
              </ReferenceInput>
              <ReferenceInput
                source="vehicle"
                label="Vehicle"
                reference="vehicles"
                perPage={100}
                validate={[required()]}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <TimeInput
                source="format_travel_time"
                label="Start time"
                validate={[required()]}
                options={{ format: 'HH:mm', ampm: false }}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ReferenceInput
            label="End station"
            source="toStation"
            reference="locations"
            validate={[required()]}
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ type: 'Location::Terminal' }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput> */}
        </FormTab>
        <FormTab label="Price">
          <NumberInput
            label="Contract Rate (THB)"
            source="contract"
            reference="cost.netPrice"
          />
          <NumberInput
            label="Selling Price (THB)"
            source="sales"
            reference="cost.defaultPrice"
          />
          <NumberInput
            label="Suggested Price (THB)"
            source="suggest"
            reference="cost.suggestPrice"
          />
        </FormTab>
        <FormTab label="Pick-up/Drop-off">
          <BooleanInput label="Hotel Pick-up" source="pickup.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.pickup) {
                return (
                  formData.pickup.status && (
                    <Fragment>
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="pickup.desc.booking"
                        label="Pick-up Description (Booking Site)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="pickup.desc.ticket"
                        label="Pick-up Description (Ticket)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="pickup.leadTime"
                        label="Pick-up Lead Time (Hours)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="pickup.contract"
                        label="Pick-up Contract Rate (THB)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        label="Pick-up Selling Price (THB)"
                        source="pickup.price"
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>

          <BooleanInput label="Hotel Drop-off" source="dropoff.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.dropoff) {
                return (
                  formData.dropoff.status && (
                    <Fragment>
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="dropoff.desc.booking"
                        label="Drop-off Description (Booking Site)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="dropoff.contract"
                        label="Drop-off Contract Rate (THB)"
                      />
                      <DisabledInput
                        style={{ width: '100%' }}
                        source="dropoff.price"
                        label="Drop-off Selling Price (THB)"
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
