import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Space from './custom/view/AffiliateDashboard/Space'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    height: '330px',
    width: '352px',
    backgroundColor: '#ffffff',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    padding: '40px 39px 0',
    position: 'relative'
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  input: {
    width: 244
  },
  button: {
    width: '261px',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  cross: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  }
}

class NewPWModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fields: {},
      error: {}
    }
  }
  handleChange = event => {
    let fields = this.state.fields

    fields[event.target.name] = event.target.value

    this.setState({ fields })
  }

  doChangPW = () => {
    const pid = localStorage.getItem('pid')
    const { oldPW } = this.props
    const { fields } = this.state

    axios
      .post(
        `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/changepassword/${pid}`,
        {
          oldPassword: oldPW,
          newPassword: fields.newpassword,
          confirmPassword: fields.confirmpassword
        }
      )
      .then(res => {
        const data = res.data.message
        const { status } = data
        if (status === 'succeeded') {
          this.props.update('succeeded')
        }

        console.log('after changePW', data)
      })
      .catch(error => {
        console.log('changePW error =>', error)

        this.props.update('failed')
      })
  }

  handleSubmit = event => {
    const { handleClose } = this.props
    event.preventDefault()

    if (this.validationForm()) {
      let fields = {}
      fields['newpassword'] = ''
      fields['confirmpassword'] = ''

      this.doChangPW()

      handleClose()
      //   this.setState({ fields })
    }
  }

  validationForm = () => {
    let fields = this.state.fields
    let error = {}
    let formIsValid = true

    if (!fields['newpassword']) {
      formIsValid = false
      error['newpassword'] = '*Please enter new password'
    }

    if (!fields['confirmpassword']) {
      formIsValid = false
      error['confirmpassword'] = '*Please enter confirm password'
    } else if (fields['confirmpassword'] !== fields['newpassword']) {
      formIsValid = false
      error['confirmpassword'] = '*New Password not Match'
    }

    this.setState({ error })

    return formIsValid
  }

  render() {
    // const classes = useStyles()
    const { open, handleClose, classes, oldPW } = this.props
    const { fields, error } = this.state

    // console.log('oldPW in NewPW >>> ', oldPW)
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div className={classes.title}>
                Please enter your new password.
              </div>
              <Icon className={classes.cross} onClick={handleClose}>
                close
              </Icon>
              <Space size="15" />
              <div className={classes.container}>
                <form onSubmit={this.handleSubmit}>
                  <Input
                    name="newpassword"
                    type="password"
                    className={classes.input}
                    inputProps={{
                      'aria-label': 'description'
                    }}
                    onChange={this.handleChange}
                  />
                  {error.newpassword && (
                    <div style={{ color: '#cc0000', marginBottom: '12px' }}>
                      {error.newpassword}
                    </div>
                  )}
                  <Space size="25" />
                  <div className={classes.title}>Confirm new password.</div>
                  <Input
                    name="confirmpassword"
                    type="password"
                    className={classes.input}
                    inputProps={{
                      'aria-label': 'description'
                    }}
                    onChange={this.handleChange}
                  />
                  {error.confirmpassword && (
                    <div style={{ color: '#cc0000', marginBottom: '12px' }}>
                      {error.confirmpassword}
                    </div>
                  )}
                  <Space size="25" />
                  <Button type="submit" className={classes.button}>
                    <p className={classes.buttonText}>Reset Password</p>
                  </Button>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    )
  }
}

export default withStyles(styles)(NewPWModal)
