import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

import AllTools from './AllTools'
import PromoCode from './PromoCode'
import DeepLink from './DeepLink'
import SearchBox from './SearchBox'
import QRcode from './QRcode'

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: '0 54px' }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  label: {
    color: '#4a4a4a',
    fontSize: 20,
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    textTransform: 'none'
  }
})

class Tools extends React.Component {
  state = {
    value: 0,
    width: 1200
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth })

    console.log(window.innerWidth)
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  render() {
    const { classes, theme } = this.props
    const { width } = this.state

    let long = width * 0.64

    return (
      <div className={classes.root} style={{ width: '64%' }}>
        <AppBar position="static" color="#ffffff">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="#4a4a4a"
            fullWidth
          >
            <Tab
              classes={{
                label: classes.label
              }}
              label="All Tools"
            />
            <Tab
              classes={{
                label: classes.label
              }}
              label="Deeplink"
            />
            <Tab
              classes={{
                label: classes.label
              }}
              label="Search Box"
            />
            <Tab
              classes={{
                label: classes.label
              }}
              label="Promo-code Generator"
            />
            <Tab
              classes={{
                label: classes.label
              }}
              label="QR Code Generator"
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <AllTools swipe={this.handleChangeIndex} />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <DeepLink />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <SearchBox />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <PromoCode />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <QRcode />
          </TabContainer>
        </SwipeableViews>
      </div>
    )
  }
}

Tools.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(Tools)
