import React from 'react'
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  ArrayField,
  ImageField,
  ReferenceField,
  Toolbar,
  EditButton,
  DeleteButton,
  SaveButton,
  SimpleForm,
  ArrayInput,
  TextInput,
  ImageInput,
  SelectInput,
  LongTextInput,
  ReferenceInput,
  CheckboxGroupInput,
  Filter,
  AutocompleteInput,
  SimpleFormIterator
} from 'react-admin'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Group'
import facil_ac from '../Assets/image/icr_facility_ac.png'
import facil_food from '../Assets/image/icr_facility_food.png'
import facil_toilet from '../Assets/image/icr_facility_toilet.png'
import facil_wifi from '../Assets/image/icr_facility_wifi.png'
import facil_steward from '../Assets/image/icr_facility_steward.png'
import facil_dining_room from '../Assets/image/icr_facility_dining_room.png'
import facil_sleep from '../Assets/image/icr_facility_sleep.png'
import Pagination from './custom/view/Pagination'

// Exporter
// const splitCapJoin = text => {
//   return text
//     .split('_')
//     .map(t => t[0].toUpperCase() + t.slice(1))
//     .join(' ')
// }
const renderFaclity = key => {
  let image
  switch (key) {
    case 'air':
      image = facil_ac
      break
    case 'food':
      image = facil_food
      break
    case 'toilet':
      image = facil_toilet
      break
    case 'wifi':
      image = facil_wifi
      break
    case 'sleep':
      image = facil_sleep
      break
    case 'steward':
      image = facil_steward
      break
    case 'dining_room':
      image = facil_dining_room
      break
    default:
  }
  return (
    <img src={image} alt="" style={{ height: '14px', marginRight: '8px' }} />
  )
}
const VehicleFacilityField = ({ record }) => {
  const { facilities } = record
  return <span>{facilities.map(item => renderFaclity(item))}</span>
}
const VehicleNameField = ({ record }) => {
  const { name } = record
  return <p>{name}</p>
}

const VehicleFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Company"
      source="company"
      reference="companies"
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput source="op_name" />
    </ReferenceInput>

    {/* <TextField source="type" label="Type" /> */}
    <SelectInput
      label="Vehicle Type "
      source="type"
      choices={[
        { id: 'Vehicle::Bus', name: 'Bus' },
        { id: 'Vehicle::Train', name: 'Train' },
        { id: 'Vehicle::Ferry', name: 'Ferry' },
        { id: 'Vehicle::Airplane', name: 'Airplane' }
      ]}
      allowEmpty
      alwaysOn
    />
  </Filter>
)

export const VehicleIcon = () => <DefaultIcon />
export const VehicleList = props => (
  <List
    {...props}
    title="Show Vehicle lists"
    filters={<VehicleFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    perPage={30}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <VehicleNameField label="Vehicle Name" />
      <ReferenceField
        label="Operator"
        source="company_id"
        reference="companies"
        linkType={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" label="Type" />
      <TextField source="class_name" label="Class" />
      <VehicleFacilityField label="Amanity" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const VehicleToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/vehicles" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/vehicles" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const VehicleEdit = props => {
  return (
    <Edit title="Vehicle Edit" {...props}>
      <SimpleForm toolbar={<VehicleToolbar />}>
        <TextInput source="name" label="Vehicle Name" />
        {/* <DisabledInput source="seed_key" label="Vehicle Seed key" /> */}
        <TextInput source="class_name" label="Class" />
        <SelectInput
          label="Type"
          source="type"
          choices={[
            { id: 'Vehicle::Bus', name: 'Bus' },
            { id: 'Vehicle::Minibus', name: 'Minibus' },
            { id: 'Vehicle::Train', name: 'Train' },
            { id: 'Vehicle::Ferry', name: 'Ferry' },
            { id: 'Vehicle::Airplane', name: 'Airplane' }
          ]}
        />
        <ReferenceInput
          source="company_id"
          reference="companies"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={200}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <LongTextInput label="Description (Optional)" source="description" />
        <CheckboxGroupInput
          source="facilities"
          label="Amanity"
          choices={[
            { id: 'air', name: 'Air Conditioner' },
            { id: 'food', name: 'Meal' },
            { id: 'toilet', name: 'Toilet' },
            { id: 'wifi', name: 'Wifi' },
            { id: 'dining_room', name: 'Dining Room' },
            { id: 'sleep', name: 'Sleeping Facility' },
            { id: 'steward', name: 'Steward' }
          ]}
        />
        <ImageInput
          source="pictures"
          label="Related pictures"
          placeholder={
            <p>Drop your file here *** Max size is 840KB ** Max 5 Images</p>
          }
          accept="image/*"
          multiple="true"
          maxSize="840000"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="images" label="Edit Image">
          <SimpleFormIterator>
            <LongTextInput source="title" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayField source="images" label="Current Image">
          <Datagrid>
            <ImageField source="url" label="Image" />
            <TextField source="title" />
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  )
}

export const VehicleCreate = props => (
  <Create title="Vehicle Create" {...props}>
    <SimpleForm toolbar={<VehicleToolbar />}>
      <TextInput source="name" label="Vehicle Name" />
      <TextInput source="class_name" label="Class" />
      <SelectInput
        label="Type"
        source="type"
        choices={[
          { id: 'Vehicle::Bus', name: 'Bus' },
          { id: 'Vehicle::Minibus', name: 'Minibus' },
          { id: 'Vehicle::Train', name: 'Train' },
          { id: 'Vehicle::Ferry', name: 'Ferry' },
          { id: 'Vehicle::Airplane', name: 'Airplane' }
        ]}
      />
      <ReferenceInput
        source="company_id"
        reference="companies"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={200}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <LongTextInput label="Description (Optional)" source="description" />
      <CheckboxGroupInput
        source="facilities"
        label="Amanity"
        choices={[
          { id: 'air', name: 'Air Conditioner' },
          { id: 'food', name: 'Meal' },
          { id: 'toilet', name: 'Toilet' },
          { id: 'wifi', name: 'Wifi' },
          { id: 'dining_room', name: 'Dining Room' },
          { id: 'sleep', name: 'Sleeping Facility' },
          { id: 'steward', name: 'Steward' }
        ]}
      />
      <ImageInput
        source="pictures"
        label="Related pictures"
        placeholder={
          <p>Drop your file here *** Max size is 840KB ** Max 5 Images</p>
        }
        accept="image/*"
        multiple="true"
        maxSize="840000"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
)
