import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Switch from '@material-ui/core/Switch'
import { promocodeActive, promocodeDeActive } from '../action/PromocodeAction'
import { startUndoable as startUndoableAction } from 'ra-core'

class ApproveButton extends Component {
  handleClick = actions => {
    const { startUndoable, record } = this.props
    if (actions === 'active') {
      startUndoable(promocodeActive(record.id, record))
    } else if (actions === 'deactive') {
      startUndoable(promocodeDeActive(record.id, record))
    }
  }

  render() {
    const { record } = this.props
    const checkActive = record.status === 'active'
    const handleStatus = checkActive ? 'deactive' : 'active'
    return (
      <Switch
        checked={checkActive}
        onClick={() => this.handleClick(handleStatus)}
      />
    )
  }
}

ApproveButton.propTypes = {
  record: PropTypes.object,
  startUndoable: PropTypes.func
}

export default connect(
  null,
  {
    startUndoable: startUndoableAction
  }
)(ApproveButton)
