import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Button, Confirm, crudUpdateMany } from 'react-admin'

class BulkStatusButton extends Component {
  state = {
    isOpen: false
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false })
  }

  handleConfirm = () => {
    const { basePath, crudUpdateMany, resource, selectedIds } = this.props
    crudUpdateMany(`${resource}/promoteAdmin`, selectedIds, {}, basePath)
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <Fragment>
        <Button
          label="PROMOTE TO ADMIN"
          onClick={() => this.setState({ isOpen: true })}
        />
        <Confirm
          title={`Promote to admin`}
          content="Are you sure you want to promote this user to be admin?"
          isOpen={this.state.isOpen}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    )
  }
}

export default connect(
  undefined,
  { crudUpdateMany }
)(BulkStatusButton)
