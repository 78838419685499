import React, { Fragment, Component } from 'react'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import * as moment from 'moment'
import Space from './custom/view/AffiliateDashboard/Space'
import { graphData } from './custom/view/AffiliateDashboard/graphData'
import SpinnerView from './SpinnerView'

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  ChartLabel
} from 'react-vis'

const numberWithCommas = amount =>
  amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const sortGraphData = data => {
  let info = []

  Object.entries(data).map(([key, value]) => {
    // console.log(value[Number(key) + 1])
    info.push({ ['x']: Number(key) + 1, ['y']: value[Number(key) + 1] })
  })

  return info
}

const styles = {
  padding: {
    padding: '41px'
  },
  header: {
    fontFamily: 'Ubuntu',
    fontColor: '#4a4a4a',
    fontWeight: 500,
    fontSize: '20px',
    margin: '0'
  },
  card: {
    textAlign: 'end',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    borderRadius: '10px',
    width: '27%',
    padding: '15px',
    marginBottom: '33px'
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-15px',
    marginLeft: '0px'
  },
  span: {
    display: 'inline-block',
    width: '3%'
  },
  typeText: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    color: '#828282',
    fontWeight: 400,
    margin: '0 0 5px'
  },
  durationText: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: '0 0 13px'
  },
  amountText: {
    fontSize: '30px',
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: '0'
  },
  graph: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '280px',
    width: '85%',
    margin: '0 auto 0 auto',
    border: '2px solid #979797',
    borderTop: 0,
    borderRight: 0
  },
  graphText: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: '0 0 5px 6%'
  },
  month: {
    width: '87%',
    display: 'flex',
    margin: '0 auto'
  },
  monthText: {
    color: '#4a4a4a',
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    fontSize: '20px'
  },
  bookingLine: {
    width: '64px',
    borderTop: 'solid 4px #50e3c2'
  },
  visitorsLine: {
    width: '64px',
    borderTop: 'solid 4px #ff5e01'
  }
}

class AffiliateDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      width: 0,
      graphWidth: 796,
      border: 1000,
      commisionToday: 0,
      commisionMonth: 0,
      commisionYear: 0,
      bookingToday: 0,
      bookingMonth: 0,
      bookingYear: 0,
      graph: [],
      onload: true
    }
  }

  componentDidMount() {
    const pid = localStorage.getItem('pid')
    let card = document.getElementById('card')
    if (card) {
      // console.log(card.offsetHeight)
      // console.log(card.offsetWidth)
      // console.log(card.offsetWidth * 0.65)

      let graphWidth = card.offsetWidth * 0.65
      let border = card.offsetWidth * 0.85

      this.setState({
        height: card.offsetHeight,
        width: card.offsetWidth,
        graphWidth,
        border
      })
    }

    axios
      .get(
        `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/dashboard/affiliate/${pid}`
      )
      .then(res => {
        const data = res.data
        const {
          commisionToday,
          commisionMonth,
          commisionYear,
          bookingToday,
          bookingMonth,
          bookingYear,
          graphData
        } = data.message
        console.log('Affiliate data >>> ', data.message)

        sortGraphData(graphData)

        if (data.message) {
          this.setState({
            commisionToday,
            commisionMonth,
            commisionYear,
            bookingToday,
            bookingMonth,
            bookingYear,
            graph: sortGraphData(graphData),
            onload: false
          })
        }
      })
  }
  render() {
    const { classes } = this.props
    const {
      graphWidth,
      commisionToday,
      commisionMonth,
      commisionYear,
      bookingToday,
      bookingMonth,
      bookingYear,
      graph,
      onload
    } = this.state

    const currentMonth = moment().format('MMMM')
    const nextMonth = moment()
      .add(1, 'months')
      .format('MMMM')
    const currentYear = moment().format('YYYY')

    const data = {
      summaryText: 'Earning Summary',
      dashboard_data: [
        {
          type: 'Commision (THB)',
          durationText: 'Today',
          amount: commisionToday
        },
        {
          type: 'Commision (THB)',
          durationText: `${currentMonth}`,
          amount: commisionMonth
        },
        {
          type: 'Commision (THB)',
          durationText: `${currentYear}`,
          amount: commisionYear
        },
        {
          type: 'Booking',
          durationText: 'Today',
          amount: bookingToday
        },
        {
          type: 'Booking',
          durationText: `${currentMonth}`,
          amount: bookingMonth
        },
        {
          type: 'Booking',
          durationText: `${currentYear}`,
          amount: bookingYear
        }
      ],
      bookingText: 'Booking & Unique Visitors',
      conversionText: 'Conversion rate = 45.54%'
    }

    const graphInfo = graph.length > 0 ? graph : graphData

    console.log('currentMonth >>> ', currentMonth)
    console.log('currentYear >>> ', currentYear)

    return (
      <Card id="card">
        <SpinnerView show={onload} />
        <div className={classes.padding}>
          <p className={classes.header}>{data.summaryText}</p>
          <Space size="30" />
          <div className={classes.row}>
            {data.dashboard_data.map(
              ({ type, durationText, amount }, index) => (
                <Fragment key={index}>
                  <div className={classes.card}>
                    <p className={classes.typeText}>{type}</p>
                    <p className={classes.durationText}>{durationText}</p>
                    <p className={classes.amountText}>
                      {numberWithCommas(amount)}
                    </p>
                  </div>
                  <div className={classes.span} />
                </Fragment>
              )
            )}
          </div>
          <Space size="18" />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className={classes.header}>{data.bookingText}</p>
            {/* <p className={classes.header} style={{ margin: '0 0 0 auto' }}>
              {data.conversionText}
            </p> */}
          </div>
          <Space size="59" />

          <div className={classes.graph}>
            <p className={classes.graphText}>Booking & Unique Visitors</p>
            <div style={{ margin: '0 auto 0 auto' }}>
              <XYPlot
                width={graphWidth}
                height={210}
                xDomain={[1, graphInfo.length]}
                margin={{
                  left: 60,
                  right: 60
                }}
              >
                {/* <VerticalGridLines /> */}
                <HorizontalGridLines />
                <XAxis
                  title={`${currentMonth}`}
                  style={{
                    title: {
                      transform: 'translateY(19px)',
                      fontWeight: 700
                    }
                  }}
                />
                <XAxis
                  title={`${nextMonth}`}
                  orientation="right"
                  hideTicks
                  style={{
                    title: {
                      transform: 'translateY(99px)',
                      fontWeight: 700
                    }
                  }}
                />
                <YAxis
                  title="Bookings"
                  style={{
                    title: {
                      transform: 'translate(-31px, 20px) rotate(-45deg)',
                      fontWeight: 700
                    }
                  }}
                />
                <YAxis
                  title="Unique visitors"
                  orientation="right"
                  style={{
                    title: {
                      transform: 'translate(-140px, 120px) rotate(-135deg)',
                      fontWeight: 700
                    }
                  }}
                />
                <LineMarkSeries
                  className="linemark-series-example"
                  style={{
                    strokeWidth: '3px'
                  }}
                  lineStyle={{ stroke: 'red' }}
                  markStyle={{ stroke: 'blue' }}
                  data={graphInfo}
                />
              </XYPlot>
            </div>
          </div>
          <div className={classes.month}>
            <p
              className={classes.monthText}
              style={{ margin: '20px 0 20px 0' }}
            >
              {currentMonth}
            </p>
            <p
              className={classes.monthText}
              style={{ margin: '20px 0 20px auto' }}
            >
              {nextMonth}
            </p>
          </div>
          <Space size="34" />
          <div className={classes.bookingLine} />
          <p className={classes.monthText} style={{ margin: '10px 0' }}>
            Booking
          </p>
          <div className={classes.visitorsLine} />
          <p className={classes.monthText} style={{ margin: '10px 0' }}>
            Unique Visitors
          </p>
          <Space size="97" />
        </div>
      </Card>
    )
  }
}

export default withStyles(styles)(AffiliateDashboard)
