import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { change, submit, isSubmitting } from 'redux-form'
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  SimpleForm,
  TextInput,
  UPDATE,
  REDUX_FORM_NAME
} from 'react-admin'
// import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import dataProvider from '../../../dataProvider'

class SendEmailButton extends Component {
  state = {
    error: false,
    showDialog: false
  }

  handleClick = () => {
    this.setState({ showDialog: true })
  }

  handleCloseClick = () => {
    this.setState({ showDialog: false })
  }

  handleSaveClick = () => {
    const { submit } = this.props

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('send-email')
  }

  handleSubmit = (values, ...props) => {
    const {
      change,
      fetchStart,
      fetchEnd,
      showNotification,
      record,
      sendTo
    } = this.props
    const { id } = record
    record['targetEmail'] = values.targetEmail
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart()
    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    const recordSender = { ...record, ...{ sendType: sendTo } }
    if (sendTo === 'operator_email') {
      delete recordSender.targetEmail
    }
    dataProvider(UPDATE, 'tickets/email', {
      id: id,
      data: recordSender
    })
      .then(({ data }) => {
        // Update the main react-admin form (in this case, the comments creation form)
        change(REDUX_FORM_NAME, 'put_id', data.id)
        this.setState({ showDialog: false })
      })
      .catch(error => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        alert('Send Done')
        fetchEnd()
      })
  }

  render() {
    const { showDialog } = this.state
    const { isSubmitting, record, sendTo } = this.props
    // alert(JSON.stringify(record))
    // console.log(this.props)
    let sendE = 'someOne@mail.com'
    let nameE = 'someOne'
    if (sendTo === 'user_email') {
      sendE = 'Customer'
      nameE = record.user_name
    } else if (sendTo === 'operator_email') {
      sendE = 'Operator'
      nameE = record.companyName
    }
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          color="primary"
          variant="contained"
          size="large"
        >
          <span
            style={{ fontSize: '0.825rem' }}
          >{`SEND ${sendE} Booking Email`}</span>
          <Icon style={{ marginLeft: '8px' }}>send</Icon>
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create post"
        >
          <DialogTitle>{`Send Customer Booking Detail To ${sendE}:`}</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              // We override the redux-form onSubmit prop to handle the submission ourselves
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
              form="send-email"
              resource="tickets"
              onSubmit={this.handleSubmit}
            >
              <span>{nameE}</span>
              {sendTo === 'user_email' && (
                <TextInput
                  label="Email: "
                  source="targetEmail"
                  defaultValue={record[sendTo]}
                  validate={required()}
                />
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              color="primary"
              variant="contained"
              size="large"
            >
              {`SEND ${sendE} Booking Email`}
            </Button>
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('send-email')(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendEmailButton)
