export const ThaiBankLists = [
  {
    id: 'bbl',
    code: '002',
    color: '#1e4598',
    official_name: 'BANGKOK BANK PUBLIC COMPANY LTD.',
    name: 'Bangkok Bank'
  },
  {
    id: 'kbank',
    code: '004',
    color: '#138f2d',
    official_name: 'KASIKORNBANK PUBLIC COMPANY LTD.',
    name: 'Kasikornbank'
  },
  {
    id: 'rbs',
    code: '005',
    color: '#032952',
    official_name: 'THE ROYAL BANK OF SCOTLAND PLC',
    name: 'Royal Bank of Scotland'
  },
  {
    id: 'ktb',
    code: '006',
    color: '#1ba5e1',
    official_name: 'KRUNG THAI BANK PUBLIC COMPANY LTD.',
    name: 'Krungthai Bank'
  },
  {
    id: 'jpm',
    code: '008',
    color: '#321c10',
    official_name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATION',
    name: 'J.P. Morgan'
  },
  {
    id: 'mufg',
    code: '010',
    color: '#d61323',
    official_name: 'THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.',
    name: 'Bank of Tokyo-Mitsubishi UFJ'
  },
  {
    id: 'tmb',
    code: '011',
    color: '#1279be',
    official_name: 'TMB BANK PUBLIC COMPANY LIMITED.',
    name: 'TMB Bank'
  },
  {
    id: 'scb',
    code: '014',
    color: '#4e2e7f',
    official_name: 'SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.',
    name: 'Siam Commercial Bank'
  },
  {
    id: 'citi',
    code: '017',
    color: '#1583c7',
    official_name: 'CITIBANK, N.A.',
    name: 'Citibank'
  },
  {
    id: 'smbc',
    code: '018',
    color: '#a0d235',
    official_name: 'SUMITOMO MITSUI BANKING CORPORATION',
    name: 'Sumitomo Mitsui Banking Corporation'
  },
  {
    id: 'sc',
    code: '020',
    color: '#0f6ea1',
    official_name: 'STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED',
    name: 'Standard Chartered (Thai)'
  },
  {
    id: 'cimb',
    code: '022',
    color: '#7e2f36',
    official_name: 'CIMB THAI BANK PUPBLIC COMPANY LTD.',
    name: 'CIMB Thai Bank'
  },
  {
    id: 'uob',
    code: '024',
    color: '#0b3979',
    official_name: 'UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED',
    name: 'United Overseas Bank (Thai)'
  },
  {
    id: 'bay',
    code: '025',
    color: '#fec43b',
    official_name: 'BANK OF AYUDHYA PUBLIC COMPANY LTD.',
    name: 'Bank of Ayudhya (Krungsri)'
  },
  {
    id: 'mega',
    code: '026',
    color: '#815e3b',
    official_name: 'MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED',
    name: 'Mega International Commercial Bank'
  },
  {
    id: 'boa',
    code: '027',
    color: '#e11e3c',
    official_name: 'BANK OF AMERICA, NATIONAL ASSOCIATION',
    name: 'Bank of America'
  },
  {
    id: 'cacib',
    code: '028',
    color: '#0e765b',
    official_name: 'CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK',
    name: 'Crédit Agricole'
  },
  {
    id: 'gsb',
    code: '030',
    color: '#eb198d',
    official_name: 'THE GOVERNMENT SAVINGS BANK',
    name: 'Government Savings Bank'
  },
  {
    id: 'hsbc',
    code: '031',
    color: '#fd0d1b',
    official_name: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.',
    name: 'Hongkong and Shanghai Banking Corporation'
  },
  {
    id: 'db',
    code: '032',
    color: '#0522a5',
    official_name: 'DEUTSCHE BANK AG.',
    name: 'Deutsche Bank'
  },
  {
    id: 'ghb',
    code: '033',
    color: '#f57d23',
    official_name: 'THE GOVERNMENT HOUSING BANK',
    name: 'Government Housing Bank'
  },
  {
    id: 'baac',
    code: '034',
    color: '#4b9b1d',
    official_name: 'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES',
    name: 'Bank for Agriculture and Agricultural Cooperatives'
  },
  {
    id: 'mb',
    code: '039',
    color: '#150b78',
    official_name: 'MIZUHO BANK, LTD.',
    name: 'Mizuho Bank'
  },
  {
    id: 'bnp',
    code: '045',
    color: '#14925e',
    official_name: 'BNP PARIBAS',
    name: 'BNP Paribas'
  },
  {
    id: 'tbank',
    code: '065',
    color: '#fc4f1f',
    official_name: 'THANACHART BANK PUBLIC COMPANY LTD.',
    name: 'Thanachart Bank'
  },
  {
    id: 'ibank',
    code: '066',
    color: '#184615',
    official_name: 'ISLAMIC BANK OF THAILAND',
    name: 'Islamic Bank of Thailand'
  },
  {
    id: 'tisco',
    code: '067',
    color: '#12549f',
    official_name: 'TISCO BANK PUBLIC COMPANY LIMITED',
    name: 'Tisco Bank'
  },
  {
    id: 'kk',
    code: '069',
    color: '#199cc5',
    official_name: 'KIATNAKIN BANK PUBLIC COMPANY LIMITED',
    name: 'Kiatnakin Bank'
  },
  {
    id: 'icbc',
    code: '070',
    color: '#c50f1c',
    official_name:
      'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED',
    name: 'Industrial and Commercial Bank of China (Thai)'
  },
  {
    id: 'tcrb',
    code: '071',
    color: '#0a4ab3',
    official_name: 'THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED',
    name: 'Thai Credit Retail Bank'
  },
  {
    id: 'lhb',
    code: '073',
    color: '#6d6e71',
    official_name: 'LAND AND HOUSES BANK PUBLIC COMPANY LIMITED',
    name: 'Land and Houses Bank'
  }
]

export const SortThaiBank = ThaiBankLists.sort((a, b) => {
  if (a.id < b.id) {
    return -1
  } else if (a.id > b.id) {
    return 1
  } else {
    return 0
  }
}).map(({ id, name }) => {
  return {
    id: id.toUpperCase(),
    name: `${id.toLocaleUpperCase()} - ${name}`
  }
})
