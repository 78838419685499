import React, { Fragment } from 'react'
import {
  Filter,
  List,
  Edit,
  regex,
  Create,
  Datagrid,
  TextField,
  ReferenceField,
  Toolbar,
  EditButton,
  DeleteButton,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  LongTextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  ImageInput,
  ImageField,
  ArrayInput,
  DisabledInput,
  SimpleFormIterator,
  ArrayField
} from 'react-admin'
import DefaultIcon from '@material-ui/icons/Group'
import Button from '@material-ui/core/Button'
import Pagination from './custom/view/Pagination'

// Validation props
const validateURL = regex(
  /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
  'Must be a valid URL'
)

// Exporter
const LocationLink = ({ record }) => {
  return (
    <a href={`${record.url}`} target="blank">
      {/* See in GOOGLE MAP */}
      {record.url}
    </a>
  )
}

const BackgroundField = ({ record, width_size }) => {
  const { background_image } = record
  return (
    <img
      src={background_image}
      alt=""
      style={{ width: `${width_size || 50}%`, margin: '12px 0' }}
    />
  )
}

const LocationFilter = props => (
  <Filter {...props}>
    <TextInput label="Search Location" source="q" alwaysOn />
    <SelectInput
      source="type"
      label="Location type"
      choices={[
        { id: 'Location::Terminal', name: 'Terminal' },
        { id: 'Location::Area', name: 'Destination' }
      ]}
    />
    <ReferenceInput
      source="destination"
      reference="locations"
      filter={{ type: 'Location::Area' }}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={1000}
      allowEmpty
    >
      <AutocompleteInput optionText="location_name" />
    </ReferenceInput>
    <ReferenceInput
      label="Company"
      source="company"
      reference="companies"
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput source="op_name" />
    </ReferenceInput>
  </Filter>
)

export const LocationIcon = () => <DefaultIcon />
export const LocationList = props => (
  <List
    {...props}
    filters={<LocationFilter />}
    sort={{ field: 'name', order: 'ASC' }}
    title="Show location lists"
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField label="Location name" source="location_name" />
      <ReferenceField
        label="Company"
        source="company_id"
        reference="companies"
        linkType={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Destination"
        source="destination"
        reference="locations"
        linkType={false}
        allowEmpty
      >
        <TextField source="location_name" />
      </ReferenceField>
      <TextField source="type" />
      <LocationLink label="Location link" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const LocationToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/locations" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/locations" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const LocationEdit = props => {
  return (
    <Edit title="Location Edit" {...props}>
      <SimpleForm toolbar={<LocationToolbar />}>
        <TextInput source="location_name" label="Location name" />
        <SelectInput
          label="Type"
          source="type"
          choices={[
            { id: 'Destination', name: 'Destination' },
            { id: 'Terminal', name: 'Terminal' }
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === 'Terminal' && (
              <Fragment>
                <ReferenceInput
                  source="destination"
                  reference="locations"
                  filter={{ type: 'Location::Area' }}
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={200}
                  allowEmpty
                >
                  <AutocompleteInput optionText="location_name" />
                </ReferenceInput>
                <ReferenceInput
                  source="company_id"
                  reference="companies"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={1000}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </Fragment>
            )
          }
        </FormDataConsumer>
        <NumberInput source="latitude" label="Latitude" />
        <NumberInput source="longitude" label="Longitude" />
        <LongTextInput
          source="url"
          label="Url Address"
          validate={validateURL}
        />
        <LongTextInput source="address" label="Address" />
        <LongTextInput source="description" label="Description (optional)" />
        <ImageInput
          source="pictures"
          label="Related pictures"
          placeholder={
            <p>Drop your file here *** Max size is 840KB ** Max 5 Images</p>
          }
          accept="image/*"
          multiple="true"
          maxSize="840000"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="images" label="Edit Image">
          <SimpleFormIterator>
            <LongTextInput source="title" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayField source="images" label="Current Image">
          <Datagrid>
            <ImageField source="url" label="Image" />
            <TextField source="title" />
            {/* <DeleteButton /> */}
          </Datagrid>
        </ArrayField>
        {/*  */}
        <ImageInput
          source="background_image_picture"
          label="Update location background"
          placeholder={
            <div>
              <p>
                Drop Location background image here <br /> *** Max size is 960KB
                ***
              </p>
            </div>
          }
          accept="image/*"
          multiple={false}
          maxSize="960000"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BackgroundField width_size="70" label="Location background image" />
      </SimpleForm>
    </Edit>
  )
}

export const LocationCreate = props => (
  <Create title="Location Create" {...props}>
    <SimpleForm toolbar={<LocationToolbar />}>
      <TextInput source="location_name" label="Location name" />
      <SelectInput
        label="Type"
        source="type"
        choices={[
          { id: 'Destination', name: 'Destination' },
          { id: 'Terminal', name: 'Terminal' }
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'Terminal' && (
            <Fragment>
              <ReferenceInput
                source="destination"
                reference="locations"
                filter={{ type: 'Location::Area' }}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={200}
                allowEmpty
              >
                <AutocompleteInput optionText="location_name" />
              </ReferenceInput>
              <ReferenceInput
                source="company_id"
                reference="companies"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={1000}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Fragment>
          )
        }
      </FormDataConsumer>
      <NumberInput source="latitude" label="Latitude" />
      <NumberInput source="longitude" label="Longitude" />
      <LongTextInput source="url" label="Url Address" validate={validateURL} />
      <LongTextInput source="address" label="Address" />
      <LongTextInput source="description" label="Description (optional)" />
      <ImageInput
        source="pictures"
        label="Related pictures"
        placeholder={
          <p>Drop your file here *** Max size is 840KB ** Max 5 Images</p>
        }
        accept="image/*"
        multiple="true"
        maxSize="840000"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      {/* <ArrayField source="images">
        <Datagrid>
          <ImageField source="url" label="Image" />
          <TextField source="title" />
        </Datagrid>
      </ArrayField> */}
      {/*  */}
      <ImageInput
        source="background_image_picture"
        label="Update location background"
        placeholder={
          <div>
            <p>
              Drop Location background image here <br /> *** Max size is 960KB
              ***
            </p>
          </div>
        }
        accept="image/*"
        multiple={false}
        maxSize="960000"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
)
