import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import { routeActive, routeDeActive } from '../action/RouteAction'
import { tripActive, tripDeActive } from '../action/TripAction'
import { startUndoable as startUndoableAction } from 'ra-core'
import { change, submit, isSubmitting } from 'redux-form'
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  TextInput,
  LongTextInput,
  NumberInput,
  CREATE,
  REDUX_FORM_NAME
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import dataProvider from '../../../dataProvider'

class TripButton extends Component {
  state = {
    error: false,
    showDialog: false
  }
  handleClick = () => {
    this.setState({ showDialog: true })
  }

  handleCloseClick = () => {
    this.setState({ showDialog: false })
  }

  handleSubmit = values => {
    const { change, fetchStart, fetchEnd, showNotification } = this.props
    const {
      id,
      cost,
      weeklyScheduleMask,
      time,
      bookingWindows
    } = this.props.record
    values.id = id
    values.cost = cost
    values.weeklyScheduleMask = weeklyScheduleMask
    values.time = time
    // bookingWindows

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart()

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, 'trips/toggle', { data: values })
      .then(({ data }) => {
        // Update the main react-admin form (in this case, the comments creation form)
        this.setState({ showDialog: false })
      })
      .catch(error => {
        showNotification(error.message, 'error')
      })
      .finally(() => {
        fetchEnd()
      })
  }

  handleSaveClick = () => {
    const { submit } = this.props

    // // Trigger a submit of our custom quick create form
    // // This is needed because our modal action buttons are oustide the form
    submit('post-quick-create')
  }

  render() {
    const { name, bookingWindows } = this.props.record
    const { showDialog } = this.state
    const { isSubmitting } = this.props

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Toggle Trip">
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Generate trip"
        >
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form="post-quick-create"
              resource="trips"
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              onClose={this.handleClose}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              <NumberInput
                label="Booking Window"
                source="bookingWin"
                defaultValue={bookingWindows || 360}
                fullWidth
              />
              <NumberInput
                label="Seat Capacity"
                defaultValue={999}
                source="seatCap"
                fullWidth
              />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('post-quick-create')(state)
})

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripButton)
