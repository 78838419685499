import React, { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { notification, Icon } from 'antd'

import axios from 'axios'

import Space from './custom/view/AffiliateDashboard/Space'

// REACT QR-CODE
const QRCode = require('qrcode.react')

const departureData = location => {
  let loc = location.map(({ id, name, url_key }, index) => {
    return {
      ['id']: id,
      ['url_key']: url_key,
      ['value']: name,
      ['label']: name
    }
  })

  return loc
}

const filterRouteBlank = (option, routematch) => {
  let filter = []

  Object.keys(routematch).forEach(key => {
    // console.log('filterRouteBlank key >>> ', key)

    option.map(item => {
      if (item.id === Number(key)) {
        filter.push(item)
      }
    })
  })

  return filter
}

const findUrlKey = (option, value) => {
  let url = option.filter(item => {
    return item.value === value
  })

  console.log('url >>> ', url, 'url_key', url[0].url_key)
  return url[0].url_key
}

const styles = theme => ({
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0
  },
  desc: {
    fontFamily: 'Ubuntu',
    fontSize: '16px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '180px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    padding: '5px 3.7% 5px 3.7%',
    position: 'relative'
  },
  downloadText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#3e4eb8',
    margin: '0 auto',
    position: 'absolute',
    bottom: '33px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  affLink: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#4a4a4a',
    margin: '0 auto',
    position: 'absolute',
    bottom: '11px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: 0
  },
  copyText: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: '0 auto',
    position: 'absolute',
    bottom: '30px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  cardLeft: {
    width: '34.6%',
    height: '263px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleLeft: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '24px',
    top: '18px'
  },
  selectDeparture: {
    position: 'absolute',
    left: '24px',
    top: '84px',
    width: '83%'
  },
  selectArrival: {
    position: 'absolute',
    left: '24px',
    top: '134px',
    width: '83%'
  },
  span: {
    display: 'inline-block',
    width: '3.1%'
  },
  cardRight: {
    width: '63.2%',
    height: '263px',
    borderRadius: '10px',
    boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.21)',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  titleRight: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 700,
    margin: 0,
    position: 'absolute',
    left: '43px',
    top: '18px'
  },
  routeQR: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    margin: '0 auto',
    left: 0,
    right: 0,
    bottom: '74px'
  },
  downloadQRroute: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#3e4eb8',
    margin: '0 auto',
    position: 'absolute',
    bottom: '59px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  affrouteLink: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#4a4a4a',
    margin: '0 auto',
    position: 'absolute',
    bottom: '28px',
    left: 0,
    right: 0,
    cursor: 'pointer'
  },
  button: {
    width: '83%',
    height: '50px',
    borderRadius: '4px',
    backgroundColor: '#283593',
    textTransform: 'none',
    position: 'absolute',
    left: '24px',
    top: '195px',
    '&:hover': {
      backgroundColor: '#283593'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    color: '#ffffff',
    margin: 0
  },
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  }
})

class QRcode extends Component {
  state = {
    departure: 'Bangkok',
    departureOption: [],
    departureKey: 'bangkok',
    arrival: 'Chiangmai',
    arrivalOption: [],
    arrivalKey: 'chiangmai',
    pid: '',
    routematch: {},
    routeLink: '',
    routeDownloadLink: '',
    qrcode: '',
    routeName: '',
    routeQRcode: '',
    routeDeeplink: '-',
    value: ''
  }

  componentDidMount() {
    const { departureKey, arrivalKey, departure, arrival } = this.state
    const pid = localStorage.getItem('pid')
    const baseurl = `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}`

    console.log('componentDidMount')

    axios
      .get(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/qrcodes/${pid}`)
      .then(res => {
        const data = res.data.message
        const { Qrcodes } = data
        // console.log('QRcode data >>> ', data)
        if (data && Qrcodes.length > 0) {
          this.setState({
            qrcode: Qrcodes[0].link,
            routeName: `${departure} to ${arrival}`,
            routeLink: `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}?pid=${pid}`,
            pid
          })
        }
      })

    this.setState({
      routeQRcode: baseurl
    })

    axios
      .put(
        `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/editRouteQrcodes/${pid}`,
        {
          baseurl
        }
      )
      .then(res => {
        const data = res.data.message
        const { route, link } = data
        if (route) {
          this.setState({
            routeDownloadLink: link
          })
        }
      })

    axios
      .get(
        `https://82ywqgk2ik.execute-api.ap-southeast-1.amazonaws.com/dev/locations/searchbox`
      )
      .then(res => {
        const data = res.data
        // console.log('locations data >>> ', data.message)

        if (data.status) {
          this.setState({
            departureOption: departureData(data.message),
            arrivalOption: departureData(data.message)
          })
        }
      })

    axios
      .get(
        `https://7eliseuvsc.execute-api.ap-southeast-1.amazonaws.com/dev/transportations/routesMatch`
      )
      .then(res => {
        const data = res.data
        // console.log('routematch data >>> ', data.message)

        if (data.status) {
          this.setState({ routematch: data.message })
        }
      })
  }

  handleArrival = (option, routematch, name) => {
    let data = option.filter(item => {
      return item.value === name
    })

    let Route = []
    Object.entries(routematch).forEach(route => {
      // console.log(route[0])
      if (Number(route[0]) === data[0].id) {
        Route.push(route[1])
      }
    })

    // console.log('handleArrival >>> ', Route, data[0].id)

    let Arrival = []

    if (Route.length > 0) {
      Object.values(Route[0]).forEach(value => {
        option.map(item => {
          if (item.id === value) {
            Arrival.push(item)
          }
        })
      })
    }

    this.setState({ arrivalOption: Arrival })

    // console.log('arrival value >>> ', Arrival)
  }

  handleChange = name => event => {
    const { departureOption, routematch } = this.state

    if (name === 'departure') {
      this.handleArrival(departureOption, routematch, event.target.value)
      findUrlKey(departureOption, event.target.value)

      this.setState({
        [name]: event.target.value,
        [`${name}Key`]: findUrlKey(departureOption, event.target.value)
      })
    } else {
      this.setState({
        [name]: event.target.value,
        [`${name}Key`]: findUrlKey(departureOption, event.target.value)
      })
    }
  }

  success = () => {
    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Download Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  generateSuccess = () => {
    notification.config({
      placement: 'bottomRight'
    })

    notification.open({
      message: 'Generate Succeeded!',
      icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
    })
  }

  generateQRcode = () => {
    const {
      departureKey,
      departure,
      arrival,
      arrivalKey,
      pid,
      routeDownloadLink
    } = this.state
    const baseurl = `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}`
    this.setState({
      routeDownloadLink: '',
      routeQRcode: baseurl,
      routeName: `${departure} to ${arrival}`,
      routeLink: `https://www.kohlife.com/travel/${departureKey}/${arrivalKey}?pid=${pid}`
    })
    this.generateSuccess()
    axios
      .put(
        `${process.env.REACT_APP_URL_PARTNER}api/1.0.0/editRouteQrcodes/${pid}`,
        {
          baseurl
        }
      )
      .then(res => {
        const data = res.data.message
        const { route, link } = data
        if (route) {
          this.setState({
            routeDownloadLink: link
          })
        }
      })
  }

  render() {
    const { classes } = this.props
    const {
      pid,
      departure,
      arrival,
      qrcode,
      routeQRcode,
      routeName,
      routeDeeplink,
      departureOption,
      arrivalOption,
      routematch,
      routeLink,
      routeDownloadLink,
      departureKey,
      arrivalKey
    } = this.state

    const affLink = `(https://www.kohlife.com?pid=${pid})`
    // const routeLink = `(https://www.kohlife.com/travel/${departureKey}/${arrivalKey}?pid=${pid})`

    const departureList = filterRouteBlank(departureOption, routematch)
    const arrivalList = filterRouteBlank(arrivalOption, routematch)

    const data = {
      deeplinkDesc: `If you have a touch point with customers with mobile phones. QR code is a fast solution to reach the offline traffic to the site with track able code. You can choose generate the QR code to homepage or unique route landing pages.`,
      routeDeeplinkText: `Your affiliate link for ${routeName}`
    }

    // console.log('qrcode >>> ', qrcode)
    return (
      <Fragment>
        <Space size="66" />
        <p className={classes.title}>QR Code Generator</p>
        <Space size="24" />
        <p className={classes.desc}>{data.deeplinkDesc}</p>
        <Space size="45" />
        <p className={classes.title}>
          Your unique QR Code to Kohlife’s homepage
        </p>
        <Space size="20" />
        <div className={classes.card}>
          <img src={qrcode} alt={qrcode} height="127" width="127" />
          <span className={classes.downloadText} onClick={this.success}>
            <a
              href={qrcode}
              style={{ color: '#3e4eb8', textDecoration: 'none' }}
              download
            >
              Download QR Code
            </a>
          </span>
          <p className={classes.affLink}>{affLink}</p>
        </div>
        <Space size="26" />
        <p className={classes.title}>Your unique link to specific route</p>
        <Space size="24" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.cardLeft}>
            <p className={classes.titleLeft}>Select the route</p>
            <TextField
              id="select-departure"
              select
              value={this.state.departure}
              onChange={this.handleChange('departure')}
              className={classes.selectDeparture}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {departureList.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="select-arrival"
              select
              value={this.state.arrival}
              onChange={this.handleChange('arrival')}
              className={classes.selectArrival}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
            >
              {arrivalList.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Button className={classes.button} onClick={this.generateQRcode}>
              <p className={classes.buttonText}>Generate link</p>
            </Button>
          </div>
          <div className={classes.span} />
          <div className={classes.cardRight}>
            <p className={classes.titleRight}>{data.routeDeeplinkText}</p>
            <QRCode
              value={routeLink}
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '58px',
                width: '110px',
                height: '110px'
              }}
            />
            {routeDownloadLink !== '' && (
              <span className={classes.downloadQRroute} onClick={this.success}>
                <a
                  href={routeDownloadLink}
                  style={{ color: '#3e4eb8', textDecoration: 'none' }}
                  download
                >
                  Download QR Code
                </a>
              </span>
            )}
            <p className={classes.affrouteLink}>({routeLink})</p>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles)(QRcode)
