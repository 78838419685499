import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const DEFAULT_SPACING = 20

const checkNull = (t, alt) => {
  return t !== undefined || t != null ? t : alt
}

// withStylesProps HOC uses withStyles
const withStylesProps = styles => Component => props => {
  const Comp = withStyles(theme => styles({ ...props, theme }))(Component)
  return <Comp {...props} />
}

// styles receive component's props (props has theme)
const styles = props => ({
  space: {
    display: 'block',
    marginTop: `${checkNull(props.size, DEFAULT_SPACING) / 2}px`,
    height: `${checkNull(props.size, DEFAULT_SPACING) / 2}px`
  }
})

export default withStylesProps(styles)(props => (
  <div className={props.classes.space} />
))
