import React from 'react'
import {
  Edit,
  Filter,
  Create,
  List,
  SimpleList,
  Datagrid,
  DeleteButton,
  TextField,
  NumberField,
  EditButton,
  SimpleForm,
  DisabledInput,
  SelectInput,
  TextInput,
  LongTextInput,
  NumberInput,
  SaveButton,
  Toolbar,
  Responsive
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { DateKOHField, NationField } from './custom/view/CustomField'
import Pagination from './custom/view/Pagination'
//
const ReviewTitle = ({ record }) => {
  return <span>Review {record ? `"${record.title}"` : ''}</span>
}

const ReviewContentField = ({ record }) => {
  const { title, route, ticket_ref } = record
  return (
    <div style={{ lineHeight: '20px', padding: '20px 0' }}>
      <span style={{ fontWeight: '500' }}>{title}</span> <br />
      {ticket_ref} - {route} <br />
    </div>
  )
}
ReviewContentField.defaultProps = { label: 'Message' }

const ReviewTravellerField = ({ record }) => {
  const { traveller_nation } = record
  return <NationField nationKey={traveller_nation} showNationText />
}
ReviewTravellerField.defaultProps = { label: 'Traveller' }

const ReviewFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      source="score_type"
      defaultValue="="
      choices={[
        { id: '=', name: 'Equal' },
        { id: '>', name: 'Greater than' },
        { id: '<', name: 'Less than' }
      ]}
    />
    <NumberInput defaultValue="10" source="score" />
  </Filter>
)

const DescriptionPanel = ({ record }) => {
  return (
    <div style={{ lineHeight: '20px', padding: '16px 36px' }}>
      <span style={{ fontWeight: '500' }}> Review Description </span>
      <br />
      {record.message}
    </div>
  )
}

// Exporter
export const ReviewList = props => (
  <List
    {...props}
    filters={<ReviewFilter />}
    perPage={30}
    pagination={<Pagination />}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.title}
          secondaryText={record => `${record.views} views`}
          tertiaryText={record =>
            new Date(record.published_at).toLocaleDateString()
          }
        />
      }
      medium={
        <Datagrid expand={<DescriptionPanel />}>
          <TextField source="id" />
          <NumberField source="votes" />
          <TextField source="traveller_name" label="Traveller" />
          <ReviewTravellerField label="Nation" />
          <ReviewContentField />
          <DateKOHField label="Created" timeKey="created_at" {...props} />
          <EditButton />
          {/* <DeleteButton /> */}
        </Datagrid>
      }
    />
  </List>
)

const ReviewCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/reviews" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/reviews" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const ReviewEdit = props => {
  return (
    <Edit title={<ReviewTitle />} {...props}>
      <SimpleForm toolbar={<ReviewCreateToolbar />}>
        <DisabledInput label="Review::Id" source="id" />
        <DisabledInput label="TransportRout::Id" source="route" />
        <DisabledInput label="Relate::Ticket::Id" source="ticket_id" />
        <LongTextInput label="Title" source="title" />
        <LongTextInput label="Message of Review" source="message" />
        <NumberInput label="Vote scores (MAX 10 points)" source="votes" />
      </SimpleForm>
    </Edit>
  )
}

export const ReviewCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<ReviewCreateToolbar />}>
      <TextInput source="title" />
      <NumberInput source="ticket_id" />
      <NumberInput source="votes" />
      <LongTextInput source="message" />
    </SimpleForm>
  </Create>
)
