import React, { Fragment } from 'react'
import '../../node_modules/react-vis/dist/style.css'

import AreaSerie from './custom/view/Graph/AreaSerie'
import VerBarSerie from './custom/view/Graph/VerBarSerie'
import HorBarSerie from './custom/view/Graph/HorBarSerie'
import LineSerie from './custom/view/Graph/LineSerie'
import MarkSerie from './custom/view/Graph/MarkSerie'
import PIChart from './custom/view/Graph/PIChart'

export const Graph = props => {
  return (
    <Fragment>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 20px'
          }}
        >
          <h2>AreaSeries</h2>
          <AreaSerie />
        </div>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 60px'
          }}
        >
          <h2>VerticalBarSeries</h2>
          <VerBarSerie />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 20px'
          }}
        >
          <h2>HorizontalBarSeries</h2>
          <HorBarSerie />
        </div>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 60px'
          }}
        >
          <h2>LineSeries</h2>
          <LineSerie />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 20px'
          }}
        >
          <h2>MarkSeries</h2>
          <MarkSerie />
        </div>
        <div
          style={{
            flex: 1,
            height: '400px',
            width: '500px',
            // margin: 'auto'
            margin: '0px 60px 130px 60px'
          }}
        >
          <h2>PI Chart</h2>
          <PIChart />
        </div>
      </div>
    </Fragment>
  )
}
