import '../../../../../node_modules/react-vis/dist/style.css'

import React, { Fragment } from 'react'
import { AreaSeries, LineSeries } from 'react-vis'

import { generateLinearData, nonUniformX } from './Data'
import { SimpleChartWrapper } from './Utils'

const AreaSerie = () => {
  return (
    <SimpleChartWrapper>
      <AreaSeries opacity={0.5} data={generateLinearData({ key: 'area1' })} />
    </SimpleChartWrapper>
  )
}

export default AreaSerie
