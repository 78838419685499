import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/styles'
import dataProvider from '../dataProvider'
import {
  List,
  GET_LIST,
  GET_ONE,
  Datagrid,
  TextField,
  EditButton,
  CreateButton,
  ShowButton,
  crudGetMatching,
  FormDataConsumer,
  CheckboxGroupInput,
  NumberInput,
  BooleanInput,
  Filter,
  LongTextInput,
  TextInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BulkDeleteButton,
  Create,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  Toolbar,
  DisabledInput,
  SaveButton,
  fetchStart,
  NumberField,
  fetchEnd
} from 'react-admin'
import DefaultIcon from '@material-ui/icons/EventNote'
import StatusButton from './custom/view/StatusButton'
import TripButton from './custom/view/TripButton'
import BulkStatusButton from './custom/view/BulkStatusButton'
import Button from '@material-ui/core/Button'
import { TimeInput } from 'react-admin-date-inputs'
import { TimeKOHField } from './custom/view/CustomField'
import Pagination from './custom/view/Pagination'
import { withRouter } from 'react-router-dom'

// custome field
const BulkActionButtons = props => (
  <Fragment>
    <BulkStatusButton
      text="Deactive All"
      direct="routes"
      action="close"
      {...props}
    />
    <BulkStatusButton
      text="Active All"
      direct="routes"
      action="available"
      {...props}
    />
    <BulkDeleteButton quote="route" {...props} />
  </Fragment>
)

const CompanyToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/routes" />
    <span style={{ margin: '0 6px' }} />
    <a href="/#/routes" style={{ textDecoration: 'none' }}>
      <Button variant="contained" size="large">
        BACK
      </Button>
    </a>
  </Toolbar>
)

// custome field

// Validation props
const required = (message = 'Required') => value =>
  value ? undefined : message

const RoutesFilter = props => {
  const { filterValues } = props
  return (
    <Filter {...props}>
      {/* <TextInput label="Search" source="q" alwaysOn /> */}
      <ReferenceInput
        label="Company"
        source="company_id"
        reference="companies"
        perPage={80}
        sort={{ field: 'op_name', order: 'ASC' }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="op_name" />
      </ReferenceInput>
      {/* {filterValues.hasOwnProperty('company_id') && ( */}
      <ReferenceInput
        label="From Destination"
        source="from_area_id"
        reference="routes/filterStation"
        perPage={150}
        sort={{ field: 'seed_key', order: 'ASC' }}
        filter={{
          company_id: filterValues.company_id,
          target: 'from_area_id'
        }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="location_name" />
      </ReferenceInput>
      {/* )} */}
      {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') && (
          <ReferenceInput
            label="From Station"
            source="from_location_id"
            reference="routes/filterStation"
            perPage={150}
            sort={{ field: 'seed_key', order: 'ASC' }}
            filter={{
              company_id: filterValues.company_id,
              from_area_id: filterValues.from_area_id,
              target: 'from_location_id'
            }}
            allowEmpty
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        )}
      {/* {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') && ( */}
      <ReferenceInput
        label="To Destination"
        source="to_area_id"
        reference="routes/filterStation"
        perPage={150}
        sort={{ field: 'seed_key', order: 'ASC' }}
        filter={{
          company_id: filterValues.company_id,
          from_area_id: filterValues.from_area_id,
          target: 'to_area_id'
        }}
        allowEmpty
        alwaysOn
      >
        <AutocompleteInput optionText="location_name" />
      </ReferenceInput>
      {/* )} */}
      {filterValues.hasOwnProperty('company_id') &&
        filterValues.hasOwnProperty('from_area_id') &&
        filterValues.hasOwnProperty('from_location_id') &&
        filterValues.hasOwnProperty('to_area_id') && (
          <ReferenceInput
            label="To Station"
            source="to_location_id"
            reference="routes/filterStation"
            perPage={150}
            sort={{ field: 'seed_key', order: 'ASC' }}
            filter={{
              company_id: filterValues.company_id,
              from_area_id: filterValues.from_area_id,
              from_location_id: filterValues.from_location_id,
              to_area_id: filterValues.to_area_id,
              target: 'to_location_id'
            }}
            allowEmpty
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        )}
      <SelectInput
        source="status"
        choices={[
          { id: 'available', name: 'Available' },
          { id: 'close', name: 'Close' }
        ]}
      />
    </Filter>
  )
}

// const RoutesFilter = props => (
//   <Filter {...props}>
//     {/* <TextInput label="Search" source="q" alwaysOn /> */}
//     <ReferenceInput
//       label="Company"
//       source="companyId"
//       reference="companies"
//       allowEmpty
//       alwaysOn
//     >
//       <SelectInput optionText="op_name" />
//     </ReferenceInput>
//     <ReferenceInput
//       label="From Destination"
//       source="fromAreaId"
//       reference="routes/fromDestination"
//       allowEmpty
//     >
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//     <ReferenceInput
//       label="From Station"
//       source="fromStaId"
//       reference="routes/fromStation"
//       allowEmpty
//     >
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//     <ReferenceInput
//       label="To Destination"
//       source="toAreaId"
//       reference="routes/toDestination"
//       allowEmpty
//     >
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//     <ReferenceInput
//       label="To Station"
//       source="toStaId"
//       reference="routes/toStation"
//       allowEmpty
//     >
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//     <SelectInput
//       source="status"
//       choices={[
//         { id: 'available', name: 'Available' },
//         { id: 'close', name: 'Close' }
//       ]}
//     />
//   </Filter>
// )

const getStationsFor = company => {
  if (typeof company !== 'undefined') {
    dataProvider(GET_LIST, `locations/`, {
      pagination: {
        page: 1,
        perPage: 100
      },
      sort: {
        field: 'name',
        order: 'ASC'
      },
      filter: {
        company_id: company
      }
    })
      .then(() => {
        return [
          { location_name: 'mvoermver', id: 13 },
          { location_name: 'doraemon', id: 555 }
        ]
      })
      .catch(e => {
        console.error(e)
        return [{ location_name: 'doraemon', id: 555 }]
      })
  } else {
    return [{ location_name: 'doraemon', id: 555 }]
  }
}

const CreateRouteCloneButton = ({ record }) => {
  const MyCloneButton = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dataProvider(GET_ONE, 'routes', { id: record.id }).then(response => {
          const data = response.data

          let locationObj = {
            pathname: '/routes/create',
            state: {
              record: Object.assign({}, data, { companyId: data.company.id })
            }
          }
          history.push(locationObj)
        })
      }}
    >
      Clone
    </Button>
  ))

  return <MyCloneButton />
}

const RoutePanel = ({ record }) => {
  return (
    <div>
      <h4> From station : {record.trip.fromTerminal.name}</h4>
      <h4> To station :{record.trip.toTerminal.name}</h4>
      <h4>
        Depart-Arrive : {record.time.departure}-{record.time.arrival} duration{' '}
        {record.time.duration}
      </h4>
      <h4>
        Pricing : contract_rate {record.cost.netPrice} | selling_price{' '}
        {record.cost.defaultPrice}
      </h4>
    </div>
  )
}

export const RoutesIcon = () => <DefaultIcon />
export const RoutesList = props => (
  <List
    {...props}
    title="Routes"
    filters={<RoutesFilter />}
    pagination={<Pagination />}
    perPage={30}
  >
    <Datagrid expand={<RoutePanel />}>
      <TextField label="ID" source="id" />
      <TextField
        style={{ width: '100px' }}
        label="Company"
        source="company.name"
        sortable={false}
      />
      <TextField
        style={{ width: '100px' }}
        label="From"
        source="trip.from"
        sortable={false}
      />
      <TextField
        style={{ width: '100px' }}
        label="To"
        source="trip.to"
        sortable={false}
      />
      <TextField label="Dep." source="time.departure" sortable={false} />
      <TextField label="Arr." source="time.arrival" sortable={false} />
      <TextField
        label="Selling ($)"
        source="cost.defaultPrice"
        sortable={false}
      />
      <StatusButton label="Status" resourceType="route" sortable={false} />
      <TripButton />
      <EditButton />
      <CreateRouteCloneButton />
    </Datagrid>
  </List>
)

export const RoutesCreate = props => {
  return (
    <Create title="Route create" {...props}>
      <TabbedForm toolbar={<CompanyToolbar />}>
        <FormTab label="Route">
          <ReferenceInput
            label="Company"
            source="companyId"
            reference="companies"
            perPage={100}
            sort={{ field: 'op_name', order: 'ASC' }}
            validate={[required()]}
          >
            {/* <AutocompleteInput optionText="op_name" /> */}
            <SelectInput optionText="op_name" />
          </ReferenceInput>
          <CheckboxGroupInput
            source="weeklyScheduleMask"
            label="Weekly schedule"
            defaultValue={[0, 1, 2, 3, 4, 5, 6]}
            validate={[required()]}
            choices={[
              { id: 0, name: 'Sunday' },
              { id: 1, name: 'Monday' },
              { id: 2, name: 'Tuesday' },
              { id: 3, name: 'Wednesday' },
              { id: 4, name: 'Thursday' },
              { id: 5, name: 'Friday' },
              { id: 6, name: 'Saturday' }
            ]}
          />
          <h3>Destination</h3>
          <ReferenceInput
            label="Deapature from destination"
            source="trip.from_id"
            reference="locations"
            perPage={500}
            filter={{ type: 'Location::Area' }}
            sort={{
              field: 'name',
              order: 'ASC'
            }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
          <ReferenceInput
            label="Arrival to Destination"
            source="trip.to_id"
            reference="locations"
            perPage={500}
            filter={{ type: 'Location::Area' }}
            sort={{
              field: 'name',
              order: 'ASC'
            }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
          <br />
          <Dashed />
          <br />

          <h3>Trip Itinerary</h3>
          <div style={{ display: 'flex' }}>
            <SelectInput
              defaultValue={0}
              label="Departure time (HH)"
              source="time.departureHour"
              choices={render(0, 23).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
            <span style={{ position: 'relative', marginRight: '10px' }}>
              <span
                style={{
                  verticalAlign: 'bottom',
                  height: '33px',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                {' : '}
              </span>
            </span>
            <SelectInput
              label="(mm)"
              defaultValue={0}
              source="time.departureMinute"
              choices={render(0, 59).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <SelectInput
              defaultValue={0}
              label="Duration (HH)"
              source="time.durationHour"
              choices={render(0, 72).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
            <span style={{ position: 'relative', marginRight: '10px' }}>
              <span
                style={{
                  verticalAlign: 'bottom',
                  height: '33px',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                {' : '}
              </span>
            </span>
            <SelectInput
              label="(mm)"
              defaultValue={0}
              source="time.durationMinute"
              choices={render(0, 59).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
          </div>
          {/* <TextInput
            label="Total Travel time (minuters)"
            source="time.durationUnit"
          /> */}
          <Dashed width="360px" />
          <br />

          {/*  */}
          <ArrayInput
            label="Start Station"
            source="itinerary"
            validate={[required()]}
            defaultValue={[{}]}
          >
            <SimpleFormIterator style={{}}>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  return typeof formData.companyId !== 'undefined' ? (
                    <div>
                      <ReferenceInput
                        source={getSource('terminal')}
                        label="Station"
                        reference="locations"
                        perPage={100}
                        validate={[required()]}
                        sort={{
                          field: 'name',
                          order: 'ASC'
                        }}
                        filter={{
                          type: 'Location::Terminal',
                          company: formData.companyId
                        }}
                      >
                        <AutocompleteInput
                          optionText="location_name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInput>
                      <ReferenceInput
                        source={getSource('vehicle')}
                        label="Vehicle"
                        reference="vehicles"
                        perPage={100}
                        validate={[required()]}
                        sort={{ field: 'name', order: 'ASC' }}
                        filter={{
                          company: formData.companyId
                        }}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInput>
                      <div style={{ display: 'flex' }}>
                        <SelectInput
                          defaultValue={0}
                          label="Duration (HH)"
                          source={getSource('duration.hour')}
                          choices={render(0, 47).map(time => {
                            return {
                              id: time,
                              name: ('0' + time).slice(-2)
                            }
                          })}
                        />
                        <span
                          style={{
                            position: 'relative',
                            marginRight: '10px'
                          }}
                        >
                          <span
                            style={{
                              verticalAlign: 'bottom',
                              height: '33px',
                              position: 'absolute',
                              bottom: 0
                            }}
                          >
                            {' : '}
                          </span>
                        </span>
                        <SelectInput
                          label="(mm)"
                          defaultValue={0}
                          source={getSource('duration.minute')}
                          choices={render(0, 59).map(time => {
                            return {
                              id: time,
                              name: ('0' + time).slice(-2)
                            }
                          })}
                        />
                      </div>
                    </div>
                  ) : null
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              return typeof formData.companyId !== 'undefined' ? (
                <ReferenceInput
                  label="End station"
                  source="trip.toTerminal.id"
                  reference="locations"
                  validate={[required()]}
                  perPage={100}
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={{
                    type: 'Location::Terminal',
                    company: formData.companyId
                  }}
                >
                  <AutocompleteInput optionText="location_name" />
                </ReferenceInput>
              ) : null
            }}
          </FormDataConsumer>
          <br />
          <Dashed />
          <br />
          <h3>Trip information</h3>
          <NumberInput
            label="Lead Time (Hours)"
            defaultValue={12}
            source="leadTime"
          />
          <NumberInput
            label="Booking Window (Days)"
            source="bookingWin"
            defaultValue={360}
          />
          <NumberInput
            label="Seat Capacity"
            defaultValue={999}
            source="seatCap"
          />
          <TextInput source="notes" label="Notes" />
        </FormTab>

        <FormTab label="Price">
          <NumberInput
            label="Contract Rate (THB)"
            validate={[required()]}
            source="cost.netPrice"
          />
          <NumberInput
            label="Selling Price (THB)"
            validate={[required()]}
            source="cost.defaultPrice"
          />
          <NumberInput
            label="Suggested Price (THB)"
            source="cost.suggestPrice"
          />
          <BooleanInput label="Child Rate" />
          <BooleanInput label="Infant Rate" />
        </FormTab>

        <FormTab label="Pick-up/Drop-off">
          <BooleanInput label="Hotel Pick-up" source="pickup.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.pickup) {
                return (
                  formData.pickup.status && (
                    <Fragment>
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="pickup.desc.booking"
                        label="Pick-up Description (Booking Site)"
                        validate={[required()]}
                      />
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="pickup.desc.ticket"
                        label="Pick-up Description (Ticket)"
                        validate={[required()]}
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="pickup.leadTime"
                        label="Pick-up Lead Time (Hours)"
                        validate={[required()]}
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="pickup.contract"
                        label="Pick-up Contract Rate (THB)"
                        validate={[required()]}
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        label="Pick-up Selling Price (THB)"
                        source="pickup.price"
                        validate={[required()]}
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>

          <BooleanInput label="Hotel Drop-off" source="dropoff.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.dropoff) {
                return (
                  formData.dropoff.status && (
                    <Fragment>
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="dropoff.desc.booking"
                        label="Drop-off Description (Booking Site)"
                        validate={[required()]}
                      />
                      {/* <NumberInput style={{ width: "100%" }} source="dropoff.leadTime" label="Drop-off Lead Time (Hours)" /> */}
                      <NumberInput
                        style={{ width: '100%' }}
                        source="dropoff.contract"
                        label="Pick-up Contract Rate (THB)"
                        validate={[required()]}
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="dropoff.price"
                        label="Pick-up Selling Price (THB)"
                        validate={[required()]}
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

const Dashed = ({ width }) => (
  <div style={{ width: width, borderBottom: '1px solid #e6e6e6' }} />
)

const render = (init, end) => {
  let result = []
  for (let i = init; i <= end; i++) {
    result.push(i)
  }
  return result
}

export const RoutesEdit = props => {
  return (
    <Edit title="Route Edit" {...props}>
      <TabbedForm toolbar={<CompanyToolbar />}>
        <FormTab label="Route">
          <TextField label="Route ID" source="id" />
          <ReferenceInput
            label="Company"
            source="company.id"
            reference="companies"
            perPage={200}
            sort={{
              field: 'op_name',
              order: 'ASC'
            }}
          >
            <SelectInput optionText="op_name" />
          </ReferenceInput>
          <CheckboxGroupInput
            source="weeklyScheduleMask"
            label="Weekly schedule"
            choices={[
              { id: 0, name: 'Sunday' },
              { id: 1, name: 'Monday' },
              { id: 2, name: 'Tuesday' },
              { id: 3, name: 'Wednesday' },
              { id: 4, name: 'Thursday' },
              { id: 5, name: 'Friday' },
              { id: 6, name: 'Saturday' }
            ]}
          />
          <h3>Destination</h3>
          <ReferenceInput
            label="Deapature from destination"
            source="trip.from_id"
            reference="locations"
            perPage={500}
            filter={{ type: 'Location::Area' }}
            sort={{
              field: 'name',
              order: 'ASC'
            }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
          <ReferenceInput
            label="Arrival to Destination"
            source="trip.to_id"
            reference="locations"
            perPage={500}
            filter={{ type: 'Location::Area' }}
            sort={{
              field: 'name',
              order: 'ASC'
            }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
          <br />
          <Dashed />
          <br />
          <h3>Trip Itinerary</h3>
          <div style={{ display: 'flex' }}>
            <SelectInput
              defaultValue={0}
              label="Departure time (HH)"
              source="time.departureHour"
              choices={render(0, 23).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
            <span style={{ position: 'relative', marginRight: '10px' }}>
              <span
                style={{
                  verticalAlign: 'bottom',
                  height: '33px',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                {' : '}
              </span>
            </span>
            <SelectInput
              label="(mm)"
              defaultValue={0}
              source="time.departureMinute"
              choices={render(0, 59).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <SelectInput
              defaultValue={0}
              label="Duration (HH)"
              source="time.durationHour"
              choices={render(0, 72).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
            <span style={{ position: 'relative', marginRight: '10px' }}>
              <span
                style={{
                  verticalAlign: 'bottom',
                  height: '33px',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                {' : '}
              </span>
            </span>
            <SelectInput
              label="(mm)"
              defaultValue={0}
              source="time.durationMinute"
              choices={render(0, 59).map(time => {
                return {
                  id: time,
                  name: ('0' + time).slice(-2)
                }
              })}
            />
          </div>
          {/* <TextInput
            label="Total Travel time (minuters)"
            source="time.durationUnit"
          /> */}
          <DisabledInput label="Arrival time" source="time.arrival" />
          <br />
          <Dashed width="360px" />
          <br />

          <ArrayInput label="Start Station" source="itinerary">
            <SimpleFormIterator style={{}}>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => {
                  if (formData.company) {
                    return typeof formData.company.id !== 'undefined' ? (
                      <div>
                        <ReferenceInput
                          source={getSource('terminal')}
                          label="Station"
                          reference="locations"
                          perPage={100}
                          validate={[required()]}
                          sort={{
                            field: 'name',
                            order: 'ASC'
                          }}
                          filter={{
                            type: 'Location::Terminal',
                            company: formData.company.id
                          }}
                        >
                          <AutocompleteInput
                            optionText="location_name"
                            options={{
                              fullWidth: true
                            }}
                          />
                        </ReferenceInput>
                        <ReferenceInput
                          source={getSource('vehicle')}
                          label="Vehicle"
                          reference="vehicles"
                          perPage={100}
                          validate={[required()]}
                          sort={{ field: 'name', order: 'ASC' }}
                          filter={{
                            company: formData.company.id
                          }}
                        >
                          <AutocompleteInput
                            optionText="name"
                            options={{
                              fullWidth: true
                            }}
                          />
                        </ReferenceInput>
                        <div style={{ display: 'flex' }}>
                          <SelectInput
                            defaultValue={0}
                            label="Duration (HH)"
                            source={getSource('duration.hour')}
                            choices={render(0, 23).map(time => {
                              return {
                                id: time,
                                name: ('0' + time).slice(-2)
                              }
                            })}
                          />
                          <span
                            style={{
                              position: 'relative',
                              marginRight: '10px'
                            }}
                          >
                            <span
                              style={{
                                verticalAlign: 'bottom',
                                height: '33px',
                                position: 'absolute',
                                bottom: 0
                              }}
                            >
                              {' : '}
                            </span>
                          </span>
                          <SelectInput
                            label="(mm)"
                            defaultValue={0}
                            source={getSource('duration.minute')}
                            choices={render(0, 59).map(time => {
                              return {
                                id: time,
                                name: ('0' + time).slice(-2)
                              }
                            })}
                          />
                        </div>
                        <DisabledInput
                          label="Start time"
                          source={getSource('format_travel_time')}
                        />
                      </div>
                    ) : null
                  }
                }}
              </FormDataConsumer>
              {/* <TimeInput
                source="format_travel_time"
                label="Start time"
                options={{ format: 'HH:mm', ampm: false }}
              /> */}
            </SimpleFormIterator>
          </ArrayInput>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              return typeof formData.company.id !== 'undefined' ? (
                <ReferenceInput
                  label="End station"
                  source="trip.toTerminal.id"
                  reference="locations"
                  perPage={100}
                  sort={{ field: 'name', order: 'ASC' }}
                  filter={{
                    type: 'Location::Terminal',
                    company: formData.company.id
                  }}
                >
                  <AutocompleteInput
                    optionText="location_name"
                    options={{
                      fullWidth: true
                    }}
                  />
                </ReferenceInput>
              ) : null
            }}
          </FormDataConsumer>
          <br />
          <Dashed />
          <br />
          <h3>Trip information</h3>
          <NumberInput label="Lead Time (Hours)" source="leadTime" />
          <NumberInput label="Booking Window (Days)" source="bookingWin" />
          <NumberInput label="Seat Capacity" source="seatCap" />
          <TextInput source="notes" label="Notes*" />
        </FormTab>

        <FormTab label="Price">
          <h3>Trip Price and contract rate</h3>
          <NumberInput label="Contract Rate (THB)" source="cost.netPrice" />
          <NumberInput label="Selling Price (THB)" source="cost.defaultPrice" />
          <NumberInput
            label="Suggested Price (THB)"
            source="cost.suggestPrice"
          />
          <BooleanInput label="Child Rate" />
          <BooleanInput label="Infant Rate" />
        </FormTab>

        <FormTab label="Pick-up/Drop-off">
          <h3>Hotel pickup and drop-off</h3>
          <BooleanInput label="Hotel Pick-up" source="pickup.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.pickup) {
                return (
                  formData.pickup.status && (
                    <Fragment>
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="pickup.desc.booking"
                        label="Pick-up Description (Booking Site)"
                      />
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="pickup.desc.ticket"
                        label="Pick-up Description (Ticket)"
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="pickup.leadTime"
                        label="Pick-up Lead Time (Hours)"
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="pickup.contract"
                        label="Pick-up Contract Rate (THB)"
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        label="Pick-up Selling Price (THB)"
                        source="pickup.price"
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>

          <BooleanInput label="Hotel Drop-off" source="dropoff.status" />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.dropoff) {
                return (
                  formData.dropoff.status && (
                    <Fragment>
                      <LongTextInput
                        style={{ width: '100%' }}
                        source="dropoff.desc.booking"
                        label="Drop-off Description (Booking Site)"
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="dropoff.contract"
                        label="Drop-off Contract Rate (THB)"
                      />
                      <NumberInput
                        style={{ width: '100%' }}
                        source="dropoff.price"
                        label="Drop-off Selling Price (THB)"
                      />
                    </Fragment>
                  )
                )
              }
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
