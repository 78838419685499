import React from 'react'
import { HorizontalBarSeries, VerticalBarSeries } from 'react-vis'

import { generateLinearData, intRandom, random } from './Data'
import { chooseColorScale, SimpleChartWrapper } from './Utils'

const VerBarSerie = () => {
  //   const xyPlotParams = isVertical ? {} : { xDomain: [0, 20], yDomain: [0, 8] }
  //   function dataGenerator(params) {
  //     return generateLinearData({ ...params, filpXY: !isVertical })
  //   }
  return (
    <SimpleChartWrapper>
      <VerticalBarSeries
        data={generateLinearData({
          nbPoints: 8,
          changeRatio: 0.4,
          key: 'bar1'
        })}
      />
    </SimpleChartWrapper>
  )
}

export default VerBarSerie
