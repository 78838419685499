import React, { Fragment, Component } from 'react'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { notification, Icon } from 'antd'

import Space from './custom/view/AffiliateDashboard/Space'
import OldPWModal from './OldPWModal'
import SpinnerView from './SpinnerView'

const styles = {
  padding: {
    padding: '41px'
  },
  titleStyle: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 500,
    margin: 0
  },
  textStyle: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    color: '#4a4a4a',
    fontWeight: 400,
    margin: 0
  },
  span: {
    display: 'inline-block',
    width: '10px'
  },
  line: {
    width: '657px',
    height: '0.5px',
    border: 'solid 0.5px #979797'
  },
  resetText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    color: '#3e4eb8',
    fontWeight: 500,
    margin: 0,
    cursor: 'pointer'
  }
}

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '-',
      lastname: '-',
      business: '-',
      website: '-',
      telephone: '-',
      email: '-',
      country: '-',
      open: false,
      onload: true
    }
  }

  componentDidMount() {
    const pid = localStorage.getItem('pid')

    axios
      .get(`${process.env.REACT_APP_URL_PARTNER}api/1.0.0/partner/${pid}`)
      .then(res => {
        const data = res.data
        const {
          firstname,
          lastname,
          business,
          website,
          telephone,
          email,
          country
        } = data

        console.log('Profile data >>> ', data)

        if (data) {
          this.setState({
            firstname,
            lastname,
            business,
            website,
            telephone,
            email,
            country,
            onload: false
          })
        }
      })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  doUpdate = value => {
    notification.config({
      placement: 'bottomRight'
    })

    if (value === 'succeeded') {
      notification.open({
        message: 'Change Password Succeeded!',
        icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
      })
    } else {
      notification.open({
        message: 'Change Password Failed!',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />
      })
    }
  }

  render() {
    const { classes } = this.props
    const {
      firstname,
      lastname,
      business,
      website,
      telephone,
      email,
      country,
      open,
      onload
    } = this.state

    const data = [
      {
        title: 'Email:',
        info: email
      },
      {
        title: 'First Name:',
        info: firstname
      },
      {
        title: 'Last Name:',
        info: lastname
      },
      {
        title: 'Business Name:',
        info: business
      },
      {
        title: 'Website:',
        info: website
      },
      {
        title: 'Country:',
        info: country
      },
      {
        title: 'Phone Number (optional):',
        info: `+66${telephone}`
      }
    ]
    return (
      <Fragment>
        <SpinnerView show={onload} />
        <Card id="card">
          <div className={classes.padding}>
            <p className={classes.titleStyle}>Profile</p>
            <Space size="43" />
            {data.map(({ title, info }, index) => (
              <Fragment key={index}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p className={classes.textStyle}>{title}</p>
                  <span className={classes.span} />
                  <p className={classes.textStyle}>{info}</p>
                </div>
                <Space size="5" />
                <div className={classes.line} />
                <Space size="25" />
              </Fragment>
            ))}
            <span className={classes.resetText} onClick={this.handleOpen}>
              Change Your Password
            </span>
          </div>
        </Card>
        <OldPWModal
          open={open}
          handleClose={this.handleClose}
          update={this.doUpdate}
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(Profile)
