export default {
  palette: {
    secondary: {
      light: '#6ec6ff',
      main: '#2196f3',
      dark: '#0069c0',
      contrastText: '#fff'
    }
  },
  typography: {
    title: {
      fontWeight: 400
    }
  }
}
