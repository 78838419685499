import React, { Fragment } from 'react'
import {
  email,
  List,
  Edit,
  Datagrid,
  SimpleForm,
  FunctionField,
  BooleanField,
  TextInput,
  TextField,
  EmailField,
  EditButton,
  // BooleanInput,
  // DeleteButton,
  BulkDeleteButton,
  SaveButton,
  Toolbar
} from 'react-admin'
import Button from '@material-ui/core/Button'
import DefaultIcon from '@material-ui/icons/Group'
import { DateKOHField } from './custom/view/CustomField'
import BulkAdminButton from './custom/view/BulkAdminButton'
import Pagination from './custom/view/Pagination'

// BulkActions
const BulkActionButtons = props => (
  <Fragment>
    <BulkAdminButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
)
// Exporter
export const UserIcon = () => <DefaultIcon />
export const UserList = props => (
  <List
    {...props}
    perPage={30}
    title="Show user lists"
    pagination={<Pagination />}
    bulkActionButtons={<BulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <FunctionField
        label="Username"
        render={record => `${record.first_name} ${record.last_name}`}
      />
      <EmailField source="email" />
      <TextField label="Tel. number" source="main_contact_number" />
      <BooleanField source="is_super_admin" label="Admin" />
      <DateKOHField label="Created" timeKey="created_at" {...props} />
      <EditButton />
      {/* <DeleteButton /> */}
    </Datagrid>
  </List>
)

const UserToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="/users" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/users" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const UserEdit = props => {
  return (
    <Edit title="User Edit" {...props}>
      <SimpleForm toolbar={<UserToolbar />}>
        <TextInput label="First Name" source="first_name" />
        <TextInput label="Last Name" source="last_name" />
        <TextInput
          label="Email"
          source="email"
          type="email"
          validate={email()}
        />
        <TextInput label="Telephone number" source="main_contact_number" />
        <TextInput label="Password" source="password" type="password" />
        {/* <TextInput
          label="Password Again"
          source="password_retype"
          type="password"
        /> */}
      </SimpleForm>
    </Edit>
  )
}
