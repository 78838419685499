import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import { Notification } from 'react-admin'
import { notification, Icon } from 'antd'
import defaultTheme from './Theme'
// import DefaultLoginForm from './LoginForm'
import DefaultLoginForm from './LoginPartnerForm'

import Space from '../view/AffiliateDashboard/Space'
import ResetModal from './ResetModal'
import Logo from './assets/icr_logo_yellow.png'

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '120vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 430,
    marginTop: '6em',
    position: 'relative'
  },
  avatar: {
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  icon: {
    backgroundColor: theme.palette.secondary[500]
  },
  web: {
    fontFamily: 'Ubuntu',
    color: '#ffc800',
    fontSize: '20px',
    fontWeight: 500,
    margin: 0
  },
  secondCard: {
    minWidth: 430,
    marginTop: '30px'
  },
  title: {
    fontFamily: 'Ubuntu',
    color: '#4a4a4a',
    fontSize: '24px',
    fontWeight: 500,
    margin: '0 0 12px'
  },
  detail: {
    fontFamily: 'Roboto',
    color: '#4a4a4a',
    fontSize: '14px',
    fontWeight: 400,
    width: '336px',
    wordWrap: 'break-word',
    margin: '0 0 14px'
  },
  regisText: {
    fontFamily: 'Roboto',
    color: '#4a90e2',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    margin: '0 0 32px'
  },
  forgetText: {
    fontSize: '15px',
    color: '#4a90e2',
    fontFamily: 'Roboto',
    fontWeight: 700,
    cursor: 'pointer',
    margin: '0 0 30px 0',
    position: 'absolute',
    bottom: '20%',
    left: '47px',
    right: 0
  }
})

const sanitizeRestProps = ({
  array,
  backgroundImage,
  classes,
  className,
  location,
  staticContext,
  theme,
  title,
  ...rest
}) => rest

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */

class LoginPartner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
    this.theme = createMuiTheme(props.theme)
    this.containerRef = React.createRef()
    this.backgroundImageLoaded = false
  }

  // Even though the React doc ensure the ref creation is done before the
  // componentDidMount, it can happen that the ref is set to null until the
  // next render.
  // So, to handle this case the component will now try to load the image on
  // the componentDidMount, but if the ref doesn't exist, it will try again
  // on the following componentDidUpdate. The try will be done only once.
  // @see https://reactjs.org/docs/refs-and-the-dom.html#adding-a-ref-to-a-dom-element
  updateBackgroundImage = (lastTry = false) => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      this.backgroundImageLoaded = true
    }

    if (lastTry) {
      this.backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props

    if (backgroundImage) {
      const img = new Image()
      img.onload = this.updateBackgroundImage
      img.src = backgroundImage
    }
  }

  componentDidMount() {
    this.lazyLoadBackgroundImage()
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage(true)
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  doUpdate = value => {
    console.log('DoUpdate >>> ', value)

    notification.config({
      placement: 'bottomRight'
    })

    if (value) {
      notification.open({
        message: 'Reset Password Succeeded!',
        icon: <Icon type="check-circle" style={{ color: '#00e511' }} />
      })
    } else {
      notification.open({
        message: 'Reset Password Failed!',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />
      })
    }
  }

  render() {
    const { classes, className, loginForm, ...rest } = this.props
    const { open } = this.state

    return (
      <MuiThemeProvider theme={this.theme}>
        <div
          className={classnames(classes.main, className)}
          {...sanitizeRestProps(rest)}
          ref={this.containerRef}
        >
          <Card
            className={classes.card}
            style={{ padding: '42px 47px 0', borderRadius: '10px' }}
          >
            <div className={classes.avatar}>
              <img src={Logo} alt={Logo} height="58" />
            </div>
            <Space size="6" />
            <p className={classes.web}>KOHLIFE.COM</p>
            <Space size="18" />
            <h2
              style={{
                padding: 0,
                textAlign: 'left',
                fontFamily: 'Ubuntu',
                fontWeight: '500',
                fontSize: '24px',
                color: '#4a4a4a',
                marginBottom: '12px',
                width: '336px',
                wordWrap: 'break-word'
              }}
            >
              Log in to manage your affiliate account
            </h2>
            <p className={classes.forgetText} onClick={this.handleOpen}>
              Forgot your password?
            </p>
            {loginForm}
          </Card>
          <Card
            className={classes.secondCard}
            style={{ padding: '35px  0 0 43px', borderRadius: '10px' }}
          >
            <p className={classes.title}>Become our affiliate partner?</p>
            <p className={classes.detail}>
              Create an affiliate account and start monetising your traffic
              today!
            </p>
            <div>
              <a
                target="_blank"
                className={classes.regisText}
                href="https://www.kohlife.com/affiliate"
              >
                Register
              </a>
            </div>
            <br />
            <br />
          </Card>
          <ResetModal
            open={open}
            handleClose={this.handleClose}
            update={this.doUpdate}
          />
          <Notification />
        </div>
      </MuiThemeProvider>
    )
  }
}

LoginPartner.propTypes = {
  authProvider: PropTypes.func,
  backgroundImage: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  loginForm: PropTypes.element,
  meta: PropTypes.object,
  previousRoute: PropTypes.string
}

LoginPartner.defaultProps = {
  backgroundImage: 'https://source.unsplash.com/HMFg9UlURvc/1600x900',
  theme: defaultTheme,
  loginForm: <DefaultLoginForm />
}

export default withStyles(styles)(LoginPartner)
