import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as moment from 'moment'


export class InvoiceTable extends Component {
  
  render() {
    const { className,
      basePath,
      record,
      resource
    } = this.props
    // console.log(this.props)

    const {
      id,
      name,
      description,
      actual_price,
      created_at,
      updated_at,
      rid,
      pay_id,
      pay_method,
      user_id,
      charge_id,
      actual_gain,
      transaction_fee,
      agent,
      journey_date,
      ticketRefID,
      passengerRid,
      // Activity
      customerPaid,
      errorActualPrice,
      paymentGateway,
      marginTransaction,
      netPrice,
      agentGain,
      totalMargin,
    } = record

    function paymentFormatter(cell, row) {
      if (cell == '') {
        return (
          <div style={{ lineHeight: '20px', margin: '15px 15px 15px 0px' }}>
            {"-"}
        </div>)
      }
      return (
        <div style={{ lineHeight: '20px', margin: '15px 15px 15px 0px' }}>
          { cell } { pay_method } <br />
          <span style={{ fontWeight: '300' }}>{ charge_id }</span>
        </div>
      );
    }
    
    function dateKohFormatter(cell, row) {
      if (cell == '') {
        return ''
      }

      return (
        <div style={{ minWidth: '90px', lineHeight: '20px', margin: '15px 15px 15px 0px' }}>
          <span style={{ fontWeight: '500' }}>
            {moment(cell)
              // .add(7, 'hours') //+7 hours for Thailand Local Time
              .format('D MMM YYYY')}
          </span>
          <br />
          <span>{moment(cell).format('HH:mm')}</span>
        </div>
      );
    }

    const detail = [{
        id: 0,
        name: 'Total Margin:',
        amount: totalMargin,
        date: '',
        payment: '',
        note: '-',
    }, {
        id: 1,
        name: 'Customer Paid:',// 'Paid:'
        amount: customerPaid,
        date: created_at,
        payment: pay_id,
        note: '-',
    }, {
        id: 2,
        name: `Error Actual Price`,// `${ticketRefID[0].ref_id} Trip Cost:`
        amount: errorActualPrice,
        date: updated_at,
        payment: pay_id,
        note: '-',
    }, {
        id: 3,
        name: `Payment Gateway`,// `${ticketRefID[0].ref_id} Pick-up Cost:`
        amount: paymentGateway,
        date: updated_at,
        payment: pay_id,
        note: '-',
    }, {
        id: 4,
        name: `Margin Transaction`,// `${ticketRefID[0].ref_id} Drop-off Cost:`
        amount: marginTransaction,
        date: updated_at,
        payment: pay_id,
        note: '-',
      }, {
        id: 5,
        name: `Net Price`,// `${ticketRefID[0].ref_id} Discount:`
        amount: netPrice,
        date: updated_at,
        payment: pay_id,
        note: '-',
      }, {
        id: 6,
        name: `Agent Gain`,// `${ticketRefID[0].ref_id} Discount:`
        amount: agentGain,
        date: updated_at,
        payment: pay_id,
        note: '-',
      }
      // , {
      //   id: 7,
      //   name: `${ticketRefID[0].ref_id} agent_gain`,// `${ticketRefID[0].ref_id} Discount:`
      //   amount: ticketRefID[0].agent_gain,
      //   date: updated_at,
      //   payment: pay_id,
      //   note: '-',
      // },
    // 
    // {
    //     id: 6,
    //     name: 'Affiliate:',
    //     amount: transaction_fee,
    //     date: updated_at,
    //     payment: pay_id,
    //     note: '-',
    // }, {
    //     id: 7,
    //     name: 'Refund',
    //     amount: actual_price,
    //     date: updated_at,
    //     payment: pay_id,
    //     note: charge_id,
    // }, {
    //     id: 8,
    //     name: 'Refund Payment Fee:',
    //     amount: actual_price,
    //     date: updated_at,
    //     payment: pay_id,
    //     note: '-',
    // }, {
    //     id: 9,
    //     name: 'Extra Payment:',
    //     amount: actual_price,
    //     date: updated_at,
    //     payment: pay_id,
    //     note: '-',
    //   }
    ];
    const columns = [{
        dataField: 'name',
        text: '',
        align: 'left',
        headerAlign: 'left',
        headerStyle:{width: '120px'},
    }, {
        dataField: 'amount',
        text: 'Amount',
        align: 'left',
        headerAlign: 'left',
        headerStyle:{width: '100px'},
    }, {
        dataField: 'date',
        text: 'Date',
        align: 'left',
        headerAlign: 'left',
        formatter: dateKohFormatter,
        headerStyle:{width: '100px'},
    }, {
        dataField: 'payment',
        text: 'Transaction',
        align: 'left',
        headerAlign: 'left',
        formatter: paymentFormatter,
        headerStyle:{width: '100px'},
    }, {
        dataField: 'note',
        text: 'Remarks',
        headerStyle:{width: '100px'},
    }];
    return (
      <div style={{ margin: '40px 36px' }} >
        <BootstrapTable
          keyField={id}
          data={detail}
          columns={columns}
          bordered={false}
          rowStyle={{ backgroundColor: 'white' }}
          />
      </div>
    )
  }
}

InvoiceTable.propTypes = {
  classes: PropTypes.object,
}
export default InvoiceTable;