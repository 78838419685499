import React from 'react'
import TextField from '@material-ui/core/TextField';
import { Field } from 'redux-form';
import {
  SelectInput,
  LongTextInput,
  TextInput,
} from 'react-admin';
import { getData } from 'country-list'
import { Input } from '@material-ui/core';

export const CustomerEditInput = ( {source} ) => (
  <div style={{ lineHeight: '30px', margin: '-15px 0px -15px 36px' }}>
    <span style={{ fontWeight: 'bold' }}>{'Customer: '}</span>{' '}
    <span><TextInput label={false} source={source} resettable /></span>
  </div>
);

export const EmailEditInput = ({ source, record }) => {
  return (
  <div style={{ lineHeight: '30px', margin: '7px 0px 15px 36px', width: '700px' }}>
    <span style={{ fontWeight: 'bold' }}>{'Email: '}</span>{' '}
    <span>
      <TextInput
          label={false}
          source={source}
          type='email'
          style={{
            width: '250px'
          }}
      />
    </span>
  </div>
)}

export const NationEditInput = ( {source} ) => (
  <div style={{ lineHeight: '30px', margin: '-35px 0px -35px 36px' }}>
    <span style={{ fontWeight: 'bold' }}>{'Nation: '}</span>{' '}
    <span>
      <SelectInput
          label={false}
          source={source}
          choices={getData()}
          optionValue="code"
    />
    </span>
  </div>
);

export const PhoneEditInput = ( {source} ) => (
  <div style={{ lineHeight: '30px', margin: '25px 0px 15px 36px' }}>
    <span style={{ fontWeight: 'bold' }}>{'Phone Number: '}</span>{' '}
    <span>
      <TextInput label={false} source={source} />
    </span>
  </div>
)

export const PickupEditInput = ({ source, record }) => {
  if (record[source] == 'meeting_point') {
    return (
      <div style={{ margin: '15px 0px 15px 36px' }}>
        <span style={{ fontWeight: 'bold' }}>{'Pick-up: '}</span>{' '}
        <span>
          {' - '}
        </span>
      </div>
    )
  }
  return (
    <div style={{ margin: '15px 0px 15px 36px' }}>
      <span style={{ fontWeight: 'bold', margin: '15px 0px 15px 0px' }}>{'Pick-up: '}</span>{' '}
      <span >
        <LongTextInput label={false} source={source} style={{ margin: '-5px 0px 25px' }} />
      </span>
    </div>
  )
}
