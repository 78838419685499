import React from 'react'
import {
  List,
  Toolbar,
  SaveButton,
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
  LongTextInput,
  TextInput,
  Datagrid,
  TextField,
  ChipField,
  EditButton,
  DeleteButton
} from 'react-admin'
import DefaultIcon from '@material-ui/icons/Group'
import Button from '@material-ui/core/Button'
import RichTextInput from 'ra-input-rich-text'
import { DateKOHField } from './custom/view/CustomField'
import Pagination from './custom/view/Pagination'

const ContentField = ({ record = {} }) => (
  <div style={{ lineHeight: '18px', padding: '20px 5px' }}>
    <span style={{ fontWeight: '500' }}>{record.title}</span> <br />
    <span dangerouslySetInnerHTML={{ __html: record.paragraph }} /> <br />
    <br />
    <span>
      <span style={{ fontWeight: '500' }}>Tags: </span>
      {record.tags.map(item => (
        <span
          style={{
            background: '#e2e2e2',
            borderRadius: '18px',
            padding: '8px 14px',
            marginRight: '5px'
          }}
        >
          {item}
        </span>
      ))}
    </span>
  </div>
)

const TagField = ({ record = {} }) => (
  <td>
    {record.tags.map(item => (
      <span
        style={{
          background: '#e2e2e2',
          borderRadius: '10px',
          padding: '7px',
          marginRight: '5px'
        }}
      >
        {item}
      </span>
    ))}
  </td>
)

const ContentImageField = ({ record = {} }) => (
  <td>
    <img
      src={record.feature_img}
      alt=""
      style={{ height: '72px', margin: '12px 0' }}
    />
  </td>
)

const ContentPanel = ({ id, record, resource }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: record.des }}
      style={{ lineHeight: '18px', padding: '16px 36px' }}
    />
  )
}

const contentTags = [
  {
    id: 'food',
    name: 'Yummy dishes you can’t miss'
  },
  {
    id: 'place',
    name: 'Places you will regret not visiting'
  },
  {
    id: 'accommodation',
    name: 'Accommodation that will blow your mind'
  },
  {
    id: 'tip',
    name: 'Sneaky tips that will help you survive'
  },
  {
    id: 'shopping',
    name: 'Parties to rock (nightlife)Shop til you drop'
  },
  {
    id: 'diving',
    name: 'Dive.live.love'
  },
  {
    id: 'festival',
    name: 'Events to experience'
  },
  {
    id: 'culture',
    name: 'How to live like a local'
  }
]

// Exporter
export const ContentIcon = () => <DefaultIcon />
export const ContentList = props => (
  <List
    {...props}
    title="Show content lists"
    perPage={30}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="edit" expand={<ContentPanel />}>
      <TextField source="id" />
      <ContentImageField label="Image" />
      <ContentField label="Content" />
      <TextField source="custom_url" />
      {/* <TagField label="Tags" /> */}
      <DateKOHField label="Created" timeKey="created_at" {...props} />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const ContentToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="/contents" />
    <span style={{ margin: '0 6px' }} />
    <Button variant="contained" size="large">
      <a href="/#/contents" style={{ textDecoration: 'none' }}>
        BACK
      </a>
    </Button>
  </Toolbar>
)

export const ContentEdit = props => {
  return (
    <Edit title="Content Edit" {...props}>
      <SimpleForm toolbar={<ContentToolbar />}>
        <LongTextInput label="Content title" source="title" />
        <div style={{ display: 'flex', width: '100%' }}>
          <ReferenceInput
            source="from"
            reference="locations"
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ type: 'Location::Area' }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
          <ReferenceInput
            source="to"
            reference="locations"
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            filter={{ type: 'Location::Area' }}
          >
            <AutocompleteInput optionText="location_name" />
          </ReferenceInput>
        </div>
        <RichTextInput
          label="Content first paragraph"
          source="paragraph"
          toolbar={['bold', 'italic', 'underline', 'link']}
        />
        <SelectArrayInput
          label="Tags"
          source="tags"
          style={{ width: '100%' }}
          choices={contentTags}
        />
        <LongTextInput label="SEO Title" source="seo_title" />
        <LongTextInput label="SEO Description" source="seo_desc" />
        <TextInput label="SEO Search URL" source="custom_url" />
        <TextInput label="Feature Image" source="feature_img" />
        <br />
        <TextInput label="Writer name" source="writer" />
        {/* <TextInput label="Writer website" source="writer_link" /> */}
        <RichTextInput
          label="Content Body"
          source="des"
          style={{ background: '#f3f3f3', padding: '40px' }}
        />
      </SimpleForm>
    </Edit>
  )
}

export const ContentCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<ContentToolbar />}>
      <LongTextInput label="Content title" source="title" />
      <div style={{ display: 'flex', width: '100%' }}>
        <ReferenceInput
          source="from"
          reference="locations"
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ type: 'Location::Area' }}
        >
          <AutocompleteInput optionText="location_name" />
        </ReferenceInput>
        <ReferenceInput
          source="to"
          reference="locations"
          perPage={100}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ type: 'Location::Area' }}
        >
          <AutocompleteInput optionText="location_name" />
        </ReferenceInput>
      </div>
      <RichTextInput
        label="Content first paragraph"
        source="paragraph"
        toolbar={['bold', 'italic', 'underline', 'link']}
      />
      <SelectArrayInput
        label="Tags"
        source="tags"
        style={{ width: '100%' }}
        choices={contentTags}
      />
      <LongTextInput label="SEO Title" source="seo_title" />
      <LongTextInput label="SEO Description" source="seo_desc" />
      <TextInput label="SEO Search URL" source="custom_url" />
      <TextInput label="Feature Image" source="feature_img" />
      <br />
      <TextInput label="Writer name" source="writer" />
      {/* <TextInput label="Writer website" source="writer_link" /> */}
      <RichTextInput
        label="Content Body"
        source="des"
        style={{ background: '#f3f3f3', padding: '40px' }}
      />
    </SimpleForm>
  </Create>
)
